import { TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";

interface IChangePasswordProps {
  open: Boolean;
  onConfirm: Function;
  onCancel: Function;
}

export default function ChangePasswordDialog({
  open,
  onConfirm,
  onCancel,
}: IChangePasswordProps) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setNewPassword("");
      setConfirmPassword("");
    }
  }, [open]);

  const formElements = (
    <>
      <TextField
        label="New password"
        required
        fullWidth
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        label="Confirm password"
        required
        fullWidth
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={!!error}
        helperText={error}
      />
    </>
  );

  const save = async (e: any) => {
    if (newPassword !== confirmPassword)
      return setError("Passwords are different, try again.");

    setError("");
    await onConfirm(newPassword);
  };

  return (
    <ConfirmDialog
      title="Change Password"
      text="Enter the new password and confirm it."
      FormElements={formElements}
      open={open}
      onConfirm={(e: any) => save(e)}
      onClose={onCancel}
    />
  );
}
