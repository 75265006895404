import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EditItemDialog from '../../components/edit-item-dialog/EditItemDialog';

interface IEditProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  location: any;
}

export default function EditLocationDialog({ open, onSave, onClose, location }: IEditProps) {

  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [kafka, setKafka] = useState("");

  useEffect(() => {
    if (open) {
      console.log(location)
      setName(location.name);
      setLatitude(location.latitude);
      setLongitude(location.longitude);
      setKafka(location.kafka_alert_alias)
    }
    return () => {
      setName("");
      setLatitude("");
      setLongitude("");
      setKafka("");
    }
  }, [open, location])

  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <TextField label="Latitude" fullWidth value={latitude} onChange={(e) => setLatitude(e.target.value)} />
    <TextField label="Longitude" fullWidth value={longitude} onChange={(e) => setLongitude(e.target.value)} />
    {!location.kafka_alert_alias && (
      <TextField label="Kafka Alert Alias" fullWidth value={kafka} onChange={(e) => setKafka(e.target.value)} />
    )}
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, latitude, longitude, kafka })
  }

  return (
    <EditItemDialog
      title={`Edit ${location?.name || ''} Location`}
      text={`Edit the details of this Location`}
      FormElements={formElements}
      open={open}
      onClose={onClose}
      onSave={(e: any) => save(e)}
    />
  )
}
