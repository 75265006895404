import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from '@mui/material';
import React from 'react'

const Content = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  paddingTop: '0px'
}));

export default function EditItemDialog(props: any) {
  const { title, text, FormElements, open, onClose, onSave } = props;

  return (
    <Dialog transitionDuration={{ exit: 0 }} maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={onSave}>
        <Content>
          <DialogContentText>
            {text}
          </DialogContentText>
          {FormElements}
        </Content>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
