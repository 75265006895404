// Default Options //
// name
// data_source
// tags
// disabled

interface UsecaseDisplayOptions {
  name: string;
  sub_title: boolean;
  camera_feed: boolean;
  camera_feeds: boolean;
  camera_stream_url: boolean;
  data_source: boolean;
  data_sources: boolean;
  secondary_thresholds: boolean;
  time_range: boolean;
  main_tile_data_slots: number;
  sub_tile_data_slots: number;
  graph: boolean;
  compare_to: boolean;
  display_last_updated: boolean;
  display_notifications: boolean;
  unit_of_measurement: boolean;
}

export const usecaseDisplayTypes: UsecaseDisplayOptions[] = [
  {
    name: "PeopleDetection",
    sub_title: false,
    camera_feed: true,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: true,
    main_tile_data_slots: 3,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "SubAreaPeopleDetection",
    sub_title: false,
    camera_feed: true,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: true,
    main_tile_data_slots: 2,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "CrowdingDetection",
    sub_title: false,
    camera_feed: true,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: true,
    main_tile_data_slots: 2,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "VehicleDetection",
    sub_title: false,
    camera_feed: true,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: true,
    main_tile_data_slots: 3,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "AgeDemographic",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 0,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "GenderDemographic",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 0,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "EmotionDemographic",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 0,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "LuggageDetection",
    sub_title: false,
    camera_feed: true,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: true,
    main_tile_data_slots: 3,
    sub_tile_data_slots: 0,
    compare_to: false,
    display_last_updated: false,
    display_notifications: true,
    graph: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "IotCurrentLevel",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 1,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "IotCurrentDetection",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 1,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "IotGraph",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 1,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "IotMultiGraph",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: true,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 1,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "CameraAccess",
    sub_title: false,
    camera_feed: false,
    camera_feeds: true,
    camera_stream_url: false,
    data_source: false,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 0,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "CameraFeed",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: true,
    data_source: false,
    data_sources: false,
    time_range: false,
    main_tile_data_slots: 0,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: false,
  },
  {
    name: "TiltVibrationDetection",
    sub_title: false,
    camera_feed: false,
    camera_feeds: false,
    camera_stream_url: false,
    data_source: false,
    data_sources: true,
    time_range: false,
    main_tile_data_slots: 0,
    sub_tile_data_slots: 0,
    graph: false,
    compare_to: false,
    display_last_updated: false,
    display_notifications: false,
    unit_of_measurement: false,
    secondary_thresholds: true,
  },
];
