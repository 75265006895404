import React, { useEffect, useState } from 'react'
import { Button, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CustomTable from '../../components/table/Table';
import { ApiKeys as ApiKeysApi } from '../../api';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import CreateApiKeyDialog from './CreateApiKeyDialog';
import EditApiKeyDialog from './EditApiKeyDialog';
import { useParams } from 'react-router-dom';

export default function ApiKeys() {

  const { tenant: tenantSlug } = useParams();

  const [apiKeys, setApiKeys] = useState<any[]>([]);
  const [addDialog, setAddDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)

  const [selected, setSelected] = useState<any>({});

  const getApiKeys = async (tenantId: any) => {
    try {
      const ApiKeysResponse = await ApiKeysApi.getAll(tenantId);
      setApiKeys(
        ApiKeysResponse.data.map((d: any) => ({
          Name: `${d.name}`,
          Service: `${d.service}`,
          Key: `${d.key}`,
          Endpoint: `${d.endpoint}`,
          key: d.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const saveCreate = async (data: any) => {
    // do create API functionality
    try {
      await ApiKeysApi.create(tenantSlug, data.name, data.service, data.endpoint, data.key)
      await getApiKeys(tenantSlug)
      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveEdit = async (data: any) => {
    // do save edit API functionality
    try {
      await ApiKeysApi.patch(tenantSlug, selected.key, data.name, data.service, data.endpoint, data.key)
      await getApiKeys(tenantSlug)
      setEditDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveDelete = async () => {
    // do delete API
    try {
      await ApiKeysApi.delete(tenantSlug, selected.key)
      await getApiKeys(tenantSlug)
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectEdit = (id: string) => {
    const apiKey = apiKeys.find(key => key.key === id);
    if (!apiKey) return;

    setSelected(apiKey);
    setEditDialog(true);
  }

  const openAndSelectDelete = (id: string) => {
    const apiKey = apiKeys.find(key => key.key === id);
    if (!apiKey) return;

    setSelected(apiKey);
    setDeleteDialog(true);
  }

  useEffect(() => {
    getApiKeys(tenantSlug);
    return () => {
      setApiKeys([]);
    }
  }, [tenantSlug]);

  useEffect(() => {
    if (!editDialog) {
      setSelected({});
    }
  }, [editDialog])

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])


  const headers = ['Name', 'Service', 'Key', 'Endpoint'];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>API Keys</Typography>
        <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}> New API Key</Button>
      </Toolbar>
      <CustomTable
        name="API Keys"
        data={apiKeys}
        headers={headers}
        viewButton={false}
        editButton={true}
        editItem={(id: string) => openAndSelectEdit(id)}
        deleteButton={true}
        deleteItem={(id: string) => openAndSelectDelete(id)}
      />
      <CreateApiKeyDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} />
      <EditApiKeyDialog open={editDialog} onClose={() => setEditDialog(false)} onSave={(data: any) => saveEdit(data)} selected={selected} />
      <DeleteItemDialog title={`Delete ${selected?.Name || ''} API Key`} text={`Are you sure you want to delete this API Key? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
    </div>
  )
}
