import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EditItemDialog from '../../components/edit-item-dialog/EditItemDialog';
import { apiServices } from '../../app/config/config'

interface IEditProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  selected: any;
}

export default function EditApiKeyDialog({ open, onSave, onClose, selected }: IEditProps) {

  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [key, setKey] = useState("");
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    if (open) {
      setName(selected.Name);
      setKey(selected.Key);
      setService(selected.Service);
      setEndpoint(selected.Endpoint);
    }
    return () => {
      setName("");
      setKey("");
      setService("");
      setEndpoint("");
    }
  }, [open, selected])

  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <FormControl required>
      <InputLabel id="serviceLabel">Service</InputLabel>
      <Select value={service} labelId="serviceLabel" fullWidth label="Service" onChange={(e) => setService(e.target.value)}>
        {apiServices.map(service => (
          <MenuItem value={service.value} key={service.value}>{service.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <TextField label="Key" required fullWidth value={key} onChange={(e) => setKey(e.target.value)} />
    <TextField label="Endpoint" fullWidth value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, service, key, endpoint })
  }

  return (
    <EditItemDialog
      title={`Edit ${selected?.Name || ''} API Key`}
      text={`Edit the details of this API Key`}
      FormElements={formElements}
      open={open}
      onClose={onClose}
      onSave={(e: any) => save(e)}
    />
  )
}
