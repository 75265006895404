export const mainTileDataOptions = [
  {
    name: "Current count",
    value: "current_count"
  },
  {
    name: "Hour count",
    value: "hour_count"
  },
  {
    name: "Day count",
    value: "day_count"
  },
  {
    name: "Previous day count",
    value: "previous_day_count"
  },
  {
    name: "Week count",
    value: "week_count"
  },
  {
    name: "Previous 7 day count",
    value: "previous_7_day_count"
  },
  {
    name: "Month count",
    value: "month_count"
  },
  {
    name: "Previous 30 day count",
    value: "previous_30_day_count"
  },
  {
    name: "Gate Status",
    value: "gate_status"
  },
  {
    name: "Average per m2",
    value: "average_per_m2"
  }
];

export const subTileDataOptions = [
  {
    name: "Busiest hour",
    value: "busiest_hour"
  },
  {
    name: "Busiest day",
    value: "busiest_day"
  },
  {
    name: "Quietest hour",
    value: "quietest_hour"
  },
  {
    name: "Quietest day",
    value: "quietest_day"
  },
  {
    name: "Weekday average",
    value: "weekday_average"
  },
  {
    name: "Weekend average",
    value: "weekend_average"
  },
  {
    name: "Weekday quietest hour",
    value: "weekday_quietest_hour"
  },
  {
    name: "Weekday busiest hour",
    value: "weekday_busiest_hour"
  },
  {
    name: "Weekend quietest hour",
    value: "weekend_quietest_hour"
  },
  {
    name: "Weekend busiest hour",
    value: "weekend_busiest_hour"
  },
  {
    name: "Level of Service",
    value: "level_of_service"
  },
];

export const compareToOptions = [
  {
    name: "Last day",
    value: "last_day"
  },
  {
    name: "Last week",
    value: "last_week"
  },
  {
    name: "Last year",
    value: "last_year"
  },
]

export const overviewWidgetOptions = [
  {
    name: "Highest value",
    value: "highest_value"
  },
  {
    name: "Lowest value",
    value: "lowest_value"
  },
  {
    name: "Average value",
    value: "average_value"
  },
  {
    name: "Time interval with highest value",
    value: "time_interval_with_highest_value"
  },
  {
    name: "Time interval with lowest value",
    value: "time_interval_with_lowest_value"
  },
  {
    name: "Total count",
    value: "total_count"
  },
  {
    name: "Average fruin level",
    value: "average_fruin_level"
  },
  {
    name: "Most common category",
    value: "most_common_category"
  },
  {
    name: "Least common category",
    value: "least_common_category"
  },
  {
    name: "Live data",
    value: "live_data"
  },

];

export const widgetTypes = [
  {
    name: "Line Graph",
    value: "line_graph",
    datasource: true,
    thresholdLimits: true,
  },
  {
    name: "Multi Line Graph",
    value: "multi_line_graph",
    datasource: false,
    datasources: true,
    thresholdLimits: true,
  },
  {
    name: "Bar Chart",
    value: "bar_chart",
    datasource: true,
    thresholdLimits: true,
    layouts: [
      {
        name: "Stacked",
        value: "stacked"
      },
      {
        name: "Grouped",
        value: "grouped"
      }
    ]
  },
  {
    name: "Text Box",
    value: "text_box",
    textarea: true
  },
  {
    name: "Datagroup insights",
    value: "datagroup_insights",
    datasource: true,
    featuredCard: true,
    cameraFeed: true,
    cameraFeedSubArea: true,
    cameraFeedMetric: true,
    statusCardOptions: true,
    thresholdLimits: true,
    graphOptions: [
      {
        name: "No graph",
        value: "no_graph"
      },
      {
        name: "Line graph",
        value: "line_graph"
      },
      {
        name: "Bar graph - Stacked",
        value: "bar_graph_stacked"
      },
      {
        name: "Bar graph - Grouped",
        value: "bar_graph_grouped"
      },
      {
        name: "Text Box",
        value: "text_box",
        textarea: true
      }
    ]
  },
  {
    name: "Multi Device Map",
    value: "multi_device_map",
    datasources: true,
  },
];

export const graphIntervalOptions = [
  {
    name: "15 Minutely",
    value: "15minutes"
  },
  {
    name: "Daily",
    value: "daily"
  },
]

export const timeIntervalOptions = [
  {
    name: "1 Minute",
    value: "1minute"
  },
  {
    name: "15 Minutes",
    value: "15minutes"
  },
  {
    name: "1 Hour",
    value: "1hour"
  },
  {
    name: "1 Day",
    value: "1day"
  }
];

export const timeRangeSliderMarks = [
  {
    value: 60,
    label: '01:00',
  },
  {
    value: 165,
    label: '02:45',
  },
  {
    value: 270,
    label: '04:30',
  },
  {
    value: 375,
    label: '06:15',
  },
  {
    value: 480,
    label: '8:00',
  }
]

export const timeRangeDisplayValue = (value: number) => {
  return `0${value / 60 ^ 0}`.slice(-2) + ':' + ('0' + value % 60).slice(-2)
}

export const timeRangeMinRange = 60;