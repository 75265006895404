import { Box, FormControl, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditItemDialog from "../../components/edit-item-dialog/EditItemDialog";

interface IEditProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  threshold: any;
}

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "3px",
  border: "1px solid #ddd",
}));

export default function EditLocationDialog({
  open,
  onSave,
  onClose,
  threshold,
}: IEditProps) {
  const [name, setName] = useState("");
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("");
  const [fallbackColor, setFallbackColor] = useState("");

  useEffect(() => {
    if (open) {
      setName(threshold.key);
      setLimit1(threshold.value.limit1 || "");
      setLimit1Color(threshold.value.limit1Color || "");
      setLimit2(threshold.value.limit2 || "");
      setLimit2Color(threshold.value.limit2Color || "");
      setLimit3(threshold.value.limit3 || "");
      setLimit3Color(threshold.value.limit3Color || "");
      setFallbackColor(threshold.value.fallbackColor || "");
    }
    return () => {
      setName("");
      setLimit1("");
      setLimit1Color("");
      setLimit2("");
      setLimit2Color("");
      setLimit3("");
      setLimit3Color("");
      setFallbackColor("");
    };
  }, [open, threshold]);

  const formElements = (
    <>
      <TextField label="Name" disabled fullWidth value={name} />
      <SubItem>
        <p>Threshold Limit 1</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Value"
              label="Value"
              value={limit1}
              type="number"
              onChange={(e) => {
                setLimit1(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={limit1Color}
              onChange={(e) => {
                setLimit1Color(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: limit1Color }} />
        </div>
      </SubItem>
      <SubItem>
        <p>Threshold Limit 2</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Value"
              label="Value"
              value={limit2}
              type="number"
              onChange={(e) => {
                setLimit2(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={limit2Color}
              onChange={(e) => {
                setLimit2Color(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: limit2Color }} />
        </div>
      </SubItem>
      <SubItem>
        <p>Threshold Limit 3</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Value"
              label="Value"
              value={limit3}
              type="number"
              onChange={(e) => {
                setLimit3(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={limit3Color}
              onChange={(e) => {
                setLimit3Color(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: limit3Color }} />
        </div>
      </SubItem>
      <SubItem>
        <p>Fallback</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={fallbackColor}
              onChange={(e) => {
                setFallbackColor(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: fallbackColor }} />
        </div>
      </SubItem>
    </>
  );

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({
      limit1,
      limit1Color,
      limit2,
      limit2Color,
      limit3,
      limit3Color,
      fallbackColor,
    });
  };

  return (
    <EditItemDialog
      title={`Edit ${threshold?.key || ""} Threshold Template`}
      text={`Edit the details of this Threshold Template`}
      FormElements={formElements}
      open={open}
      onClose={onClose}
      onSave={(e: any) => save(e)}
    />
  );
}
