import { Box, Grid, Button, styled, Switch, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Settings } from "../../api";
import AddIcon from "@mui/icons-material/Add";
import { deviceMetrics } from "../../app/config/config";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CustomTable from "../../components/table/Table";
import { getSettings } from "./settingsSlice";
import CreateThresholdDialog from "./CreateThresholdDialog";
import EditThresholdDialog from "./EditThresholdDialog";
import DeleteItemDialog from "../../components/delete-item-dialog/DeleteItemDialog";

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #c3c3c3",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "right",
  gap: "16px",
}));

export default function ThresholdTemplates() {
  const dispatch = useAppDispatch();
  const tenants = useAppSelector((s) => s.tenants);
  const settings = useAppSelector((s) => s.settings);
  const [thresholdTemplatesSettings, setThresholdTemplatesSettings] =
    useState<any>([]);
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editThresholdIndex, setEditThresholdIndex] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteThresholdIndex, setDeleteThresholdIndex] = useState(null);

  useEffect(() => {
    setThresholdTemplatesSettings(
      settings.data.filter((s) => s.type === "thresholdtemplates")
    );
  }, [settings]);

  useEffect(() => {
    if (!editDialog) {
      setEditThresholdIndex(null);
    }
  }, [editDialog]);

  useEffect(() => {
    if (!deleteDialog) {
      setDeleteThresholdIndex(null);
    }
  }, [deleteDialog]);

  // const updateMetricSetting = (metric: any, value: any) => {
  //   setChangedZeroMetricsSettings((s: any) => {
  //     return {...s, [metric]: value}
  //   })
  //   setSettingsChanged(true)
  // }

  // const discardChanges = () => {
  //   setChangedZeroMetricsSettings(false)
  //   setSettingsChanged(false)
  // }

  const openEditDialog = (id: string) => {
    const editIndex = thresholdTemplatesSettings.findIndex(
      (t: any) => t.id === id
    );

    if (editIndex !== -1) {
      setEditThresholdIndex(editIndex);
      setEditDialog(true);
    }
  };

  const openDeleteDialog = (id: string) => {
    const deleteIndex = thresholdTemplatesSettings.findIndex(
      (t: any) => t.id === id
    );

    if (deleteIndex !== -1) {
      setDeleteThresholdIndex(deleteIndex);
      setDeleteDialog(true);
    }
  };

  const saveCreate = async ({ name, ...rest }: any) => {
    if (!tenants.selected) return;
    try {
      await Settings.create(tenants.selected, "thresholdtemplates", name, rest);
      dispatch(getSettings(tenants.selected));
      setAddDialog(false);
    } catch (error) {}
  };

  const saveEdit = async (data: any) => {
    if (!tenants.selected || editThresholdIndex === null) return;
    const threshold = thresholdTemplatesSettings[editThresholdIndex];
    try {
      await Settings.create(
        tenants.selected,
        "thresholdtemplates",
        threshold.key,
        data
      );
      dispatch(getSettings(tenants.selected));
      setEditDialog(false);
    } catch (error) {}
  };

  const saveDelete = async (id: string) => {
    if (!tenants.selected || deleteThresholdIndex === null) return;
    const threshold = thresholdTemplatesSettings[deleteThresholdIndex];

    try {
      await Settings.delete(tenants.selected, threshold.id);
      dispatch(getSettings(tenants.selected));
      setDeleteDialog(false);
    } catch (error) {}
  };

  return (
    <>
      <Box>
        <Toolbar
          sx={{ alignItems: "baseline", justifyContent: "right", gap: "24px" }}
          disableGutters
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddDialog(true)}
          >
            {" "}
            New Threshold
          </Button>
        </Toolbar>
        <CustomTable
          name="Threshold Templates"
          data={thresholdTemplatesSettings.map((d: any) => ({
            Name: `${d.key}`,
            key: d.id,
          }))}
          headers={["Name"]}
          viewButton={false}
          editButton={true}
          deleteButton={true}
          editItem={openEditDialog}
          deleteItem={openDeleteDialog}
        />
      </Box>
      <CreateThresholdDialog
        open={addDialog}
        onClose={() => setAddDialog(false)}
        onSave={(data: any) => saveCreate(data)}
      />
      <EditThresholdDialog
        open={editDialog}
        threshold={
          editThresholdIndex === null
            ? {}
            : thresholdTemplatesSettings[editThresholdIndex]
        }
        onSave={(data: any) => saveEdit(data)}
        onClose={() => setEditDialog(false)}
      />
      <DeleteItemDialog
        title={`Delete ${
          deleteThresholdIndex === null
            ? ""
            : thresholdTemplatesSettings[deleteThresholdIndex]?.key || ""
        } Threshold Template`}
        text={`Are you sure you want to delete this Threshold Template? It cannot be undone.`}
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        onConfirm={saveDelete}
      />
    </>
  );
}
