import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from '../../components/table/Table';
import Tabs from '../../components/tabs/Tabs';
import { Areas as AreasApi } from '../../api';
import EditAreaDialog from './EditAreaDialog';
import CreateHeatmapDialog from './CreateHeatmapDialog';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import Datagroups from '../../components/datagroups/Datagroups';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateArea } from '../location/areasSlice';
import Usecases from '../../components/usecases/Usecases';

export default function Area() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);
  const devices = useAppSelector((s) => s.devices);
  const areas = useAppSelector((s) => s.areas);
  const [areaIndex, setAreaIndex] = useState<number | null>(null);

  useEffect(() => {
    if (areas.status !== 'idle') return;
    const index = areas.data.findIndex((a) => a.id === areas.selected)
    if (index === -1 && areas.selected) {
      return navigate(`/${tenants.selected}/${locations.selected}`);
    }
    setAreaIndex(index);
  }, [areas.data, tenants.selected, locations.selected, areas.selected, areas.status, navigate]);

  const [heatmaps, setHeatmaps] = useState<any[]>([]);
  const [customDevices, setCustomDevices] = useState<any[]>([]);

  const [addDialog, setAddDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});
  const [datagroupCount, setDatagroupCount] = useState(0);
  const [usecaseCount, setUsecaseCount] = useState(0);
  const [datagroupsData, setDatagroupsData] = useState([]);

  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState('');

  const receiveDataGroups = (data: any) => {
    setDatagroupsData(data)
  }

  useEffect(() => {
    if (areaIndex === null) return;
    const devicesResults = devices.data.map((d: any) => ({
      Name: `${d.name}`,
      "Serial Number": `${d.serial_number}`,
      Heatmap: d.heatmap,
      Disabled: d.disabled,
      key: d.id,
    }))

    const heatmapsResponse = areas.data[areaIndex].heatmaps.map((h: any) => {
      return devicesResults.find((d: any) => d.key === h);
    }).filter(h => !!h);

    setCustomDevices(devicesResults);
    setHeatmaps(heatmapsResponse);

  }, [devices.data, areaIndex, areas.data])

  const saveCreate = async (data: any) => {
    if (!tenants.selected || !locations.selected || !areas.selected || areaIndex === null) return;

    const currentDeviceIds = heatmaps.map((h: any) => h.key);
    try {
      const updates = await AreasApi.update(
        tenants.selected,
        locations.selected,
        areas.selected,
        data.name,
        data.description,
        [...currentDeviceIds, data.device]
      );
      dispatch(updateArea({ index: areaIndex, area: updates.data }))
      setAddDialog(false);
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  const saveEdit = async (data: any) => {
    if (!tenants.selected || !locations.selected || !areas.selected || areaIndex === null) return;

    try {
      const updates = await AreasApi.update(
        tenants.selected,
        locations.selected,
        areas.selected,
        data.name,
        data.description,
        areas.data[areaIndex].heatmaps
      );
      dispatch(updateArea({ index: areaIndex, area: updates.data }))
      setEditDialog(false);
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  const saveDelete = async () => {
    if (!tenants.selected || !locations.selected || !areas.selected || areaIndex === null) return;

    const currentDeviceIds = heatmaps.map((h: any) => h.key);
    const updatedDeviceIds = currentDeviceIds.filter(item => item !== selected.key);
    try {
      const updates = await AreasApi.update(
        tenants.selected,
        locations.selected,
        areas.selected,
        areas.data[areaIndex].name,
        areas.data[areaIndex].description,
        updatedDeviceIds
      );
      dispatch(updateArea({ index: areaIndex, area: updates.data }))
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const heatmap = heatmaps.find((key: { key: string; }) => key.key === id);
    if (!heatmap) return;
    setSelected(heatmap);
    setDeleteDialog(true);
  }

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])

  // if (notFound) {
  //   // return <Navigate to="../404" replace />;
  //   return <Typography>No area found</Typography>;
  // }

  const heatmapHeaders = ['Name', 'Serial Number', 'Disabled'];

  const tabs = [
    {
      label: `Use Cases (${usecaseCount})`,
      Component: (
        // <Typography>Use cases</Typography>
        <Usecases id={areas.selected || ''} datagroups={datagroupsData} count={(c: number) => setUsecaseCount(c)} />
      )
    },
    {
      label: `Data Groups (${datagroupCount})`,
      Component: (
        <Datagroups id={areas.selected || ''} datagroupsData={(data: any) => receiveDataGroups(data)} count={(c: number) => setDatagroupCount(c)} />
      )
    },
    {
      label: `Heatmaps (${heatmaps.length})`,
      Component: (
        <div>
          <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}> New Heatmap</Button>
          </Toolbar >
          <CustomTable
            name="Heatmaps"
            data={heatmaps}
            headers={heatmapHeaders}
            viewButton={false}
            editButton={false}
            deleteButton={true}
            deleteItem={(id: string) => openAndSelectDelete(id)}
          />
        </div>
      )
    }
  ];

  if (areaIndex === null) {
    return <div>Loading...</div>
  }
  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>{areas.data[areaIndex].name}</Typography>
        <Button variant='contained' color='primary' startIcon={<EditIcon />} onClick={() => setEditDialog(true)}>Edit</Button>
      </Toolbar>
      {/* <Chip label="Area" sx={{ backgroundColor: 'warning.main', color: '#ffffff' }} /> */}
      <p>{areas.data[areaIndex].description}</p>
      <Tabs tabs={tabs} />
      <EditAreaDialog open={editDialog} area={areas.data[areaIndex]} onSave={(data: any) => saveEdit(data)} onClose={() => setEditDialog(false)} />
      <DeleteItemDialog title={`Remove heatmap for ${selected?.Name || ''}`} text={`Are you sure you want to delete this device's heatmap from this area? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
      <CreateHeatmapDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} devices={customDevices} heatmaps={heatmaps} />
    </div>
  )
}
