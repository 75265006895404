import { Toolbar, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Usecases as UsecasesApi } from '../../api';
import CustomTable from '../table/Table';
import CreateUsecaseDialog from './CreateUsecaseDialog';
import DeleteItemDialog from '../delete-item-dialog/DeleteItemDialog';
import { useParams } from 'react-router-dom';

export default function Usecases(props: any) {

  const { id, datagroups, count } = props;

  const { tenant: tenantSlug } = useParams();

  const [addDialog, setAddDialog] = useState(false)
  const [usecases, setUsecases] = useState<any[]>([])
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});
  const [error, setError] = useState('');

  const getUsecases = async (tenantId: any, id: string) => {
    try {
      const UsecasesResponse = await UsecasesApi.getAllByLinkedId(tenantId, id);
      setUsecases(
        UsecasesResponse.data.sort((a: any, b: any) => a.display_position - b.display_position)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const saveCreate = async (data: any) => {
    const selectedDataSource = datagroups.find((d: any) => d.id === data.dataSource)
    try {
      await UsecasesApi.create(
        tenantSlug,
        data.displayType,
        data.name,
        data.subTitle,
        data.cameraFeed,
        data.cameraFeeds,
        data.cameraFeedSubArea,
        data.cameraStreamUrl,
        data.dataSource,
        data.dataSources,
        selectedDataSource?.metric,
        data.unitOfMeasurement,
        data.timeRange,
        data.mainTileOptions,
        data.subTileOptions,
        data.graphInterval,
        data.compareTo,
        data.displayLastUpdated,
        data.displayNotifications,
        data.timeIntervals,
        data.tags,
        data.disabled,
        data.endPosition,
        data.limit1,
        data.limit1Color,
        data.limit2,
        data.limit2Color,
        data.limit3,
        data.limit3Color,
        data.fallbackColor,
        data.secondaryLimit1,
        data.secondaryLimit2,
        data.secondaryLimit3,
        id
      )
      await getUsecases(tenantSlug, id)
      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveDelete = async () => {
    // do delete API
    try {
      await UsecasesApi.delete(tenantSlug, selected.id)
      await getUsecases(tenantSlug, id)
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const usecase = usecases.find(d => d.id === id);
    if (!usecase) return;
    setSelected(usecase);
    setDeleteDialog(true);
  }

  const saveNewPositions = async (tenant_id: any, id: string, newPosition: any) => {
    try {
      return await UsecasesApi.patch(
        tenant_id,
        id,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        newPosition,
      );
      // await getUsecases(tenantSlug, id)
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  const reorderDisplayPositions = (newOrder: any) => {
    const order = newOrder.map((newOrderItem: any, index: any) => {
      // return { tenant_id: tenantSlug, id: newOrderItem.key, newPosition: String(index) };
      return saveNewPositions(tenantSlug, newOrderItem.key, String(index));
    });


    Promise.all(order).then(a => getUsecases(tenantSlug, id));
  }

  useEffect(() => {
    count(usecases.length);
  }, [usecases, count])

  useEffect(() => {
    if (!id) return;
    getUsecases(tenantSlug, id);
    return () => {
      setUsecases([]);
    }
  }, [id, tenantSlug]);

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])

  const headers = ['Name', 'Tags'];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
        <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}>New Use Case</Button>
      </Toolbar >
      <CustomTable
        name="Use Cases"
        data={usecases.map(uc => ({
          Name: uc.name,
          Tags: uc.tags.join(', '),
          Position: uc.display_position,
          key: uc.id
        }))}
        headers={headers}
        viewButton={true}
        editButton={false}
        deleteButton={true}
        routePrefix="usecases"
        deleteItem={(id: string) => openAndSelectDelete(id)}
        editDisplayPosition={(id: string) => reorderDisplayPositions(id)}
      />
      <CreateUsecaseDialog datagroups={datagroups} open={addDialog} endPosition={String(+usecases[usecases.length - 1]?.display_position + 1)} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} />
      <DeleteItemDialog title={`Delete ${selected?.name || ''} Usecase`} text={`Are you sure you want to delete this Usecase? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
    </div>
  )
}
