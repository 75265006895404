import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getAreas, resetAreas, setSelected } from '../location/areasSlice';

export default function AreaWrapper() {
  const dispatch = useAppDispatch();
  const { area } = useParams();
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);
  const areas = useAppSelector((s) => s.areas);

  useEffect(() => {
    if (areas.status !== 'idle') return;
    const index = areas.data.findIndex((a) => a.id === areas.selected)
    if (index === -1 && areas.selected) {
      return navigate(`/${tenants.selected}/${locations.selected}`);
    }
  }, [areas.data, tenants.selected, locations.selected, areas.selected, areas.status, navigate]);

  useEffect(() => {
    if (!areas.selected) {
      dispatch(setSelected(area));
    }
  }, [area, dispatch, areas.selected]);

  useEffect(() => {
    if (!areas.selected) return;

    return () => {
      dispatch(setSelected(''));
    }
  }, [areas.selected, dispatch])

  return (
    <Outlet />
  )
}
