export const deviceMetrics = [
  {
    type: "age",
    formula: "average",
  },
  {
    type: "areaCounting",
    formula: "total",
  },
  {
    type: "areaDensity",
    formula: "average",
  },
  {
    type: "areaDwell",
    formula: "total",
  },
  {
    type: "audio",
    formula: "average",
  },
  {
    type: "crowd",
    formula: "average",
  },
  {
    type: "crowdPeople",
    formula: "average",
  },
  {
    type: "crowdDensity",
    formula: "maximum",
  },
  {
    type: "density",
    formula: "maximum",
  },
  {
    type: "dwell",
    formula: "total",
  },
  {
    type: "emotion",
    formula: "average",
  },
  {
    type: "facemask",
    formula: "total",
  },
  {
    type: "gender",
    formula: "average",
  },
  {
    type: "lux",
    formula: "average",
  },
  {
    type: "temperature",
    formula: "average",
  },
  {
    type: "temperature2",
    formula: "average",
  },
  {
    type: "humidity",
    formula: "average",
  },
  {
    type: "suitcase",
    formula: "total",
  },
  {
    type: "peopleWithBikes",
    formula: "total",
  },
  {
    type: "activity",
    formula: "total",
  },
  {
    type: "doorOpen",
    formula: "total"
  },
  {
    type: "doorClosed",
    formula: "total"
  },
  {
    type: "waterLeak",
    formula: "total"
  },
  {
    type: "waterNoLeak",
    formula: "total"
  },
  {
    type: "peopleCounting",
    formula: "total",
  },
  {
    type: "peopleIn",
    formula: "total",
  },
  {
    type: "peopleOut",
    formula: "total",
  },
  {
    type: "vehicleIn",
    formula: "total",
  },
  {
    type: "vehicleOut",
    formula: "total",
  },
  {
    type: "airQuality",
    formula: "average",
  },
  {
    type: "particulateMatter",
    formula: "average",
  },
  {
    type: "particleSize",
    formula: "average",
  },
  {
    type: "co2",
    formula: "average",
  },
  {
    type: "pressure",
    formula: "average",
  },
  {
    type: "indoorAirQuality",
    formula: "average",
  },
  {
    type: "organicCompound",
    formula: "average",
  },
  {
    type: "gasPPB",
    formula: "average",
  },
  {
    type: "gasUGM3",
    formula: "average",
  },
  { 
    type: "avgDensity",
    formula: "maximum",
  },
  { 
    type: "maxDensity",
    formula: "maximum",
  },
  { 
    type: "minDensity",
    formula: "maximum",
  },
  { 
    type: "avgPeople",
    formula: "total",
  },
  { 
    type: "maxPeople",
    formula: "total",
  },
  { 
    type: "minPeople",
    formula: "total",
  },
  { 
    type: "alerts",
    formula: "total",
  },
  { 
    type: "fireAlarm",
    formula: "total",
  },
  { 
    type: "ppe",
    formula: "total",
  },
  { 
    type: "trespass",
    formula: "total",
  },
  { 
    type: "presence",
    formula: "total",
  },
  { 
    type: "distance",
    formula: "average",
  },
  { 
    type: "positionNormal",
    formula: "average",
  },
  { 
    type: "positionTilt",
    formula: "average",
  },
  { 
    type: "occupacity",
    formula: "average",
  },
  { 
    type: "illuminance",
    formula: "average",
  },
  { 
    type: "current",
    formula: "average",
  },
  {
    type: "currentPhaseA",
    formula: "average",
  },
  {
    type: "currentPhaseB",
    formula: "average",
  },
  {
    type: "currentPhaseC",
    formula: "average",
  },
  {
    type: "accelerationX",
    formula: "average",
  },
  {
    type: "accelerationY",
    formula: "average",
  },
  {
    type: "accelerationZ",
    formula: "average",
  },
  {
    type: "velocityX",
    formula: "average",
  },
  {
    type: "velocityY",
    formula: "average",
  },
  {
    type: "velocityZ",
    formula: "average",
  },
  {
    type: "dataX",
    formula: "average",
  },
  {
    type: "dataY",
    formula: "average",
  },
  {
    type: "dataZ",
    formula: "average",
  },
  {
    type: "tilt",
    formula: "average",
  },
  {
    type: "vibration",
    formula: "average",
  },
  {
    type: "solarPanel",
    formula: "average",
  },
  {
    type: "precipitation",
    formula: "average",
  },
  {
    type: "solarRadiation",
    formula: "average",
  },
  {
    type: "minTemperature",
    formula: "average",
  },
  {
    type: "maxTemperature",
    formula: "average",
  },
  {
    type: "minHumidity",
    formula: "average",
  },
  {
    type: "maxHumidity",
    formula: "average",
  },
  {
    type: "windDirection",
    formula: "average",
  },
  {
    type: "windSpeed",
    formula: "average",
  },
  {
    type: "maxWindSpeed",
    formula: "average",
  },
  {
    type: "windGustSpeed",
    formula: "average",
  },
  {
    type: "informationCallsInitiated",
    formula: "total",
  },
  {
    type: "informationCallsAnswered",
    formula: "total",
  },
  {
    type: "informationCallsAnswerTime",
    formula: "average",
  },
  {
    type: "emergencyCallsInitiated",
    formula: "total",
  },
  {
    type: "emergencyCallsAnswered",
    formula: "total",
  },
  {
    type: "emergencyCallsAnswerTime",
    formula: "average",
  },
  {
    type: "shampooBottle",
    formula: "total",
  },
  {
    type: "pillBottle",
    formula: "total",
  },
  {
    type: "screwDriver",
    formula: "total",
  },
  {
    type: "sprayCan",
    formula: "total",
  },
  {
    type: "pressed",
    formula: "total",
  },
  {
    type: "volts",
    formula: "average",
  },
  {
    type: "frequency",
    formula: "average",
  },
  {
    type: "realPower",
    formula: "total",
  },
  {
    type: "emergency", 
    formula: "maximum"
  },
  {
    type: "conveyor", 
    formula: "maximum"
  },
  {
    type: "door", 
    formula: "maximum"
  },
  {
    type: "button1", 
    formula: "total"
  },
  {
    type: "button2", 
    formula: "total"
  },
  {
    type: "button3", 
    formula: "total"
  },
  {
    type: "analogue", 
    formula: "average"
  },
  {
    type: "batteryPercentage",
    formula: "average"
  },
  {
    type: "usbPowered",
    formula: "average"
  },
  {
    type: "missedConnections",
    formula: "average"
  },
  {
    type: "buttonPressed",
    formula: "average"
  },
  {
    type: "cableConnected",
    formula: "average"
  },
  {
    type: "wet",
    formula: "average"
  },
  {
    type: "tvoc",
    formula: "average"
  },
  {
    type: "ambientNoise",
    formula: "average"
  },
  {
    type: "iaqIndex",
    formula: "average"
  },
  {
    type: "open",
    formula: "average"
  },
  {
    type: "tamperDetection",
    formula: "average"
  },
  {
    type: "mainsVolts",
    formula: "average"
  },
  {
    type: "mainsCurrent",
    formula: "average"
  },
  {
    type: "mainsRealPower",
    formula: "average"
  },
  {
    type: "mainsApparentPower",
    formula: "average"
  },
  {
    type: "mainsPowerFactor",
    formula: "average"
  },
  {
    type: "mainsDeltaEnergy",
    formula: "average"
  },
  {
    type: "mainsFrequency",
    formula: "average"
  },
  {
    type: "peopleAreaDwell",
    formula: "average"
  },
  {
    type: "peopleAreaDensity",
    formula: "average"
  },
  {
    type: "peopleAreaCounting",
    formula: "average"
  },
];


export const tagOptions = ['operations', 'safety', 'passenger experience'];

export const formulaOptions = ['average', 'total', 'maximum', 'minimum'];
export const formulaOptionsPerMetric: any = {
  age: ['average'],
  areaCounting: ['average', 'total', 'maximum', 'minimum'],
  areaDensity: ['average', 'total', 'maximum', 'minimum'],
  areaDwell: ['average', 'total', 'maximum', 'minimum'],
  audio: ['average', 'total', 'maximum', 'minimum'],
  crowd: ['average'],
  crowdPeople: ['average', 'maximum', 'minimum'],
  crowdDensity: ['average', 'maximum', 'minimum'],
  density: ['average', 'maximum', 'minimum'],
  avgDensity: ['average', 'maximum', 'minimum'],
  maxDensity: ['average', 'maximum', 'minimum'],
  minDensity: ['average', 'maximum', 'minimum'],
  avgPeople: ['average', 'maximum', 'minimum'],
  maxPeople: ['average', 'maximum', 'minimum'],
  minPeople: ['average', 'maximum', 'minimum'],
  distance: ['average', 'total', 'maximum', 'minimum'],
  positionNormal: ['average', 'total', 'maximum', 'minimum'],
  positionTilt: ['average', 'total', 'maximum', 'minimum'],
  dwell: ['average', 'total', 'maximum', 'minimum'],
  emotion: ['average'],
  facemask: ['average', 'total', 'maximum', 'minimum'],
  gender: ['average'],
  lux: ['average', 'total', 'maximum', 'minimum'],
  temperature: ['average'],
  temperature2: ['average'],
  humidity: ['average'],
  suitcase: ['average', 'total', 'maximum', 'minimum'],
  peopleWithBikes: ['average', 'total', 'maximum', 'minimum'],
  activity: ['average', 'total', 'maximum', 'minimum'],
  doorOpen: ['average', 'total', 'maximum', 'minimum'],
  doorClosed: ['average', 'total', 'maximum', 'minimum'],
  waterLeak: ['average', 'total', 'maximum', 'minimum'],
  waterNoLeak: ['average', 'total', 'maximum', 'minimum'],
  peopleCounting: ['average', 'total', 'maximum', 'minimum'],
  peopleIn: ['average', 'total', 'maximum', 'minimum'],
  peopleOut: ['average', 'total', 'maximum', 'minimum'],
  vehicleIn: ['average', 'total', 'maximum', 'minimum'],
  vehicleOut: ['average', 'total', 'maximum', 'minimum'],
  airQuality: ['average', 'total', 'maximum', 'minimum'],
  particulateMatter:['average', 'total', 'maximum', 'minimum'],
  particleSize:['average', 'total', 'maximum', 'minimum'],
  co2:['average', 'total', 'maximum', 'minimum'],
  pressure:['average', 'total', 'maximum', 'minimum'],
  indoorAirQuality:['average', 'total', 'maximum', 'minimum'],
  organicCompound:['average', 'total', 'maximum', 'minimum'],
  gasPPB:['average', 'total', 'maximum', 'minimum'],
  gasUGM3:['average', 'total', 'maximum', 'minimum'],
  alerts:['total'],
  fireAlarm:['average', 'total', 'maximum', 'minimum'],
  ppe:['average', 'total', 'maximum', 'minimum'],
  trespass:['average', 'total', 'maximum', 'minimum'],
  presence:['average', 'total', 'maximum', 'minimum'],
  occupacity:['average', 'total', 'maximum', 'minimum'],
  illuminance:['average', 'total', 'maximum', 'minimum'],
  current:['average', 'maximum', 'minimum'],
  currentPhaseA: ['average', 'maximum', 'minimum'],
  currentPhaseB: ['average', 'maximum', 'minimum'],
  currentPhaseC: ['average', 'maximum', 'minimum'],
  accelerationX: ['average', 'total', 'maximum', 'minimum'],
  accelerationY: ['average', 'total', 'maximum', 'minimum'],
  accelerationZ: ['average', 'total', 'maximum', 'minimum'],
  velocityX: ['average', 'total', 'maximum', 'minimum'],
  velocityY: ['average', 'total', 'maximum', 'minimum'],
  velocityZ: ['average', 'total', 'maximum', 'minimum'],
  dataX: ['average', 'total', 'maximum', 'minimum'],
  dataY: ['average', 'total', 'maximum', 'minimum'],
  dataZ: ['average', 'total', 'maximum', 'minimum'],
  tilt: ['average', 'total', 'maximum', 'minimum'],
  vibration: ['average', 'total', 'maximum', 'minimum'],
  solarPanel: ['average', 'total', 'maximum', 'minimum'],
  precipitation: ['average', 'total', 'maximum', 'minimum'],
  solarRadiation: ['average', 'total', 'maximum', 'minimum'],
  minTemperature: ['average', 'total', 'maximum', 'minimum'],
  maxTemperature: ['average', 'total', 'maximum', 'minimum'],
  minHumidity: ['average', 'total', 'maximum', 'minimum'],
  maxHumidity: ['average', 'total', 'maximum', 'minimum'],
  windDirection: ['average', 'total', 'maximum', 'minimum'],
  windSpeed: ['average', 'total', 'maximum', 'minimum'],
  maxWindSpeed: ['average', 'total', 'maximum', 'minimum'],
  windGustSpeed: ['average', 'total', 'maximum', 'minimum'],
  informationCallsInitiated: ['average', 'total', 'maximum', 'minimum'],
  informationCallsAnswered: ['average', 'total', 'maximum', 'minimum'],
  informationCallsAnswerTime: ['average', 'total', 'maximum', 'minimum'],
  emergencyCallsInitiated: ['average', 'total', 'maximum', 'minimum'],
  emergencyCallsAnswered: ['average', 'total', 'maximum', 'minimum'],
  emergencyCallsAnswerTime: ['average', 'total', 'maximum', 'minimum'],
  shampooBottle: ['average', 'total', 'maximum', 'minimum'],
  pillBottle: ['average', 'total', 'maximum', 'minimum'],
  screwDriver: ['average', 'total', 'maximum', 'minimum'],
  sprayCan: ['average', 'total', 'maximum', 'minimum'],
  pressed: ['average', 'total', 'maximum', 'minimum'],
  volts: ['average', 'total', 'maximum', 'minimum'],
  frequency: ['average', 'total', 'maximum', 'minimum'],
  realPower: ['average', 'total', 'maximum', 'minimum'],
  emergency: ['average', 'total', 'maximum', 'minimum'],
  conveyor: ['average', 'total', 'maximum', 'minimum'],
  door: ['average', 'total', 'maximum', 'minimum'],
  button1: ['average', 'total', 'maximum', 'minimum'],
  button2: ['average', 'total', 'maximum', 'minimum'],
  button3: ['average', 'total', 'maximum', 'minimum'],
  analogue: ['average', 'total', 'maximum', 'minimum'],
  batteryPercentage: ['average', 'total', 'maximum', 'minimum'],
  usbPowered: ['average', 'total', 'maximum', 'minimum'],
  missedConnections: ['average', 'total', 'maximum', 'minimum'],
  buttonPressed: ['average', 'total', 'maximum', 'minimum'],
  cableConnected: ['average', 'total', 'maximum', 'minimum'],
  wet: ['average', 'total', 'maximum', 'minimum'],
  tvoc: ['average', 'total', 'maximum', 'minimum'],
  ambientNoise: ['average', 'total', 'maximum', 'minimum'],
  iaqIndex: ['average', 'total', 'maximum', 'minimum'],
  open: ['average', 'total', 'maximum', 'minimum'],
  tamperDetection: ['average', 'total', 'maximum', 'minimum'],
  mainsVolts: ['average', 'total', 'maximum', 'minimum'],
  mainsCurrent: ['average', 'total', 'maximum', 'minimum'],
  mainsRealPower: ['average', 'total', 'maximum', 'minimum'],
  mainsApparentPower: ['average', 'total', 'maximum', 'minimum'],
  mainsPowerFactor: ['average', 'total', 'maximum', 'minimum'],
  mainsDeltaEnergy: ['average', 'total', 'maximum', 'minimum'],
  mainsFrequency: ['average', 'total', 'maximum', 'minimum'],
  peopleAreaDwell: ['average', 'total', 'maximum', 'minimum'],
  peopleAreaDensity: ['average', 'total', 'maximum', 'minimum'],
  peopleAreaCounting: ['average', 'total', 'maximum', 'minimum'],
};

export const apiServices = [
  {
    name: 'V-App',
    value: 'vapp'
  },
  {
    name: 'MQTT',
    value: 'mqtt'
  },
  {
    name: 'Cogniac',
    value: 'cogniac'
  },
  {
    name: 'Meraki',
    value: 'meraki'
  }
];

export const deviceTypes = [
  {
    name: 'Camera',
    value: 'camera'
  },
  {
    name: 'IoT',
    value: 'iot'
  },
  {
    name: 'MICA',
    value: 'mica'
  }
];

export const thresholdOrderOptions = [
  {
    name: 'Accending',
    value: 'accending'
  },
  {
    name: 'Descending',
    value: 'descending'
  }
];

export const thresholdTemplates = [
  {
    name: "Lux",
    limit1: "50",
    limit1Color: "#dc3545",
    limit2: "119",
    limit2Color: "#ffc107",
    limit3: "200",
    limit3Color: "#28a745",
    fallbackColor: "#dc3595"
  },
  {
    name: "Dwell Time",
    limit1: "30",
    limit1Color: "#28a745",
    limit2: "120",
    limit2Color: "#ffc107",
    limit3: "200",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Audio Level",
    limit1: "10",
    limit1Color: "#28a745",
    limit2: "85",
    limit2Color: "#ffc107",
    limit3: "120",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Density",
    limit1: "0.5",
    limit1Color: "#28a745",
    limit2: "1",
    limit2Color: "#ffc107",
    limit3: "3",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "AQI",
    limit1: "50",
    limit1Color: "#28a745",
    limit2: "150",
    limit2Color: "#ffc107",
    limit3: "250",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Co2",
    limit1: "600",
    limit1Color: "#28a745",
    limit2: "1000",
    limit2Color: "#ffc107",
    limit3: "1600",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Temperature",
    limit1: "0",
    limit1Color: "#3545dc",
    limit2: "25",
    limit2Color: "#28a745",
    limit3: "30",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Humidity",
    limit1: "55",
    limit1Color: "#28a745",
    limit2: "65",
    limit2Color: "#ffc107",
    limit3: "100",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Organic Compounds",
    limit1: "1",
    limit1Color: "#28a745",
    limit2: "3",
    limit2Color: "#ffc107",
    limit3: "10",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Particulate Matter (PM2.5)",
    limit1: "11",
    limit1Color: "#28a745",
    limit2: "35",
    limit2Color: "#ffc107",
    limit3: "45",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Pressure",
    limit1: "1013",
    limit1Color: "#28a745",
    limit2: "1030",
    limit2Color: "#ffc107",
    limit3: "1050",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  },
  {
    name: "Average Particle Size",
    limit1: "2.5",
    limit1Color: "#28a745",
    limit2: "10",
    limit2Color: "#ffc107",
    limit3: "20",
    limit3Color: "#dc3545",
    fallbackColor: "#dc3595"
  }
]

export const iotModels = [
  "Advantec WISE-2410-EB", // Vibration
  "EM310-UDL-868M", // Milesight Ultrasonic Distance/Level Sensor
  "ERSSound", // Noise
  "ET0_LoRa", // Wind and Rain
  "HummBox Weather Station", // Wind and Rain
  "IM Buildings IB010-001.02", // People Counting
  "Meraki MT20", // Door sensor
  "Netop 473-3919", // Waste bin sensor
  "Netvox E718E", // Three-Axis Digital Accelerometer &NTC Thermistor
  "Netvox R712", // Outdoor Temperature and Humidity Sensor
  "Netvox R718AB", // Wireless Temperature and Humidity Sensor
  "Netvox R718E", // Three-Axis Digital Accelerometer &NTC Thermistor
  "Netvox R718EA", // Incinometer
  "Netvox R718LB", // Wireless Hall Type Open/CloseDetection Sensor
  "Netvox R718N115", // Current Meter
  "Netvox R718N17", // current Meter
  "Netvox R718N315", // current Meter
  "Netvox R718PG", // Light Sensor
  "Netvox R718WA", // Water Leak Detector
  "Netvox R718WB", // Water Leak Detector with Rope Sensor
  "Netvox RB11E", // Wireless Occupancy/Temperature/Light Sensor
  "Netvox R718CK2", // Dual Temperature probes
  "Polysense WxS 8800-004", // Noise
  "Synetica ENL-OAQ", // Outdoor Air Quality Sensor + CO2
  "merakiCustomModel",
  "labPeopleDetection",
  "labTemperature",
  "labHumidity",
  "ciscoAV203",
  "labDoorSensor",
  "ciscoAV204",
  "labEmergencyAlert",
  "labVolts",
  "labFrequency",
  "labCurrent",
  "labRealPower",
  "ciscoR718N17",
  "plc1",
  "merakiMT",
];

// ERSSound
//  - audio
//  - temperature
//  - humidity

// IM Buildings IB010-001.02
//  - peopleCounting

// Netvox R718AB
//  - temperature
//  - humidity

// Netvox R718LB
//  - activity

// Netvox R718WA
//  - activity

// Polysense WxS 8800-004
//  - audio

// Synetica ENL-OAQ
//  - temperature
//  - humidity
//  - pressure
//  - particulateMatter
//  - particleSize
//  - indoorAirQuality
//  - organicCompound
//  - airQuality
//  - co2