import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Locations } from "../../api";

export interface LocationState {
  id: string;
  name: string;
  kafka_alert_alias: string;
  latitude: string;
  longitude: string;
  createdAt: number;
  updatedAt: number;
  areas: any[];
}

export interface LocationsState {
  data: LocationState[];
  status: 'idle' | 'loading' | 'failed';
  selected: string | undefined;
}

const initialState: LocationsState = {
  data: [],
  status: 'loading',
  selected: ''
}

export const getLocations = createAsyncThunk(
  'locations/getLocations',
  async (tenantId: string) => {
    const response = await Locations.getAll(tenantId);
    return response.data;
  }
);

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetLocations: (state) => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.selected = initialState.selected;
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    updateLocation: (state, action) => {
      state.data[action.payload.index] = {
        ...state.data[action.payload.index],
        ...action.payload.location
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(getLocations.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { setSelected, resetLocations, updateLocation } = locationsSlice.actions;


export default locationsSlice.reducer;