import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";

interface IConfirmationProps {
  open: Boolean;
  title: String;
  text: String;
  onConfirm: Function;
  onCancel: Function;
}

export default function ConfirmationDialog({
  open,
  title,
  text,
  onConfirm,
  onCancel,
}: IConfirmationProps) {
  const save = async () => {
    await onConfirm();
  };

  return (
    <ConfirmDialog
      title={title}
      text={text}
      open={open}
      onConfirm={() => save()}
      onClose={onCancel}
    />
  );
}
