import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Tenants } from "./api";

export interface TenantState {
  id: string;
  name: string;
  description: string;
  createdAt: number;
  updatedAt: number;
}

export interface TenantsState {
  data: TenantState[];
  status: 'idle' | 'loading' | 'failed';
  selected: string | undefined;
}

const initialState: TenantsState = {
  data: [],
  status: 'loading',
  selected: ''
}

export const getTenants = createAsyncThunk(
  'tenants/getTenants',
  async () => {
    const response = await Tenants.getAll();
    return response.data;
  }
);

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    updateTenant: (state, action) => {
      state.data[action.payload.index] = {
        ...state.data[action.payload.index],
        ...action.payload.tenant
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTenants.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTenants.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(getTenants.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { setSelected, updateTenant } = tenantsSlice.actions;


export default tenantsSlice.reducer;