import { styled } from '@mui/material';
import React, { useCallback, useState } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import SlateToolbar from './SlateToolbar';

const Container = styled('div')(({ theme }: { theme: any }) => ({
  width: '100%',
  backgroundColor: '#fff',
  padding: '1em',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px'
}));

// const initialValue: any[] = [{children: [{text: ''}]}]

export default function SlateApp({initialValue, onChange}: any) {
  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
  const [editor] = useState(() => withReact(createEditor()))

  return (
      <Container>
        <Slate editor={editor} value={initialValue} onChange={onChange}>
          <SlateToolbar />
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus
          />
        </Slate>
      </Container>
  )
}

const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}