import { Box, Button, Chip, Divider, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, styled, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { overviewWidgetOptions, widgetTypes } from '../../app/config/usecases/config';
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextEditor from '../../components/texteditor/TextEditor';
import { useAppSelector } from '../../app/hooks';

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  datagroups: any[];
}

const ParentItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '1px solid #c3c3c3'
}));

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '3px',
  border: '1px solid #ddd'
}));

const slateInitialValue: any[] = [{children: [{text: ''}]}]

export default function AddWidgetDialog({ open, onSave, onClose, datagroups }: ICreateProps) {

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [comparison, setComparison] = useState(false);
  const [featuredCard, setFeaturedCard] = useState("hide");
  const [cameraFeed, setCameraFeed] = useState("");
  const [cameraFeedSubArea, setCameraFeedSubArea] = useState("");
  const [subAreaOptions, setSubAreaOptions] = useState<any>([]);
  const [cameraFeedMetricOptions, setCameraFeedMetricOptions] = useState<any>([]);
  const [cameraFeedMetric, setCameraFeedMetric] = useState<any>([]);
  const [tiles, setTiles] = useState([]);
  const [textBox, setTextBox] = useState(null);
  const [layout, setLayout] = useState("");
  const [graphOption, setGraphOption] = useState("");
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("#28a745");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("#ffc107");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("#dc3545");
  const [fallbackColor, setFallbackColor] = useState("#dc3595");
  const [templatesMenuEl, setTemplatesMenuEl] = useState(null)
  const [thresholdTemplates, setThresholdTemplates] = useState<any[]>([]);

  const settings = useAppSelector((s) => s.settings);
  const devices = useAppSelector((s) => s.devices);

  useEffect(() => {
    const thresholds = settings.data.filter((s) => s.type === "thresholdtemplates").map((t: any) => ({
      name: t.key,
      ...t.value
    }))

    setThresholdTemplates(thresholds);
  
    return () => {
      setThresholdTemplates([]);
    }
  }, [settings.data])

  useEffect(() => {
    if (!open) {
      setName("");
      setType("");
      setDataSource("");
      setDataSources([])
      setComparison(false);
      setFeaturedCard("hide");
      setCameraFeed("");
      setCameraFeedSubArea("");
      setCameraFeedMetric("");
      setTiles([]);
      setTextBox(null);
      setLayout("");
      setGraphOption("");
      setLimit1("");
      setLimit1Color("#28a745");
      setLimit2("");
      setLimit2Color("#ffc107");
      setLimit3("");
      setLimit3Color("#dc3545");
      setFallbackColor("#dc3595");
    }
  }, [open])

  useEffect(() => {
    const typeLayout = widgetTypes.find(w => w.value === type)?.layouts;
    if (!typeLayout || !typeLayout.length) {
      setLayout("")
      return;
    }

    setLayout(typeLayout[0].value);
  }, [type])

  useEffect(() => {
    const typeGraphOptions = widgetTypes.find(w => w.value === type)?.graphOptions;
    if (!typeGraphOptions || !typeGraphOptions.length) {
      setGraphOption("");
      return;
    }
    setGraphOption(typeGraphOptions[0].value);
  }, [type]);

  useEffect(() => {
    const typeDG = widgetTypes.find((w) => w.value === type)?.datasource;
    if (!typeDG) {
      setDataSource("")
    }

    setTextBox(null)
  }, [type])

  const reverseColorOrder = () => {
    const col1 = limit1Color;
    const col3 = limit3Color;

    setLimit1Color(col3);
    setLimit3Color(col1);
  }

  const fillFromTemplate = (template: any) => {
    setLimit1Color(template.limit1Color);
    setLimit1(template.limit1);
    setLimit2Color(template.limit2Color);
    setLimit2(template.limit2);
    setLimit3Color(template.limit3Color);
    setLimit3(template.limit3);
    setFallbackColor(template.fallbackColor);
    setTemplatesMenuEl(null)
  }

  const onSlateChange = (value: any) => {
    setTextBox(value);
  }

  const handleTilesChanged = (event: any) => {
    const {
      target: { value },
    } = event;
    const maxTiles = 4;
    if (value.length > maxTiles) return;

    setTiles(typeof value === "string" ? value.split(",") : value);
  };

  const handlCameraFeedChange = (event: any) => {
    const { target: { value } } = event;
    setSubAreaOptions([])
    setCameraFeedMetricOptions([])
    if (value !== "") {
      const selectedDevice = devices.data.find(d => d.id === value)
      setSubAreaOptions(selectedDevice?.sub_areas)
      setCameraFeedMetricOptions(selectedDevice?.metrics)
    }
    setCameraFeed(value)
    setCameraFeedSubArea("")
    setCameraFeedMetric("")
  }

  const handlFeaturedCardChange = (event: any) => {
    const { target: { value } } = event;
    if (value === "hide") {
      setSubAreaOptions([])
      setCameraFeedMetricOptions([])
      setCameraFeed("")
      setCameraFeedSubArea("")
      setCameraFeedMetric("")
    }
    setFeaturedCard(value)
  }

  const handleDataSourcesChange = (event: any) => {
    const { target: { value } } = event;
    console.log(value);
    setDataSources(value);
  }

  const onlyAllowOneMetricType = (datagroup: any, dataSources: any, datagroups:any) => {
    if (!dataSources.length) {
      return false
    }
    const activeMetric = datagroups.find((dg:any) => dg.id === dataSources[0])?.metric
    if (activeMetric === datagroup.metric) {
      return false
    }
    return true
  }

  const formElements = (
    <>
      <FormControl fullWidth>
      <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    </FormControl>
    {/* <FormControl fullWidth>
      <TextField label="Type" required fullWidth value={type} onChange={(e) => setType(e.target.value)} />
    </FormControl> */}
    <FormControl required fullWidth>
      <InputLabel id="type">Type</InputLabel>
      <Select value={type} fullWidth label="Type" onChange={(e) => setType(e.target.value)} >
        {widgetTypes.map((option: any) => (
          <MenuItem value={option.value} key={option.name}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {(widgetTypes.find(w => w.value === type)?.layouts?.length) && (
      <FormControl required fullWidth>
        <InputLabel id="layoutLabel">Layout</InputLabel>
        <Select value={layout} labelId="layoutLabel" fullWidth label="Layout" onChange={(e) => setLayout(e.target.value)}>
          {widgetTypes.find(w => w.value === type)?.layouts?.map((layout) => (
            <MenuItem value={layout.value} key={layout.value}>{layout.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {(widgetTypes.find(w => w.value === type)?.datasource) && (
      <>
        <FormControl required fullWidth>
          <InputLabel id="dataSourceLabel">Data Source</InputLabel>
          <Select value={dataSource} labelId="dataSourceLabel" fullWidth label="Data Source" onChange={(e) => setDataSource(e.target.value)}>
            {datagroups.map((d: any) => (
              <MenuItem value={d.id} key={d.name}>{d.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel control={<Switch checked={comparison} onChange={(e) => setComparison(e.target.checked)} />} label="Show comparison line" />
      </>
    )}
    {(widgetTypes.find(w => w.value === type)?.datasources) && (
      <FormControl required fullWidth>
        <InputLabel id="dataSourcesLabel">Data Sources</InputLabel>
        <Select multiple value={dataSources} fullWidth label="Data Sources" onChange={(e) => { handleDataSourcesChange(e) }} >
          {datagroups.map((datagroup: any) => (
            <MenuItem disabled={onlyAllowOneMetricType(datagroup, dataSources, datagroups)} value={datagroup.id} key={datagroup.name}>{datagroup.name}</MenuItem>
          ))}
        </Select>
      </FormControl> 
    )}
    {(widgetTypes.find(w => w.value === type)?.featuredCard) && (
      <>
    <Divider textAlign="left" sx={{marginTop: 2}} >Featured Card Settings</Divider>
      <FormControl required fullWidth>
        <InputLabel id="featuredCardLabel">Featured Card</InputLabel>
        <Select
          value={featuredCard}
          labelId="featuredCardLabel"
          fullWidth
          label="Featured Card"
          onChange={(e) => handlFeaturedCardChange(e)}
        >
          <MenuItem value={"hide"} key={"hide"}>Hide Featured Card</MenuItem>
          <MenuItem value={"camera"} key={"camera"}>Show Camera Feed</MenuItem>
          <MenuItem value={"live_data"} key={"live_data"}>Show Live Data</MenuItem>
        </Select>
      </FormControl>
      </>
    )}
      {widgetTypes.find((w) => w.value === type)?.cameraFeed && featuredCard !== "hide" && (
        <>
          <FormControl required fullWidth>
           <InputLabel id="cameraFeedLabel">Device/Camera Feed</InputLabel>
            <Select
              value={cameraFeed}
              labelId="cameraFeedLabel"
              fullWidth
              displayEmpty
              label="Device/Camera Feed"
              onChange={(e) => handlCameraFeedChange(e)}
            >
              {devices.data.filter( d => d.type === "camera").map((d: any) => (
                <MenuItem value={d.id} key={d.name}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      {widgetTypes.find((w) => w.value === type)?.cameraFeedSubArea && !!subAreaOptions.length && (
              <FormControl fullWidth>
              <InputLabel id="cameraFeedSubArea">Device/Camera feed sub-area</InputLabel>
              <Select value={cameraFeedSubArea} fullWidth label="Device/Camera feed sub-area" onChange={(e) => setCameraFeedSubArea(e.target.value)} >
                {subAreaOptions.map((option: any) => (
                  <MenuItem value={option.name} key={option.name}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
      )}
      {widgetTypes.find((w) => w.value === type)?.cameraFeedMetric && !!cameraFeedMetricOptions.length && (
              <FormControl required fullWidth>
              <InputLabel id="cameraFeedMetric">Device/Camera feed metric</InputLabel>
              <Select value={cameraFeedMetric} fullWidth displayEmpty label="Device/Camera feed metric" onChange={(e) => setCameraFeedMetric(e.target.value)} >
                {cameraFeedMetricOptions.map((option: any) => (
                  <MenuItem value={option.type} key={option.type}>{option.type}</MenuItem>
                ))}
              </Select>
            </FormControl>
      )}
      {widgetTypes.find((w) => w.value === type)?.statusCardOptions && (
        <>
          <Divider sx={{marginBottom: 2}} />
            <FormControl required fullWidth>
              <InputLabel id="mainTileLabel">
                Status cards display options
              </InputLabel>
              <Select
                value={tiles}
                labelId="mainTileLabel"
                fullWidth
                multiple
                label="Status cards display options"
                onChange={(e) => handleTilesChanged(e)}
                renderValue={(selected) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {overviewWidgetOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </>
      )}
      {widgetTypes.find((w) => w.value === type)?.graphOptions?.length && (
        <FormControl required fullWidth>
          <InputLabel id="graphOptionLabel">Graph option</InputLabel>
          <Select
            value={graphOption}
            labelId="graphOptionLabel"
            fullWidth
            label="Graph option"
            onChange={(e) => setGraphOption(e.target.value)}
          >
            {widgetTypes
              .find((w) => w.value === type)
              ?.graphOptions?.map((graphOptionItem) => (
                <MenuItem value={graphOptionItem.value} key={graphOptionItem.value}>
                  {graphOptionItem.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {(widgetTypes.find(w => w.value === type)?.textarea || graphOption === "text_box") && (
        <TextEditor initialValue={slateInitialValue} onChange={onSlateChange} />
      )}
      {widgetTypes.find((w) => w.value === type)?.thresholdLimits && (
        <ParentItem>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button sx={{ mt: 2, mb: 2 }} size="small" onClick={(e: any) => setTemplatesMenuEl(e.currentTarget)} variant="contained" endIcon={<KeyboardArrowDownIcon />}>Templates</Button>
          <Menu
            anchorEl={templatesMenuEl}
            open={!!templatesMenuEl}
            onClose={() => setTemplatesMenuEl(null)}
          >
            {thresholdTemplates.map((t: any) => (
              <MenuItem key={t.name} onClick={() => fillFromTemplate(t)}>{t.name}</MenuItem>
            ))}
          </Menu>
          <Button sx={{ mt: 2, mb: 2 }} size="small" variant="contained" onClick={reverseColorOrder}>Reverse Thresholds</Button>
        </div>
        <SubItem>
          <p>Threshold Limit 1</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Value" label="Value" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value) }} />
            </FormControl>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value) }} />
            </FormControl>
            <ColorPreview sx={{ backgroundColor: limit1Color }} />
          </div>
        </SubItem>
        <SubItem>
          <p>Threshold Limit 2</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Value" label="Value" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value) }} />
            </FormControl>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value) }} />
            </FormControl>
            <ColorPreview sx={{ backgroundColor: limit2Color }} />
          </div>
        </SubItem>
        <SubItem>
          <p>Threshold Limit 3</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Value" label="Value" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value) }} />
            </FormControl>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value) }} />
            </FormControl>
            <ColorPreview sx={{ backgroundColor: limit3Color }} />
          </div>
        </SubItem>
        <SubItem>
          <p>Fallback</p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 125 }}>
              <TextField fullWidth placeholder="Color" label="Color" value={fallbackColor} onChange={(e) => { setFallbackColor(e.target.value) }} />
            </FormControl>
            <ColorPreview sx={{ backgroundColor: fallbackColor }} />
          </div>
        </SubItem>
      </ParentItem>
    )}
  </>
  );

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, type, dataSource, dataSources, comparison, featuredCard, cameraFeed, cameraFeedSubArea, cameraFeedMetric, tiles, graphOption, layout, textBox, limit1, limit1Color, limit2, limit2Color, limit3, limit3Color, fallbackColor });
    setName("");
    setType("");
    setDataSource("");
    setComparison(false);
    setFeaturedCard("hide");
    setCameraFeed("");
    setCameraFeedSubArea("");
    setSubAreaOptions([])
    setCameraFeedMetric("")
    setCameraFeedMetricOptions([])
    setTiles([]);
    setTextBox(null);
    setLayout("");
    setGraphOption("");
    setLimit1("");
    setLimit1Color("");
    setLimit2("");
    setLimit2Color("");
    setLimit3("");
    setLimit3Color("");
    setFallbackColor("");
  }

  return (
    <NewItemDialog title="Add Widget" text="Add a widget into this Use Case." FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
