import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

export default function CreateAreaDialog({ open, onSave, onClose }: ICreateProps) {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!open) {
      setName("");
      setDescription("");
    }
  }, [open])

  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <TextField label="Description" fullWidth value={description} onChange={(e) => setDescription(e.target.value)} />
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, description })
  }

  return (
    <NewItemDialog title="New Area" text="Create a new area within this Location" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
