import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Users } from "../../api";

export interface UserState {
  id: string;
  userId: string;
  email: string;
  isTPA: boolean;
  isTCA: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface UsersState {
  data: UserState[];
  status: 'idle' | 'loading' | 'failed';
  selected: string | undefined;
}

export interface GetUsers {
  tenantId: string;
}

const initialState: UsersState = {
  data: [],
  status: 'loading',
  selected: ''
}

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (tenantId: string) => {
    const response = await Users.getAll(tenantId);
    return response.data;
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: (state) => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.selected = initialState.selected;
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    updateUser: (state, action) => {
      state.data[action.payload.index] = {
        ...state.data[action.payload.index],
        ...action.payload.area
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { setSelected, resetUsers, updateUser } = usersSlice.actions;


export default usersSlice.reducer;