import { Box, Button, FormControl, Grid, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Devices as DevicesApi } from '../../api';
import { formulaOptions, formulaOptionsPerMetric } from '../../app/config/config'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDevices } from './devicesSlice';

interface IEditProps {
  device: any;
  locationState: any;
}
const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #c3c3c3'
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));

export default function DeviceProcessings({ device, locationState }: IEditProps) {

  const tenants = useAppSelector(s => s.tenants)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [processors, setProcessors] = useState<any[]>([]);
  const [tenantName, setTenantName] = useState("");

  const [name, setName] = useState("");
  const [serial_number, setSerialNumber] = useState("");
  const [type, setType] = useState("");
  const [heatmap, setHeatmap] = useState(false);
  const [apikey, setApiKey] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [settingsChanged, setSettingsChanged] = useState(false);
  const [error, setError] = useState('');

  const handleProcessesChange = (e: any, itemName: string) => {
    const { target: { value } } = e;
    const newProcessing = processors.map(p => {
      if (p.name === itemName) {
        return { ...p, formula: value };
      }
      if (p.type === itemName) {
        return { ...p, formula: value };
      }
      return p;
    })
    setProcessors([...newProcessing]);
  }

  const saveEdit = async () => {
    if (!tenants.selected) return
    const newMetrics = processors.filter(s => s.type).map(p => {
      return p
    })
    const newSubAreas = processors.filter(s => s.name).map(p => {
      return p
    })
    try {
      await DevicesApi.patch(
        tenants.selected,
        device.id,
        device["tenant_name"],
        name,
        serial_number,
        type,
        heatmap,
        newMetrics,
        device.model || "",
        device.location || "",
        device.area || "",
        device.associated_camera || "",
        device.latitude || "",
        device.longitude || "",
        device.isV2,
        apikey,
        disabled,
        newSubAreas,
      );
      setSettingsChanged(false);
      dispatch(getDevices(tenants.selected))
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
    navigate(`/${tenants.selected}/devices${locationState?.prevRoute?.search || ''}`);
  }

  useEffect(() => {
    setName(device.tenant_name);
    setName(device.name);
    setSerialNumber(device.serial_number);
    setType(device.type);
    setHeatmap(!!device.heatmap);
    setApiKey(device.apikey || "");
    setDisabled(!!device.disabled);
    setProcessors([...device.metrics, ...device.sub_areas]);
  }, [device])

  return (
    <Box component="form">
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">

        {processors.map(item => {
          const itemName = item.type || item.name;
          return (
            <Grid item key={itemName} xs={12}>
              <Item>
                <p>{itemName}</p>
                <FormControl style={{ margin: "1rem", minWidth: "250px" }}>
                  <Select value={item.formula} labelId={itemName} fullWidth onChange={(e) => { handleProcessesChange(e, itemName); setSettingsChanged(true) }}>
                    {!!formulaOptionsPerMetric[itemName] ?
                      formulaOptionsPerMetric[itemName].map((formula: string) => (
                        <MenuItem value={formula} key={formula}>{formula}</MenuItem>
                      ))
                      :
                      formulaOptions.map(formula => (
                        <MenuItem value={formula} key={formula}>{formula}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Item>
            </Grid>
          )
        })}

        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={!settingsChanged}
              onClick={(e) => setSettingsChanged(false)}
            >
              Discard changes
            </Button>

            <Button
              variant="contained"
              disabled={!settingsChanged}
              onClick={saveEdit}
            >
              Save settings
            </Button>
          </ButtonContainer>

        </Grid>
      </Grid >
    </Box >
  )
}
