import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tenantsReducer from '../tenantsSlice';
import locationsReducer from '../pages/tenant/locationsSlice';
import areasReducer from '../pages/location/areasSlice';
import devicesReducer from '../pages/devices/devicesSlice';
import settingsReducer from '../pages/settings/settingsSlice';
import usersReducer from '../pages/users/usersSlice';
import authReducer from '../pages/auth/authSlice';
import counterReducer from '../pages/counter/counterSlice';

export const store = configureStore({
  reducer: {
    tenants: tenantsReducer,
    locations: locationsReducer,
    areas: areasReducer,
    devices: devicesReducer,
    settings: settingsReducer,
    users: usersReducer,
    auth: authReducer,
    counter: counterReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
