import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Button, CircularProgress, Icon, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CustomTable from '../../components/table/Table';
import Tabs from '../../components/tabs/Tabs';
import { Usecases as UsecasesApi, Widgets as WidgetsApi, Datagroups as DatagroupsApi } from '../../api';

import AddWidgetDialog from './AddWidgetDialog';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import UsecaseSettings from './UsecaseSettings';
import EditWidgetDialog from './EditWidgetDialog';
import EditOverviewWidgetDialog from './EditOverviewWidgetDialog';
import { useAppSelector } from '../../app/hooks';

export default function Usecase() {

  const { usecase: usecaseSlug } = useParams();
  const { tenant: tenantSlug } = useParams();
  const areas = useAppSelector((s) => s.areas);
  const locations = useAppSelector((s) => s.locations);


  const [usecase, setUsecase] = useState({
    id: "",
    linked_id: "",
    display_type: "",
    name: "",
    sub_title: "",
    camera_feed: "",
    camera_stream_url: "",
    data_source: "",
    main_tile_options: [],
    sub_tile_options: [],
    coompare_to: "",
    display_last_updated: false,
    display_notifications: false,
    tags: [],
    disabled: false,
    settings: [],
    createdAt: 0,
    updatedAt: 0
  });
  const [datagroups, setDatagroups] = useState<any[]>([]);
  const [widgets, setWidgets] = useState<any[]>([]);
  const [addDialog, setAddDialog] = useState(false)
  const [editDialogWidget, setEditDialogWidget] = useState(false)
  const [editOverviewDialogWidget, setEditOverviewDialogWidget] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});
  const [displayPositionsExist, setDisplayPositionsExist] = useState(true);
  const [widgetsLoading, setWidgetsLoading] = useState(false);

  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState('');


  const getUsecase = async (tenantId: any, usecaseId: any) => {

    try {
      const usecaseResponse = await UsecasesApi.get(tenantId, usecaseId);

      if (!usecaseResponse.data) {
        setNotFound(true);
        return;
      }
      setUsecase(usecaseResponse.data);

    } catch (error) {
      console.log(error);
      setNotFound(true);
    }
  };

  const saveCreate = async (data: any) => {
    const { name, type, dataSource, dataSources, comparison, featuredCard, cameraFeed, cameraFeedSubArea, cameraFeedMetric, tiles, layout, graphOption, textBox, limit1, limit1Color, limit2, limit2Color, limit3, limit3Color, fallbackColor } = data;

    const newDisplayPosition = Number(widgets[widgets.length - 1]?.Position) ? +widgets[widgets.length - 1]?.Position + 1 : widgets.length;

    try {
      await WidgetsApi.create( tenantSlug, String(newDisplayPosition), name, type, dataSource, dataSources, comparison, featuredCard, cameraFeed, cameraFeedSubArea, cameraFeedMetric, tiles, layout, graphOption, textBox, limit1, limit1Color, limit2, limit2Color, limit3, limit3Color, fallbackColor, usecaseSlug );
      await getUsecase(tenantSlug, usecaseSlug);
      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveEditWidget = async (data: any) => {

    try {
      await WidgetsApi.patch(
        tenantSlug,
        selected.key,
        data.display_position,
        data.name,
        data.type,
        data.dataSource,
        data.dataSources,
        data.comparison,
        data.featuredCard,
        data.cameraFeed,
        data.cameraFeedSubArea,
        data.cameraFeedMetric,
        data.tiles,
        data.layout,
        data.graphOption,
        data.textBox,
        data.limit1,
        data.limit1Color,
        data.limit2,
        data.limit2Color,
        data.limit3,
        data.limit3Color,
        data.fallbackColor,
      );
      getUsecase(tenantSlug, usecaseSlug);
      setEditDialogWidget(false);
      setEditOverviewDialogWidget(false)
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }
  const openAndSelectEditWidget = (id: string) => {
    const widget = widgets.find((w: any) => w.key === id);
    if (!widget) return;

    setSelected(widget);

    if (widget.Type === 'default') {
      return setEditOverviewDialogWidget(true);
    }

    setEditDialogWidget(true);
  }
  const saveDelete = async () => {
    try {
      await WidgetsApi.delete(tenantSlug, selected.key)
      getUsecase(tenantSlug, usecaseSlug);
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const widget = widgets.find((w) => w.key === id);
    if (!widget) return;
    setSelected(widget);
    setDeleteDialog(true);
  }

  const getDatagroups = async (tenant_id: any, id: any) => {
    try {
      const currentArea = areas.data.find(a => a.id === id);
      const areaIds = areas.data.map(a => a.id);
      const currentLocation = locations.data.find(a => a.id === id);

      const AllDatagroupsResponse = await DatagroupsApi.getAll(tenant_id);
      const currentDatagroups = AllDatagroupsResponse.data.filter((dg: any) => dg.linked_id === id).sort((a: any, b: any) => a.name.localeCompare(b.name));

      if (currentArea) {
        const locationDatagroups = AllDatagroupsResponse.data.filter((dg: any) => dg.linked_id === currentArea.location_id ).map((dg: any) => {
          const location = locations.data.find(a => a.id === dg.linked_id);
          return {...dg, name: `[${location?.name}]: ${dg.name}`}
        }).sort((a: any, b: any) => a.name.localeCompare(b.name));
  
        return setDatagroups(
          [...currentDatagroups, ...locationDatagroups]
        );
      }

      if (currentLocation) {
        const areaDatagroups = AllDatagroupsResponse.data.filter((dg: any) => areaIds.includes(dg.linked_id)).map((dg: any) => {
          const area = areas.data.find(a => a.id === dg.linked_id);
          return {...dg, name: `[${area?.name}]: ${dg.name}`}
        }).sort((a: any, b: any) => a.name.localeCompare(b.name));
  
        return setDatagroups(
          [...currentDatagroups, ...areaDatagroups]
        );
      }

      setDatagroups(currentDatagroups)

    } catch (error) {
      console.log(error);
    }
  };

  const getWidgets = async (tenantId: any, usecaseId: any, datasources: any) => {
    setWidgetsLoading(true);
    const usecaseWidgets = await WidgetsApi.getByUsecaseId(tenantId, usecaseId);

    const widgetsResults = usecaseWidgets.data.sort((a: any, b: any) => {
      if (!b.display_position) return -1;
      return a.display_position - b.display_position;
    }).map((d: any) => {
      const dataSource: any = datasources.find((dg: any) => dg?.id === d.data_source);
      return {
        Name: `${d.name}`,
        Type: d.type,
          "Data Source":
            d.type === "text_box" ? "Custom Text" : dataSource?.name || "",
          datasource_id: d.data_source,
          dataSources: d.data_sources,
          comparison: d.comparison,
          featuredCard: d.featured_card,
          cameraFeed: d.camera_feed,
          cameraFeedSubArea: d.camera_feed_sub_area,
          cameraFeedMetric: d.camera_feed_metric,
          textBox: d.text_box,
          layout: d.layout,
          graphOption: d.graph_option,
          limit1: d.limit_1,
          limit1Color: d.limit_1_color,
          limit2: d.limit_2,
          limit2Color: d.limit_2_color,
          limit3: d.limit_3,
          limit3Color: d.limit_3_color,
          fallbackColor: d.limit_fallback_color,
          tiles: d.tiles,
          // hideDelete: d.type === "default",
          usecase_id: d.usecase_id,
          Position: d.display_position,
          key: d.id,
        }
      })
    const allHavePosition = widgetsResults.every((w: any) => !!w.Position);
    setDisplayPositionsExist(allHavePosition);
    setWidgets(widgetsResults);
    setWidgetsLoading(false);
  }

  const saveNewPositions = async (tenant_id: any, id: string, newPosition: any) => {
    try {
      return await WidgetsApi.patch(
        tenant_id,
        id,
        newPosition,
      );
      // await getUsecases(tenantSlug, id)
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  const reorderDisplayPositions = (newOrder: any) => {
    setWidgetsLoading(true);
    const order = newOrder.map((newOrderItem: any, index: any) => {
      //   // return { tenant_id: tenantSlug, id: newOrderItem.key, newPosition: String(index) };
      return saveNewPositions(tenantSlug, newOrderItem.key, String(index));
    });


    Promise.all(order).then(a => getWidgets(tenantSlug, usecaseSlug, datagroups)).catch(err => { console.log(err); setWidgetsLoading(false); });
  }

  useEffect(() => {
    getWidgets(tenantSlug, usecaseSlug, datagroups).catch(error => {
      setWidgetsLoading(false);
      console.log(error);
    })
  }, [usecase, tenantSlug, usecaseSlug, datagroups])

  useEffect(() => {
    const setup = async () => {
      await getUsecase(tenantSlug, usecaseSlug);
    };
    setup();
  }, [tenantSlug, usecaseSlug]);

  useEffect(() => {
    getDatagroups(tenantSlug, usecase.linked_id);
  }, [setDatagroups, usecase.linked_id, tenantSlug]);

  const refreshForm = () => {
    getUsecase(tenantSlug, usecaseSlug);
  }
  const headers = ['Name', 'Type', 'Data Source'];

  const tabs = [
    {
      label: `Widgets (${widgets.length})`,
      Component: (
        <div>
          <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
            {!displayPositionsExist && (<Button size="medium" disabled={widgetsLoading} variant='contained' color='primary' startIcon={widgetsLoading ? <Icon /> : <FormatListNumberedIcon />} onClick={() => reorderDisplayPositions(widgets)}>Set Positions</Button>)}
            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}>Add Widget</Button>
          </Toolbar >
          <CustomTable
            name="Devices"
            headers={headers}
            data={widgets}
            viewButton={false}
            editButton={true}
            editItem={(id: string) => openAndSelectEditWidget(id)}
            deleteButton={true}
            deleteItem={(id: string) => openAndSelectDelete(id)}
            editDisplayPosition={(id: string) => reorderDisplayPositions(id)}
          />
        </div>
      )
    },
    {
      label: "Settings",
      Component: (
        <UsecaseSettings usecase={usecase} datagroups={datagroups} refreshForm={() => refreshForm()} />
      )
    }
  ];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>{usecase.name}</Typography>
      </Toolbar>
      {/* <Chip label="Data Group" sx={{ backgroundColor: 'error.main', color: '#ffffff' }} /> */}
      <Tabs tabs={tabs} />
      <EditWidgetDialog open={editDialogWidget} datagroups={datagroups} onClose={() => setEditDialogWidget(false)} onSave={(data: any) => saveEditWidget(data)} selected={selected} />
      <EditOverviewWidgetDialog usecase={usecase} open={editOverviewDialogWidget} datagroups={datagroups} onClose={() => setEditOverviewDialogWidget(false)} onSave={(data: any) => saveEditWidget(data)} selected={selected} />
      <DeleteItemDialog title={`Remove the widget ${selected?.Name || ''} from ${usecase.name}`} text={`Are you sure you want to delete this widget from this use case? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
      <AddWidgetDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={saveCreate} datagroups={datagroups} />
    </div>
  )
}
