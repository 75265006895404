import { FormatBold, FormatItalic, FormatUnderlined, FormatListBulleted, FormatListNumbered, FormatAlignLeft, FormatAlignCenter, FormatAlignRight } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import React from 'react'
import { Editor, Element, BaseRange, BaseText, Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

const Toolbar = styled(Box)(() => ({
  borderBottom: '2px solid #ddd',
  marginBottom: '1em'
}))

const Separator = styled('div')(() => ({
  "&::after": {
    content: "' '",
    padding: '0 0.5em',
    color: '#aaa'
  },
  display: 'inline-flex'
}))

// export type BoldElement = {
//   type: string
//   children: {text: string; bold?: true}[]
// }

// declare module 'slate' {
//   interface CustomTypes {
//     Editor: ReactEditor
//     Element: BoldElement
//     Text: BaseText & {
//       placeholder?: string
//     }
//     Range: BaseRange & {
//       placeholder?: string
//     }
//   }
// }

const MarkButton = ({children, format}: any) => {
  const slate = useSlate();

  const isMarkActive = (currentEditor: any, format: any) => {
    const marks: any = Editor.marks(currentEditor)
    return marks ? marks[format] === true : false
  }

  const toggleMark = (currentEditor: any, format: any) => {
    const isActive = isMarkActive(currentEditor, format)
  
    if (isActive) {
      Editor.removeMark(currentEditor, format)
    } else {
      Editor.addMark(currentEditor, format, true)
    }
  }

  return (
    <IconButton color={isMarkActive(slate, format) ? `primary` : `default`} onMouseDown={(e) => {e.preventDefault(); toggleMark(slate, format)}}>
      {children}
    </IconButton>
  )
}

const BlockButton = ({children, format}: any) => {
  const slate = useSlate();

  const LIST_TYPES = ['numbered-list', 'bulleted-list']
  const TEXT_ALIGN_TYPES = ['left', 'center', 'right']

  const isBlockActive = (editor: any, format: any, blockType: string = 'type') => {
    const { selection } = editor
    if (!selection) return false
  
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (n as any)[blockType] === format,
      })
    )
  
    return !!match
  }

  const toggleBlock = (editor: any, format: any) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
  
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })

    let newProperties: Partial<Element>
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      }
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      }
    }
    Transforms.setNodes<Element>(editor, newProperties)
  
    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }

  return (
    <IconButton color={isBlockActive(slate, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type') ? `primary` : `default`} onMouseDown={(e) => {e.preventDefault(); toggleBlock(slate, format)}}>
    {children}
  </IconButton>
    )
}

export default function SlateToolbar() {
  return (
  <Toolbar>
    <MarkButton format="bold">
      <FormatBold />
    </MarkButton>
    <MarkButton format="italic">
      <FormatItalic />
    </MarkButton>
    <MarkButton format="underline">
      <FormatUnderlined />
    </MarkButton>
    <Separator />
    <BlockButton format="bulleted-list">
      <FormatListBulleted />
    </BlockButton>
    <BlockButton format="numbered-list">
      <FormatListNumbered />
    </BlockButton>
    <Separator />
    <BlockButton format="left">
      <FormatAlignLeft />
    </BlockButton>
    <BlockButton format="center">
      <FormatAlignCenter />
    </BlockButton>
    <BlockButton format="right">
      <FormatAlignRight />
    </BlockButton>
  </Toolbar>
  )
}
