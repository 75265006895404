import React, { useEffect } from 'react'
import { Box, Button, styled } from '@mui/material';
import { AUTH_URL } from '../../api';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '66vh',
  textAlign: 'center',
  gap: '20px'
});

export default function Callback() {
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const client_url = process.env.REACT_APP_CLIENT_URL;


  return (
    <Container>
      <p>
        Something has gone wrong authenticating your details, please try logging in again.
        <br />
        If this keep happening please contact support.
      </p>

      <Button
        variant="contained"
        href={`${AUTH_URL}/oauth/authorize?grant_type=authorization_code&response_type=code&client_id=${client_id}&redirect_uri=${client_url}/oauth/callback&state=myState&scope=auth tenantsinfo userinfo`}
      >
        Go to log in
      </Button>
    </Container >
  )
}
