import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

export default function CreateLocationDialog({ open, onSave, onClose }: ICreateProps) {

  const [name, setName] = useState("");
  const [kafka_alert_alias, setKafkaAlertAlias] = useState("")
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    if (!open) {
      setName("");
      setKafkaAlertAlias("");
      setLatitude("");
      setLongitude("");
    }
  }, [open])

  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <TextField label="Kafka alert alias" helperText="Only letters are allowed" required fullWidth value={kafka_alert_alias} inputProps={{ pattern: "[A-Za-z]*" }} onChange={(e) => setKafkaAlertAlias(e.target.value)} />
    <TextField label="Latitude" fullWidth value={latitude} onChange={(e) => setLatitude(e.target.value)} />
    <TextField label="Longitude" fullWidth value={longitude} onChange={(e) => setLongitude(e.target.value)} />
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, kafka_alert_alias, latitude, longitude })
  }

  return (
    <NewItemDialog title="New Location" text="Create a new Location within this Tenant" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
