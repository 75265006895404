import { Toolbar, Typography } from '@mui/material'
import React from 'react'
import Tabs from '../../components/tabs/Tabs';
import ThresholdTemplates from './ThresholdTemplates';
import ZeroingMetrics from './ZeroingMetrics';

export default function Settings() {

  const tabs = [
    {
      label: `Zero Metrics`,
      Component: (
        <ZeroingMetrics />
      )
    },
    {
      label: `Threshold Templates`,
      Component: (
        <ThresholdTemplates />
      )
    }
  ];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>Settings</Typography>
        {/* <Button variant='contained' color='primary' startIcon={<EditIcon />} onClick={() => setEditDialog(true)}> Edit</Button> */}
      </Toolbar>
      <Tabs tabs={tabs} />
    </div>
  )
}



