import { Box, Button, Chip, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, Slider, styled, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../new-item-dialog/NewItemDialog'
import { tagOptions } from '../../app/config/config'
import { usecaseDisplayTypes } from '../../app/config/usecases/display-types'
import { mainTileDataOptions, subTileDataOptions, compareToOptions, graphIntervalOptions, timeRangeSliderMarks, timeRangeDisplayValue, timeRangeMinRange, timeIntervalOptions } from '../../app/config/usecases/config'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useAppSelector } from '../../app/hooks';

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  datagroups: any[];
  endPosition: String;
}

const ParentItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '1px solid #c3c3c3'
}));

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '3px',
  border: '1px solid #ddd'
}));

export default function CreateUsecaseDialog({ open, onSave, onClose, datagroups, endPosition }: ICreateProps) {

  const [displayType, setDisplayType] = useState("");
  const [displayTypeOptions, setDisplayTypeOptions] = useState<any>({});
  const [name, setName] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [cameraFeed, setCameraFeed] = useState("");
  const [cameraFeeds, setCameraFeeds] = useState([]);
  const [cameraFeedSubArea, setCameraFeedSubArea] = useState("");
  const [subAreaOptions, setSubAreaOptions] = useState<any>([]);
  const [cameraStreamUrl, setCameraStreamUrl] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [timeRange, setTimeRange] = useState<number[]>([0, 1439]);
  const [showTimeRange, setShowTimeRange] = useState<boolean>(false);
  const [graphInterval, setGraphInterval] = useState("");
  const [compareTo, setCompareTo] = useState("");
  const [mainTileOptions, setMainTileOptions] = useState([]);
  const [subTileOptions, setSubTileOptions] = useState([]);
  const [displayLastUpdated, setDisplayLastUpdated] = useState(false);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [timeIntervals, setTimeIntervals] = useState(["1minute", "15minutes", "1hour", "1day"]);
  const [tags, setTags] = useState(["operations", "safety"]);
  const [disabled, setDisabled] = useState(false);
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("#28a745");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("#ffc107");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("#dc3545");
  const [fallbackColor, setFallbackColor] = useState("#dc3595");
  const [secondaryLimit1, setsecondaryLimit1] = useState("");
  const [secondaryLimit2, setsecondaryLimit2] = useState("");
  const [secondaryLimit3, setsecondaryLimit3] = useState("");
  const [templatesMenuEl, setTemplatesMenuEl] = useState(null)

  const [thresholdTemplates, setThresholdTemplates] = useState<any[]>([]);

  const devices = useAppSelector((s) => s.devices);
  const settings = useAppSelector((s) => s.settings);

  useEffect(() => {
    const thresholds = settings.data.filter((s) => s.type === "thresholdtemplates").map((t: any) => ({
      name: t.key,
      ...t.value
    }))

    setThresholdTemplates(thresholds);
  
    return () => {
      setThresholdTemplates([]);
    }
  }, [settings.data])
  

  const handleDataTypeChange = (event: any) => {

    const { target: { value } } = event;
    const newDisplayTypeOptions = usecaseDisplayTypes.find(type => type.name === value);
    if (!newDisplayTypeOptions) return
    const { main_tile_data_slots, sub_tile_data_slots, sub_title, camera_feed, camera_feeds, camera_stream_url, unit_of_measurement, data_source, data_sources, time_range, graph, compare_to, display_last_updated, display_notifications, secondary_thresholds } = newDisplayTypeOptions;
    const updateMainTileOptions = mainTileOptions.slice(0, main_tile_data_slots)
    const updateSubTileOptions = subTileOptions.slice(0, sub_tile_data_slots)

    setDisplayTypeOptions(newDisplayTypeOptions)
    setDisplayType(value)
    setMainTileOptions(updateMainTileOptions)
    setSubTileOptions(updateSubTileOptions)

    if (!sub_title) { setSubTitle(""); }
    if (!camera_feed) { setCameraFeed(""); setCameraFeedSubArea(""); setSubAreaOptions([]) }
    if (!camera_feeds) { setCameraFeeds([]) }
    if (!camera_stream_url) { setCameraStreamUrl("") }
    if (!unit_of_measurement) { setUnitOfMeasurement(""); }
    if (!time_range) { setTimeRange([0, 1439]) }
    if (!sub_tile_data_slots) { setSubTileOptions([]); }
    if (!graph) { setGraphInterval(""); }
    if (!data_source) { setDataSource(""); }
    if (!data_sources) { setDataSources([]); }
    if (!compare_to) { setCompareTo(""); }
    if (!display_last_updated) { setDisplayLastUpdated(false); }
    if (!display_notifications) { setDisplayNotifications(false); }
    if (!secondary_thresholds) { setsecondaryLimit1(""); setsecondaryLimit2(""); setsecondaryLimit3("") }
  }

  const handlCameraFeedChange = (event: any) => {
    const { target: { value } } = event;
    const selectedDevice = devices.data.find(d => d.id === value)

    setSubAreaOptions(selectedDevice?.sub_areas)
    setCameraFeed(value)
    setCameraFeedSubArea("")
  }

  const handlCameraFeedsChange = (event: any) => {
    const { target: { value } } = event;
    console.log(value);
    setCameraFeeds(value);
    // const selectedDevice = devices.data.find(d => d.id === value)

    // setSubAreaOptions(selectedDevice?.sub_areas)
    // setCameraFeed(value)
    // setCameraFeedSubArea("")
  }

  const handleDataeSourcesChange = (event: any) => {
    const { target: { value } } = event;
    console.log(value);
    setDataSources(value);
  }

  const handleTimeRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setTimeRange([Math.min(newValue[0], timeRange[1] - timeRangeMinRange), timeRange[1]]);
    } else {
      setTimeRange([timeRange[0], Math.max(newValue[1], timeRange[0] + timeRangeMinRange)]);
    }
  };

  const handleMainTileOptionsChange = (event: any) => {
    const { target: { value } } = event;
    if (value.length > displayTypeOptions?.main_tile_data_slots) return;

    setMainTileOptions(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSubTileOptionsChange = (event: any) => {
    const { target: { value } } = event;
    if (value.length > displayTypeOptions?.sub_tile_data_slots) return;

    setSubTileOptions(typeof value === 'string' ? value.split(',') : value)
  }

  const handleTimeIntervalsChange = (event: any) => {
    const { target: { value } } = event;

    const order = timeIntervalOptions.map(i => i.value);
    const values = typeof value === 'string' ? value.split(',') : value;
    const newTimeIntervalsValue = [...values].sort((a, b) => order.indexOf(a) - order.indexOf(b));

    setTimeIntervals(newTimeIntervalsValue);
  }

  const handleTagsChange = (event: any) => {
    const { target: { value } } = event;

    setTags(typeof value === 'string' ? value.split(',') : value)
  }

  const reverseColorOrder = () => {
    const col1 = limit1Color;
    const col3 = limit3Color;

    setLimit1Color(col3);
    setLimit3Color(col1);
  }

  const fillFromTemplate = (template: any) => {
    setLimit1Color(template.limit1Color);
    setLimit1(template.limit1);
    setLimit2Color(template.limit2Color);
    setLimit2(template.limit2);
    setLimit3Color(template.limit3Color);
    setLimit3(template.limit3);
    setFallbackColor(template.fallbackColor);
    setTemplatesMenuEl(null)
  }

  useEffect(() => {
    if (!open) {
      const usecaseDisplayTypeOptions = usecaseDisplayTypes.find(type => type.name === displayType);
      const deviceSubAreas = devices.data.find(d => d.id === cameraFeed)?.sub_areas
      setSubAreaOptions(deviceSubAreas || []);
      setDisplayTypeOptions(usecaseDisplayTypeOptions)
      setDisplayType("");
      setName("");
      setSubTitle("");
      setCameraFeed("");
      setCameraFeeds([]);
      setCameraFeedSubArea("");
      setCameraStreamUrl("");
      setDataSource("");
      setDataSources([]);
      setUnitOfMeasurement("");
      setTimeRange([0, 1439]);
      setMainTileOptions([]);
      setSubTileOptions([]);
      setGraphInterval("");
      setCompareTo("");
      setDisplayLastUpdated(false);
      setDisplayNotifications(false);
      setTimeIntervals(["1minute", "15minutes", "1hour", "1day"]);
      setTags(["operations", "safety"]);
      setDisabled(false);
      setLimit1("");
      setLimit1Color("#28a745");
      setLimit2("");
      setLimit2Color("#ffc107");
      setLimit3("");
      setLimit3Color("#dc3545");
      setFallbackColor("#dc3595");
      setsecondaryLimit1("");
      setsecondaryLimit2("");
      setsecondaryLimit3("");
    }
  }, [open, devices, displayType, cameraFeed])

  useEffect(() => {
    if (showTimeRange) {
      setTimeRange((prev) => {
        if (Array.isArray(prev) && prev[0] === 0 && prev[1] === 1439) {
          return [60, 480];
        }

        return prev;
      })
    }

    if (!showTimeRange) {
      setTimeRange((prev) => {
        if (Array.isArray(prev) && prev[0] === 0 && prev[1] === 1439) {
          return prev;
        }
        return [0, 1439];
      })
    }


  }, [showTimeRange])

  const formElements = <>

    <FormControl required fullWidth>
      <InputLabel id="displayTypeLabel">Display Type</InputLabel>
      <Select value={displayType} labelId="displayTypeLabel" fullWidth label="displayType" onChange={(e) => handleDataTypeChange(e)} >
        {usecaseDisplayTypes.map((type: any) => (
          <MenuItem value={type.name} key={type.name}>{type.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    {!!displayTypeOptions?.sub_title && (
      <FormControl fullWidth>
        <TextField label="Sub Title" fullWidth placeholder="Usecase Sub Title" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
      </FormControl>
    )}
    {!!displayTypeOptions?.camera_feed && (
      <FormControl required fullWidth>
        <InputLabel id="cameraFeedLabel">Camera Feed</InputLabel>
        <Select value={cameraFeed} labelId="cameraFeedLabel" fullWidth label="Camera Feed" onChange={(e) => handlCameraFeedChange(e)} >
          {devices.data.map((device: any) => (
            <MenuItem value={device.id} key={device.id}>{device.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.camera_feeds && (
      <FormControl required fullWidth>
        <InputLabel id="cameraFeedsLabel">Camera Feeds</InputLabel>
        <Select multiple value={cameraFeeds} labelId="cameraFeedsLabel" fullWidth label="Camera Feeds" onChange={(e) => handlCameraFeedsChange(e)} >
          {devices.data.filter(c => c.type === "camera").map((device: any) => (
            <MenuItem value={device.id} key={device.id}>{device.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!subAreaOptions.length && (
      <FormControl fullWidth>
        <InputLabel id="cameraFeedLabel">Camera Feed Sub Area</InputLabel>
        <Select value={cameraFeedSubArea} fullWidth label="Camera Feed Sub Area" onChange={(e) => setCameraFeedSubArea(e.target.value)} >
          {subAreaOptions.map((option: any) => (
            <MenuItem value={option.name} key={option.name}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.camera_stream_url && (
      <FormControl fullWidth>
        <TextField label="Camera Stream URL" fullWidth placeholder="Camera Stream URL" value={cameraStreamUrl} onChange={(e) => setCameraStreamUrl(e.target.value)} />
      </FormControl>
    )}
    {!!displayTypeOptions?.data_source && (
      <FormControl required fullWidth>
        <InputLabel id="dataSourceLabel">Data Source</InputLabel>
        <Select value={dataSource} fullWidth label="Data Source" onChange={(e) => setDataSource(e.target.value)} >
          {datagroups.map((datagroup: any) => (
            <MenuItem value={datagroup.id} key={datagroup.name}>{datagroup.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.data_sources && (
      <FormControl required fullWidth>
        <InputLabel id="dataSourceLabel">Data Sources</InputLabel>
        <Select multiple value={dataSources} fullWidth label="Data Sources" onChange={(e) => handleDataeSourcesChange(e)} >
          {datagroups.map((datagroup: any) => (
            <MenuItem value={datagroup.id} key={datagroup.name}>{datagroup.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.unit_of_measurement && (
      <FormControl fullWidth>
        <TextField fullWidth required placeholder="Unit of Measurement" label="Unit of Measurement" value={unitOfMeasurement} onChange={(e) => setUnitOfMeasurement(e.target.value)} />
      </FormControl>
    )}
    {!!displayTypeOptions?.time_range && (
      <>
        <p>Time Range <Switch checked={showTimeRange} onChange={(e) => { setShowTimeRange(e.target.checked); }} /></p>
        {showTimeRange && (
          <FormControl sx={{ ml: 4, mr: 4 }}>
            <Slider
              getAriaLabel={() => 'Time Range'}
              value={timeRange}
              onChange={handleTimeRangeChange}
              valueLabelDisplay="auto"
              getAriaValueText={timeRangeDisplayValue}
              valueLabelFormat={timeRangeDisplayValue}
              step={15}
              min={60}
              max={480}
              disableSwap
              marks={timeRangeSliderMarks}
            />
          </FormControl>
        )}
      </>
    )}
    {!!displayTypeOptions?.main_tile_data_slots && (
      <FormControl required fullWidth>
        <InputLabel id="mainTileLabel">Main tile (Select {displayTypeOptions?.main_tile_data_slots})</InputLabel>
        <Select value={mainTileOptions} labelId="mainTileLabel" fullWidth multiple label={`Main tile (Select ${displayTypeOptions?.main_tile_data_slots})`} onChange={(e) => handleMainTileOptionsChange(e)}
          renderValue={(selected) => (
            // eslint-disable-next-line react/jsx-no-undef
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}>
          {mainTileDataOptions.map(option => (
            <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.sub_tile_data_slots && (
      <FormControl required fullWidth>
        <InputLabel id="subTileLabel">Sub tile (Select {displayTypeOptions?.sub_tile_data_slots})</InputLabel>
        <Select value={subTileOptions} labelId="subTileLabel" fullWidth multiple label={`Sub tile (Select ${displayTypeOptions?.sub_tile_data_slots})`} onChange={(e) => handleSubTileOptionsChange(e)}
          renderValue={(selected) => (
            // eslint-disable-next-line react/jsx-no-undef
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}>
          {subTileDataOptions.map(option => (
            <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.graph && (
      <FormControl required fullWidth>
        <InputLabel id="graphIntervalLabel">Graph interval</InputLabel>
        <Select value={graphInterval} fullWidth label="Graph interval" onChange={(e) => setGraphInterval(e.target.value)} >
          {graphIntervalOptions.map((option: any) => (
            <MenuItem value={option.value} key={option.name}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.compare_to && (
      <FormControl required fullWidth>
        <InputLabel id="compareToLabel">Compare to</InputLabel>
        <Select value={compareTo} fullWidth label="Compare to" onChange={(e) => setCompareTo(e.target.value)} >
          {compareToOptions.map((option: any) => (
            <MenuItem value={option.value} key={option.name}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {!!displayTypeOptions?.display_last_updated && (
      <FormControlLabel control={<Switch checked={displayLastUpdated} onChange={(e) => setDisplayLastUpdated(e.target.checked)} />} label="Display last updated" />
    )}

    {!!displayTypeOptions?.display_notifications && (
      <FormControlLabel control={<Switch checked={displayNotifications} onChange={(e) => setDisplayNotifications(e.target.checked)} />} label="Display notifications" />
    )}
    <FormControl fullWidth>
      <InputLabel id="timeIntervalsLabel">Time Intervals</InputLabel>
      <Select value={timeIntervals} labelId="timeIntervalsLabel" fullWidth multiple label="Time Intervals" onChange={handleTimeIntervalsChange}
        renderValue={(selected) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}>
        {timeIntervalOptions.map(option => (
          <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl fullWidth>
      <InputLabel id="tagsLabel">Tags</InputLabel>
      <Select value={tags} labelId="tagsLabel" fullWidth multiple label="Tags" onChange={handleTagsChange}
        renderValue={(selected) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}>
        {tagOptions.map(tag => (
          <MenuItem value={tag} key={tag}>{tag}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <ParentItem>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button sx={{ mt: 2, mb: 2 }} size="small" onClick={(e: any) => setTemplatesMenuEl(e.currentTarget)} variant="contained" endIcon={<KeyboardArrowDownIcon />}>Templates</Button>
        <Menu
          anchorEl={templatesMenuEl}
          open={!!templatesMenuEl}
          onClose={() => setTemplatesMenuEl(null)}
        >
          {thresholdTemplates.map((t: any) => (
            <MenuItem key={t.name} onClick={() => fillFromTemplate(t)}>{t.name}</MenuItem>
          ))}
        </Menu>
        <Button sx={{ mt: 2, mb: 2 }} size="small" variant="contained" onClick={reverseColorOrder}>Reverse Thresholds</Button>
      </div>
      {!!displayTypeOptions?.secondary_thresholds && (
        <>
          <SubItem>
            <p>Threshold Limit 1</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Tilt" label="Tilt" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Vibration" label="Vibration" value={secondaryLimit1} type="number" onChange={(e) => { setsecondaryLimit1(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit1Color }} />
            </div>
          </SubItem>
          <SubItem>
            <p>Threshold Limit 2</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Tilt" label="Tilt" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Vibration" label="Vibration" value={secondaryLimit2} type="number" onChange={(e) => { setsecondaryLimit2(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit2Color }} />
            </div>
          </SubItem>
          <SubItem>
            <p>Threshold Limit 3</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Tilt" label="Tilt" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Vibration" label="Vibration" value={secondaryLimit3} type="number" onChange={(e) => { setsecondaryLimit3(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit3Color }} />
            </div>
          </SubItem>      
        </>
      )}
      {!displayTypeOptions?.secondary_thresholds && (
        <>
          <SubItem>
            <p>Threshold Limit 1</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Value" label="Value" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit1Color }} />
            </div>
          </SubItem>
          <SubItem>
            <p>Threshold Limit 2</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Value" label="Value" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit2Color }} />
            </div>
          </SubItem>
          <SubItem>
            <p>Threshold Limit 3</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Value" label="Value" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit3Color }} />
            </div>
          </SubItem>      
        </>
      )}
      <SubItem>
        <p>Fallback</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Color" label="Color" value={fallbackColor} onChange={(e) => { setFallbackColor(e.target.value) }} />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: fallbackColor }} />
        </div>
      </SubItem>
    </ParentItem>
    <FormControlLabel control={<Switch checked={disabled} onChange={(e) => setDisabled(e.target.checked)} />} label="Disable usecase" />
  </>;

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({
      displayType,
      name,
      subTitle,
      cameraFeed,
      cameraFeeds,
      cameraFeedSubArea,
      cameraStreamUrl,
      dataSource,
      dataSources,
      unitOfMeasurement,
      timeRange,
      mainTileOptions,
      subTileOptions,
      graphInterval,
      compareTo,
      displayLastUpdated,
      displayNotifications,
      timeIntervals,
      tags,
      disabled,
      endPosition,
      limit1,
      limit1Color,
      limit2,
      limit2Color,
      limit3,
      secondaryLimit1,
      secondaryLimit2,
      secondaryLimit3,
      limit3Color,
      fallbackColor
    })
  }

  return (
    <NewItemDialog title="New Use Case" text="Create a new Use Case to display in this section" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
