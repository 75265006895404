import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const Content = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  paddingTop: "0px",
}));

export default function ConfirmDialog(props: any) {
  const { title, text, open, onClose, FormElements, onConfirm } = props;

  const [loading, setLoading] = useState(false);
  const handleOnSave = async (e: any) => {
    e.preventDefault(e);
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleOnSave}>
        <Content>
          <DialogContentText>{text}</DialogContentText>
          {FormElements}
        </Content>
        <DialogActions>
          <LoadingButton
            variant="text"
            onClick={onClose}
            disabled={loading}
            loading={false}
          >
            {/* There is a known issue with the login button you need to put the text inside an html element to avoid */}
            <span>Cancel</span>
          </LoadingButton>
          <LoadingButton variant="text" type="submit" loading={loading}>
            {/* There is a known issue with the login button you need to put the text inside an html element to avoid */}
            <span>Confirm</span>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
