import { Autocomplete, Box, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'
import { deviceMetrics, deviceTypes, iotModels } from '../../app/config/config'
import { useAppSelector } from '../../app/hooks';

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  apiKeys: any;
  locations: any;
}

export default function CreateDeviceDialog({ open, onSave, onClose, apiKeys, locations }: ICreateProps) {

  const devices = useAppSelector((s) => s.devices);
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [type, setType] = useState("");
  const [subAreas, setSubAreas] = useState([]);
  const [location, setLocation] = useState("");
  const [area, setArea] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isV2, setIsV2] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [model, setModel] = useState("");
  const [isAreaMetric, setIsAreaMetric] = useState(false)
  const [apiKey, setApiKey] = useState("");
  const [heatmap, setHeatmap] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [associatedCamera, setAssociatedCamera] = useState("");

  useEffect(() => {
    if (!open) {
      setName("");
      setSerial("");
      setType("");
      setSubAreas([]);
      setLocation("")
      setArea("")
      setLatitude("")
      setLongitude("")
      setIsV2(false);
      setMetrics([]);
      setModel("");
      setIsAreaMetric(false);
      setAssociatedCamera("");
      setApiKey("");
      setHeatmap(false);
      setDisabled(false);
    }
  }, [open])

  const removeLastT = (val: string) => {
    return val.replace('\t', '');
  }

  const stripAndSet = (func: Function, val: string | string[]) => {
    if (Array.isArray(val)) {
      func(val.map(s => removeLastT(s)))
      return;
    }

    func(removeLastT(val))

  }

  const handleSubAreasChange = (e: any, value: any) => {
    stripAndSet(setSubAreas, value)
  }

  const handleMetricsChange = (event: any) => {
    const { target: { value } } = event;

    stripAndSet(setMetrics, typeof value === 'string' ? value.split(',') : value)

    const metricContainsArea = value.find((metric: any) => {
      if (metric.includes("area")) {
        return true;
      }
      return false
    });

    metricContainsArea ? setIsAreaMetric(true) : setIsAreaMetric(false);
  }

  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => stripAndSet(setName, e.target.value)} />
    <TextField label="Serial Number" fullWidth value={serial} onChange={(e) => stripAndSet(setSerial, e.target.value)} />
    <FormControl fullWidth required>
      <InputLabel id="typeLabel">Type</InputLabel>
      <Select value={type} labelId="typeLabel" fullWidth label="Type" onChange={(e) => stripAndSet(setType, e.target.value)}>
        {deviceTypes.map(type => (
          <MenuItem value={type.value} key={type.value}>{type.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl fullWidth>
      <InputLabel id="metricsLabel">Metrics</InputLabel>
      <Select value={metrics} labelId="metricsLabel" fullWidth multiple label="Metrics" onChange={handleMetricsChange}
        renderValue={(selected) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}>
        {deviceMetrics.map(metric => (
          <MenuItem value={metric.type} key={metric.type}>{metric.type}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {type === "iot" && (
      <FormControl fullWidth required={type === "iot"}>
        <InputLabel id="modelLabel">Model</InputLabel>
        <Select value={model} labelId="modelLabel" fullWidth label="Model" onChange={(e) => stripAndSet(setModel, e.target.value)}>
          {iotModels.map(m => (
            <MenuItem value={m} key={m}>{m}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    <FormControl fullWidth>
      {/* <InputLabel id="subAreasLabel">Sub Areas</InputLabel> */}
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        freeSolo
        onChange={(e, value) => handleSubAreasChange(e, value)}
        value={subAreas || []}

        renderTags={(
          value: any[],
          getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
        ) =>
          value.map((option: any, index: any) => {
            return (
              <Chip
                key={index}
                // variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params: any) => {
          params.inputProps.value = removeLastT(params.inputProps.value)
          return (
            <TextField
              {...params}
              label="Sub Areas"
              required={isAreaMetric}
              helperText="When adding a Sub Area - Press return after each entry"
              inputProps={{
                ...params.inputProps,
                required: isAreaMetric ? subAreas.length === 0 : false
              }}
            // placeholder="Sub areas"
            />
          )
        }}
      />
    </FormControl>
    <TextField label="Latitude" fullWidth value={latitude} onChange={(e) => stripAndSet(setLatitude, e.target.value)} />
    <TextField label="Longitude" fullWidth value={longitude} onChange={(e) => stripAndSet(setLongitude, e.target.value)} />
    <FormControl required fullWidth>
      <InputLabel id="apiKeyLabel">API Key</InputLabel>
      <Select value={apiKey} labelId="apiKeyLabel" fullWidth label="API Key" onChange={(e) => stripAndSet(setApiKey, e.target.value)}>
        {apiKeys.map((key: any) => (
          <MenuItem value={key.key} key={key.key}>{key.Name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl required fullWidth>
      <InputLabel id="locationLabel">Location</InputLabel>
      <Select value={location} labelId="locationLabel" fullWidth label="Location" onChange={(e) => { stripAndSet(setLocation, e.target.value); setArea("") }}>
        {locations.map((key: any) => (
          <MenuItem value={key.id} key={key.id}>{key.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {location && (
      <FormControl required fullWidth>
        <InputLabel id="areaLabel">Area</InputLabel>
        <Select value={area} labelId="areaLabel" fullWidth label="Area" onChange={(e) => stripAndSet(setArea, e.target.value)}>
          {locations.find((l: any) => l.id === location).areas.map((key: any) => (
            <MenuItem value={key.id} key={key.id}>{key.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {(type === "iot" || type === "mica") && (
      <FormControl fullWidth>
        <InputLabel id="associatedCameraLabel">Associated Camera</InputLabel>
        <Select value={associatedCamera} labelId="associatedCameraLabel" fullWidth label="Associated Camera" onChange={(e) => setAssociatedCamera(e.target.value)}>
          {devices.data.filter(d => d.type === "camera").map(t => (
            <MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    <FormControlLabel control={<Switch checked={heatmap} onChange={(e) => setHeatmap(e.target.checked)} />} label="Heatmap" />
    <FormControlLabel control={<Switch checked={isV2} onChange={(e) => setIsV2(e.target.checked)} />} label="Is V2" />
    <FormControlLabel control={<Switch color="error" checked={disabled} onChange={(e) => setDisabled(e.target.checked)} />} label="Disabled" />
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, serial, type, subAreas, metrics, model, apiKey, location, area, associatedCamera, latitude, longitude, isV2, heatmap, disabled })
  }

  return (
    <NewItemDialog title="New Device" text="Create a new device to gather data from" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
