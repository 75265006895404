import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { Permissions, Users as UsersApi } from '../../api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUsers } from './usersSlice';

export default function UserAudit({ logs }: any) {
  return (
    <Box>
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Method</TableCell>
                  <TableCell>Resource</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log: any) => {
                  const date = new Date(log.createdAt);
                  return (
                    <TableRow
                      key={log.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        {log.method}
                      </TableCell>
                      <TableCell>{log.resource}</TableCell>
                      <TableCell>{`${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid >
    </Box >
  )
}

