import { Box, Button, styled } from '@mui/material';
import React, { useEffect } from 'react'
import { AUTH_URL } from '../../api';


const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '66vh',
  textAlign: 'center',
  gap: '20px'
});

export default function Login() {
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const client_url = process.env.REACT_APP_CLIENT_URL;

  // useEffect(() => {
  //   window.location.href = 'https://www.google.com'
  // }, [])

  return (
    <Container>
      <p>You are not currently signed in, please log in or contact support.</p>
      <Button
        variant="contained"
        href={`${AUTH_URL}/oauth/authorize?grant_type=authorization_code&response_type=code&client_id=${client_id}&redirect_uri=${client_url}/oauth/callback&state=myState&scope=auth tenantsinfo userinfo`}
      >
        Go to log in
      </Button>
    </Container >
  )
}
