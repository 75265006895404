import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton, TableCell } from '@mui/material';
import RawTableRow from './RawTableRow';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const DndTableRow = (props: any) => {
  const { id } = props.rowData;
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    opacity: !!props.isSortable && isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <RawTableRow ref={setNodeRef} style={style} >
      {(!!props.isSortable) && (
        <TableCell sx={{ width: '40px', padding: '4px', borderRight: '1px solid #e0e0e0' }}>
          <IconButton color='default'>
            <DragIndicatorIcon sx={{ outline: 'none' }} {...attributes} {...listeners} />
          </IconButton>
        </TableCell>
      )}
      {props.children}
    </RawTableRow>
  );
};

export default DndTableRow;