import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, FormControl, Grid, Menu, MenuItem, Select, Slider, Switch, TextField } from '@mui/material';
import { styled } from '@mui/material/styles'
import { Usecases as UsecasesApi } from '../../api';
import { tagOptions } from '../../app/config/config'
import { usecaseDisplayTypes } from '../../app/config/usecases/display-types'
import { mainTileDataOptions, subTileDataOptions, compareToOptions, graphIntervalOptions, timeRangeSliderMarks, timeRangeDisplayValue, timeRangeMinRange, timeIntervalOptions } from '../../app/config/usecases/config'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useAppSelector } from '../../app/hooks';

interface IEditProps {
  usecase: any;
  datagroups: any;
  refreshForm(): any;
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #c3c3c3'
}));

const ParentItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '1px solid #c3c3c3'
}));

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '3px',
  border: '1px solid #ddd'
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));

export default function UsecaseSettings({ usecase, datagroups, refreshForm }: IEditProps) {

  const [displayType, setDisplayType] = useState("");
  const [displayTypeOptions, setDisplayTypeOptions] = useState<any>({});
  const [name, setName] = useState("");
  const [displayPosition, setDisplayPosition] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [cameraFeed, setCameraFeed] = useState("");
  const [cameraFeeds, setCameraFeeds] = useState([]);
  const [cameraFeedSubArea, setCameraFeedSubArea] = useState("");
  const [subAreaOptions, setSubAreaOptions] = useState<any>([]);
  const [cameraStreamUrl, setCameraStreamUrl] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [timeRange, setTimeRange] = useState<number[]>([0, 1439]);
  const [showTimeRange, setShowTimeRange] = useState<boolean>(false);
  const [graphInterval, setGraphInterval] = useState("");
  const [compareTo, setCompareTo] = useState("");
  const [mainTileOptions, setMainTileOptions] = useState([]);
  const [subTileOptions, setSubTileOptions] = useState([]);
  const [displayLastUpdated, setDisplayLastUpdated] = useState(false);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [timeIntervals, setTimeIntervals] = useState(["1minute", "15minutes", "1hour", "1day"]);
  const [tags, setTags] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("#28a745");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("#ffc107");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("#dc3545");
  const [fallbackColor, setFallbackColor] = useState("#dc3595");
  const [secondaryLimit1, setsecondaryLimit1] = useState("");
  const [secondaryLimit2, setsecondaryLimit2] = useState("");
  const [secondaryLimit3, setsecondaryLimit3] = useState("");
  const [templatesMenuEl, setTemplatesMenuEl] = useState(null)
  const [error, setError] = useState('');
  const [thresholdTemplates, setThresholdTemplates] = useState<any[]>([]);

  const devices = useAppSelector((s) => s.devices);
  const settings = useAppSelector((s) => s.settings);

  useEffect(() => {
    const thresholds = settings.data.filter((s) => s.type === "thresholdtemplates").map((t: any) => ({
      name: t.key,
      ...t.value
    }))

    setThresholdTemplates(thresholds);
  
    return () => {
      setThresholdTemplates([]);
    }
  }, [settings.data])

  const handleDataTypeChange = (event: any) => {
    const { target: { value } } = event;
    const newDisplayTypeOptions = usecaseDisplayTypes.find(type => type.name === value);
    if (!newDisplayTypeOptions) return
    const { main_tile_data_slots, sub_tile_data_slots, sub_title, camera_feed, camera_feeds, camera_stream_url, data_source, data_sources, time_range, graph, compare_to, display_last_updated, display_notifications, unit_of_measurement, secondary_thresholds } = newDisplayTypeOptions;
    const updateMainTileOptions = mainTileOptions.slice(0, main_tile_data_slots)
    const updateSubTileOptions = subTileOptions.slice(0, sub_tile_data_slots)

    setDisplayTypeOptions(newDisplayTypeOptions)
    setDisplayType(value)
    setMainTileOptions(updateMainTileOptions)
    setSubTileOptions(updateSubTileOptions)

    if (!sub_title) { setSubTitle(""); }
    if (!camera_feed) { setCameraFeed(""); setCameraFeedSubArea(""); setSubAreaOptions([]) }
    if (!camera_feeds) { setCameraFeeds([]) }
    if (!camera_stream_url) { setCameraStreamUrl("") }
    if (!unit_of_measurement) { setUnitOfMeasurement(""); }
    if (!time_range) { setTimeRange([0, 1439]) }
    if (!sub_tile_data_slots) { setSubTileOptions([]); }
    if (!data_source) { setDataSource(""); }
    if (!data_sources) { setDataSources([]); }
    if (!graph) { setGraphInterval(""); }
    if (!compare_to) { setCompareTo(""); }
    if (!display_last_updated) { setDisplayLastUpdated(false); }
    if (!display_notifications) { setDisplayNotifications(false); }
    if (!secondary_thresholds) { setsecondaryLimit1(""); setsecondaryLimit2(""); setsecondaryLimit3("") }
  }

  const handlCameraFeedChange = (event: any) => {
    const { target: { value } } = event;
    const selectedDevice = devices.data.find(d => d.id === value)

    setSubAreaOptions(selectedDevice?.sub_areas)
    setCameraFeed(value)
    setCameraFeedSubArea("")
  }

  const handlCameraFeedsChange = (event: any) => {
    const { target: { value } } = event;
    console.log(value);
    setCameraFeeds(value);
  }


  const handleDataSourcesChange = (event: any) => {
    const { target: { value } } = event;
    console.log(value);
    setDataSources(value);
  }

  const handleMainTileOptionsChange = (event: any) => {
    const { target: { value } } = event;
    if (value.length > displayTypeOptions?.main_tile_data_slots) return;

    setMainTileOptions(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSubTileOptionsChange = (event: any) => {
    const { target: { value } } = event;
    if (value.length > displayTypeOptions?.sub_tile_data_slots) return;

    setSubTileOptions(typeof value === 'string' ? value.split(',') : value)
  }

  const handleTimeRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setSettingsChanged(true)
    if (activeThumb === 0) {
      setTimeRange([Math.min(newValue[0], timeRange[1] - timeRangeMinRange), timeRange[1]]);
    } else {
      setTimeRange([timeRange[0], Math.max(newValue[1], timeRange[0] + timeRangeMinRange)]);
    }
  };

  const handleTagsChange = (event: any) => {
    const { target: { value } } = event;

    setTags(typeof value === 'string' ? value.split(',') : value)
  }

  const handleTimeIntervalsChange = (event: any) => {
    const { target: { value } } = event;

    const order = timeIntervalOptions.map(i => i.value);
    const values = typeof value === 'string' ? value.split(',') : value;
    const newTimeIntervalsValue = [...values].sort((a, b) => order.indexOf(a) - order.indexOf(b));

    setTimeIntervals(newTimeIntervalsValue);
  }

  const reverseColorOrder = () => {
    const col1 = limit1Color;
    const col3 = limit3Color;

    setLimit1Color(col3);
    setLimit3Color(col1);
    setSettingsChanged(true)
  }

  const fillFromTemplate = (template: any) => {
    setLimit1Color(template.limit1Color);
    setLimit1(template.limit1);
    setLimit2Color(template.limit2Color);
    setLimit2(template.limit2);
    setLimit3Color(template.limit3Color);
    setLimit3(template.limit3);
    setFallbackColor(template.fallbackColor);
    setTemplatesMenuEl(null)
    setSettingsChanged(true)
  }

  const saveEdit = async (e: any) => {
    e.preventDefault()
    const selectedDataSource = datagroups.find((d: any) => d.id === dataSource)
    if (!selectedDataSource && usecase.display_type !== "CameraAccess" && usecase.display_type !== "CameraFeed" && usecase.display_type !== "TiltVibrationDetection") return
    try {
      await UsecasesApi.patch(
        usecase.tenant_id,
        usecase.id,
        displayType,
        name,
        subTitle,
        cameraFeed,
        cameraFeeds,
        cameraFeedSubArea,
        cameraStreamUrl,
        dataSource,
        dataSources,
        selectedDataSource?.metric,
        unitOfMeasurement,
        timeRange,
        mainTileOptions,
        subTileOptions,
        graphInterval,
        compareTo,
        displayLastUpdated,
        displayNotifications,
        timeIntervals,
        tags,
        disabled,
        limit1,
        limit1Color,
        limit2,
        limit2Color,
        limit3,
        limit3Color,
        fallbackColor,
        secondaryLimit1,
        secondaryLimit2,
        secondaryLimit3,
        displayPosition,
      );
      refreshForm();
      setSettingsChanged(false);
    } catch (error: any) {
      console.log(error)
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  useEffect(() => {
    const usecaseDisplayTypeOptions = usecaseDisplayTypes.find(type => type.name === usecase.display_type);
    const deviceSubAreas = devices.data.find(d => d.id === usecase.camera_feed)?.sub_areas
    const usecaseTimeRange = usecase.time_range || [0, 1439];
    const usecaseTimeIntervals = usecase.time_intervals?.length ? usecase.time_intervals : ["1minute", "15minutes", "1hour", "1day"];
    setSubAreaOptions(deviceSubAreas || []);
    setDisplayTypeOptions(usecaseDisplayTypeOptions)
    setDisplayType(usecase.display_type);
    setName(usecase.name);
    setSubTitle(usecase.sub_title || "");
    setCameraFeed(usecase.camera_feed || "");
    setCameraFeeds(usecase.camera_feeds || []);
    setCameraFeedSubArea(usecase.camera_feed_sub_area || "");
    setCameraStreamUrl(usecase.camera_stream_url || "")
    setDataSource(usecase.data_source);
    setDataSources(usecase.data_sources || []);
    setUnitOfMeasurement(usecase.unit_of_measurement || "");
    setTimeRange(usecaseTimeRange);
    setMainTileOptions(usecase.main_tile_options || []);
    setSubTileOptions(usecase.sub_tile_options || []);
    setGraphInterval(usecase.graph_interval || "");
    setCompareTo(usecase.compare_to || "");
    setDisplayLastUpdated(usecase.display_last_updated || false);
    setDisplayNotifications(usecase.display_notifications || false);
    setTags(usecase.tags);
    setTimeIntervals(usecaseTimeIntervals);
    setDisabled(usecase.disabled || false);
    setLimit1(usecase.limit_1 || "");
    setLimit1Color(usecase.limit_1_color || "");
    setLimit2(usecase.limit_2 || "");
    setLimit2Color(usecase.limit_2_color || "");
    setLimit3(usecase.limit_3 || "");
    setLimit3Color(usecase.limit_3_color || "");
    setFallbackColor(usecase.limit_fallback_color || "");
    setsecondaryLimit1(usecase.secondary_limit_1);
    setsecondaryLimit2(usecase.secondary_limit_2);
    setsecondaryLimit3(usecase.secondary_limit_3);
    setDisplayPosition(usecase.display_position || 0);

    if (Array.isArray(usecaseTimeRange) && usecaseTimeRange[0] !== 0 && usecaseTimeRange[1] !== 1439) {
      setShowTimeRange(true)
    }

  }, [usecase, devices])

  useEffect(() => {
    if (showTimeRange) {
      setTimeRange((prev) => {
        if (Array.isArray(prev) && prev[0] === 0 && prev[1] === 1439) {
          setSettingsChanged(true);
          return [60, 480];
        }

        return prev;
      })
    }

    if (!showTimeRange) {
      setTimeRange((prev) => {
        if (Array.isArray(prev) && prev[0] === 0 && prev[1] === 1439) {
          return prev;
        }

        setSettingsChanged(true);
        return [0, 1439];
      })
    }


  }, [showTimeRange])


  return (
    <Box component="form" onSubmit={saveEdit}>
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={12}>
          <Item>
            <p>Display Position*</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField fullWidth placeholder="Usecase Order" type="number" value={displayPosition} onChange={(e) => { setDisplayPosition(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Display Type</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={displayType} fullWidth onChange={(e) => { handleDataTypeChange(e); setSettingsChanged(true) }} >
                {usecaseDisplayTypes.map((type: any) => (
                  <MenuItem value={type.name} key={type.name}>{type.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        <Grid item xs={12}>
          <Item>
            <p>Name*</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField required fullWidth placeholder="Usecase Name" value={name} onChange={(e) => { setName(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        {!!displayTypeOptions?.sub_title && (
          <Grid item xs={12}>
            <Item>
              <p>Sub Title</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <TextField fullWidth placeholder="Usecase Sub Title" value={subTitle} onChange={(e) => { setSubTitle(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
            </Item>
          </Grid>
        )}
        {(!!displayTypeOptions?.camera_feed && devices.status === "idle") && (
          <Grid item xs={12}>
            <Item>
              <p>Camera Feed</p>
              {/* {(!!displayTypeOptions?.camera_feed && devices.status !== "idle") &&
                <p>hvbugvhfduu</p>
              } */}
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={cameraFeed} labelId="cameraFeedLabel" fullWidth onChange={(e) => { handlCameraFeedChange(e); setSettingsChanged(true) }} >
                  {devices.data.map((device: any) => (
                    <MenuItem value={device.id} key={device.id}>{device.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {(!!displayTypeOptions?.camera_feeds && devices.status === "idle") && (
          <Grid item xs={12}>
            <Item>
              <p>Camera Feeds</p>
              {/* {(!!displayTypeOptions?.camera_feed && devices.status !== "idle") &&
                <p>hvbugvhfduu</p>
              } */}
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select multiple value={cameraFeeds} labelId="cameraFeedsLabel" fullWidth onChange={(e) => { handlCameraFeedsChange(e); setSettingsChanged(true) }} >
                  {devices.data.filter(c => c.type === "camera").map((device: any) => (
                    <MenuItem value={device.id} key={device.id}>{device.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {!!subAreaOptions.length && (
          <Grid item xs={12}>
            <Item>
              <p>Camera Feed Sub Area</p>

              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={cameraFeedSubArea} fullWidth onChange={(e) => { setCameraFeedSubArea(e.target.value); setSettingsChanged(true) }} >
                  {subAreaOptions.map((option: any) => (
                    <MenuItem value={option.name} key={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.camera_stream_url && (
          <Grid item xs={12}>
            <Item>
              <p>Camera Stream URL</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <TextField fullWidth placeholder="Camera Stream URL" value={cameraStreamUrl} onChange={(e) => { setCameraStreamUrl(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
            </Item>
          </Grid>
        )}
        {!!displayTypeOptions?.data_source && (
        <Grid item xs={12}>
          <Item>
            <p>Data Source</p>

            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={dataSource} fullWidth onChange={(e) => { setDataSource(e.target.value); setSettingsChanged(true) }} >
                {datagroups.map((datagroup: any) => (
                  <MenuItem value={datagroup.id} key={datagroup.name}>{datagroup.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        )}
      {!!displayTypeOptions?.data_sources && (
        <Grid item xs={12}>
          <Item>
            <p>Data Sources</p>

            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select multiple value={dataSources} fullWidth onChange={(e) => { handleDataSourcesChange(e); setSettingsChanged(true) }} >
                {datagroups.map((datagroup: any) => (
                  <MenuItem value={datagroup.id} key={datagroup.name}>{datagroup.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        )}
        {!!displayTypeOptions?.unit_of_measurement && (
          <Grid item xs={12}>
            <Item>
              <p>Unit of Measurement</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <TextField fullWidth placeholder="Unit of Measurement" value={unitOfMeasurement} onChange={(e) => { setUnitOfMeasurement(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
            </Item>
          </Grid>
        )}
        {!!displayTypeOptions?.time_range && (
          <Grid item xs={12}>
            <Item>
              <p>Time Range</p>
              <FormControl sx={{ m: 1, minWidth: 300, gap: 2, flexDirection: 'row', justifyContent: 'flex-end' }}>
                {showTimeRange && (
                  <Slider
                    getAriaLabel={() => 'Time Range'}
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={timeRangeDisplayValue}
                    valueLabelFormat={timeRangeDisplayValue}
                    step={15}
                    min={60}
                    max={480}
                    disableSwap
                    marks={timeRangeSliderMarks}
                  />
                )}
                <Switch checked={showTimeRange} onChange={(e) => { setShowTimeRange(e.target.checked); }} />

              </FormControl>
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.main_tile_data_slots && (
          <Grid item xs={12}>
            <Item>
              <p>Main tile (Select {displayTypeOptions?.main_tile_data_slots})</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={mainTileOptions} labelId="mainTileLabel" fullWidth multiple onChange={(e) => { handleMainTileOptionsChange(e); setSettingsChanged(true) }}
                  renderValue={(selected) => (
                    // eslint-disable-next-line react/jsx-no-undef
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}>
                  {mainTileDataOptions.map(option => (
                    <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.sub_tile_data_slots && (
          <Grid item xs={12}>
            <Item>
              <p>Sub tile (Select {displayTypeOptions?.sub_tile_data_slots})</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={subTileOptions} labelId="subTileLabel" fullWidth multiple onChange={(e) => { handleSubTileOptionsChange(e); setSettingsChanged(true) }}
                  renderValue={(selected) => (
                    // eslint-disable-next-line react/jsx-no-undef
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}>
                  {subTileDataOptions.map(option => (
                    <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.graph && (
          <Grid item xs={12}>
            <Item>
              <p>Graph interval</p>

              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={graphInterval} fullWidth onChange={(e) => { setGraphInterval(e.target.value); setSettingsChanged(true) }} >
                  {graphIntervalOptions.map((option: any) => (
                    <MenuItem value={option.value} key={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.compare_to && (
          <Grid item xs={12}>
            <Item>
              <p>Compare to</p>

              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={compareTo} fullWidth onChange={(e) => { setCompareTo(e.target.value); setSettingsChanged(true) }} >
                  {compareToOptions.map((option: any) => (
                    <MenuItem value={option.value} key={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.display_last_updated && (
          <Grid item xs={12}>
            <Item>
              <p>Display last updated</p>
              <Switch checked={displayLastUpdated} onChange={(e) => { setDisplayLastUpdated(e.target.checked); setSettingsChanged(true) }} />
            </Item>
          </Grid >
        )}
        {!!displayTypeOptions?.display_notifications && (
          <Grid item xs={12}>
            <Item>
              <p>Display notifications</p>
              <Switch checked={displayNotifications} onChange={(e) => { setDisplayNotifications(e.target.checked); setSettingsChanged(true) }} />
            </Item>
          </Grid >
        )}
        <Grid item xs={12}>
          <Item>
            <p>Time Intervals</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={timeIntervals} labelId="timeIntervalsLabel" fullWidth multiple onChange={(e) => { handleTimeIntervalsChange(e); setSettingsChanged(true) }}
                renderValue={(selected) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}>
                {timeIntervalOptions.map(option => (
                  <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        <Grid item xs={12}>
          <Item>
            <p>Tags</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={tags} labelId="tagsLabel" fullWidth multiple onChange={(e) => { handleTagsChange(e); setSettingsChanged(true) }}
                renderValue={(selected) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}>
                {tagOptions.map(tag => (
                  <MenuItem value={tag} key={tag}>{tag}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        <Grid item xs={12}>
          <ParentItem>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button sx={{ mt: 2, mb: 2 }} size="small" onClick={(e: any) => setTemplatesMenuEl(e.currentTarget)} variant="contained" endIcon={<KeyboardArrowDownIcon />}>Templates</Button>
              <Menu
                anchorEl={templatesMenuEl}
                open={!!templatesMenuEl}
                onClose={() => setTemplatesMenuEl(null)}
              >
                {thresholdTemplates.map((t: any) => (
                  <MenuItem key={t.name} onClick={() => fillFromTemplate(t)}>{t.name}</MenuItem>
                ))}
              </Menu>
              <Button sx={{ mt: 2, mb: 2 }} size="small" variant="contained" onClick={reverseColorOrder}>Reverse Thresholds</Button>
            </div>
            {!!displayTypeOptions?.secondary_thresholds && (
        <>
          <SubItem>
            <p>Threshold Limit 1</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Tilt" label="Tilt" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Vibration" label="Vibration" value={secondaryLimit1} type="number" onChange={(e) => { setsecondaryLimit1(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit1Color }} />
            </div>
          </SubItem>
          <SubItem>
            <p>Threshold Limit 2</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Tilt" label="Tilt" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Vibration" label="Vibration" value={secondaryLimit2} type="number" onChange={(e) => { setsecondaryLimit2(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit2Color }} />
            </div>
          </SubItem>
          <SubItem>
            <p>Threshold Limit 3</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Tilt" label="Tilt" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 75 }}>
                <TextField fullWidth placeholder="Vibration" label="Vibration" value={secondaryLimit3} type="number" onChange={(e) => { setsecondaryLimit3(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <FormControl sx={{ m: 1, width: 125 }}>
                <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value); setSettingsChanged(true) }} />
              </FormControl>
              <ColorPreview sx={{ backgroundColor: limit3Color }} />
            </div>
          </SubItem>      
        </>
      )}
      {!displayTypeOptions?.secondary_thresholds && (
        <>
          <SubItem>
              <p>Threshold Limit 1</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: limit1Color }} />
              </div>
            </SubItem>
            <SubItem>
              <p>Threshold Limit 2</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: limit2Color }} />
              </div>
            </SubItem>
            <SubItem>
              <p>Threshold Limit 3</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: limit3Color }} />
              </div>
            </SubItem>
            <SubItem>
              <p>Fallback</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={fallbackColor} onChange={(e) => { setFallbackColor(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: fallbackColor }} />
              </div>
            </SubItem>   
        </>
      )}
          </ParentItem>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Disable usecase</p>
            <Switch checked={disabled} onChange={(e) => { setDisabled(e.target.checked); setSettingsChanged(true) }} />
          </Item>
        </Grid >
        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={!settingsChanged}
              onClick={(e) => { refreshForm(); setSettingsChanged(false) }}
            >
              Discard changes
            </Button>

            <Button
              variant="contained"
              disabled={!settingsChanged}

              type="submit"
            >
              Save settings
            </Button>
          </ButtonContainer>

        </Grid>
      </Grid >
    </Box >
  )
}
