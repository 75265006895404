import React, { useEffect, useState } from "react";
import {
  alpha,
  IconButton,
  InputBase,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SendIcon from "@mui/icons-material/Send";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Link, useLocation } from "react-router-dom";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
// import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';

import DndTableRow from "./DndTableRow";
import RawTableRow from "./RawTableRow";

import ConfirmationDialog from "../../pages/layout/ConfirmationDialog";
import ChangePasswordDialog from "../../pages/layout/ChangePasswordDialog";
import UserAdminOptions from "./UserAdminOptions";

import { Users as UsersApi } from "../../api";

const Container = styled(Paper)(({ theme }) => ({
  "&.active": {
    borderRight: "2px solid #333",
  },
}));

const Search = styled("div")(({ theme }: { theme: any }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(2, 2, 0, 0),
  width: "100%",
  maxWidth: "500px",
  border: "1px solid #e0e0e0",
  float: "right",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const emptyString = () => "";

interface Props {
  deleteItem?: (arg: any) => void;
  editItem?: Function;
  name: string;
  data: any[];
  headers: string[];
  viewButton: boolean;
  editButton: boolean;
  deleteButton: boolean;
  showUserAdminOptionsButton?: boolean;
  updateTable?: () => void;
  routePrefix?: string;
  editDisplayPosition?: Function;
}

export default function CustomTable({
  name = "",
  data = [],
  headers = [""],
  viewButton = false,
  editButton = false,
  deleteButton = false,
  showUserAdminOptionsButton = false,
  deleteItem = emptyString,
  editItem,
  editDisplayPosition,
  routePrefix = "",
  updateTable = () => {},
}: Props) {
  const prevRoute = useLocation();

  const [activeId, setActiveId] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [rowItems, setRowItems] = useState<any[]>([]);
  const [isTableSortable, setIsTableSortable] = useState(false);
  const [rowOrderChanged, setRowOrderChanged] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedUserId, setSelectedUserId] = useState("");

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    elementId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(elementId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [newPasswordConfirmationDialog, setNewPasswordConfirmationDialog] =
    useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [deleteUserConfirmationDialog, setDeleteUserConfirmationDialog] =
    useState(false);

  useEffect(() => {
    if (data?.some((d) => d.Position)) {
      setRowItems(
        data
          .map((d, i: number) => ({ ...d, id: d.id || d.key || i + 1 }))
          .sort((a, b) => a.Position - b.Position)
      );
      setIsTableSortable(true);
    } else {
      setRowItems(data);
    }
    return () => {
      setRowItems([]);
    };
  }, [data]);

  useEffect(() => {
    const foundItem = rowItems.find((r) => r.id === activeId);
    setActiveItem(foundItem);
    return () => {
      setActiveItem(null);
    };
  }, [activeId, rowItems]);

  useEffect(() => {
    if (!rowOrderChanged) return;
    if (editDisplayPosition) {
      editDisplayPosition(rowItems);
      setRowOrderChanged(false);
    }
  }, [editDisplayPosition, rowItems, rowOrderChanged]);

  return (
    <>
      <Container>
        {/* <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search> */}
        <TableContainer>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Table sx={{}} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {!!isTableSortable && (
                    <TableCell sx={{ width: "40px", paddingRight: "0px" }} />
                  )}
                  {headers.map((h) => (
                    <TableCell sx={{ maxWidth: "25%" }} key={h}>
                      {h}
                    </TableCell>
                  ))}
                  {rowItems.length ? (
                    <TableCell align="right" sx={{ minWidth: "152px" }}>
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowItems.length ? (
                  <>
                    <SortableContext
                      items={rowItems}
                      strategy={verticalListSortingStrategy}
                    >
                      {rowItems.map((r: any) => (
                        <DndTableRow
                          key={r.key}
                          rowData={r}
                          isSortable={!!r.Position}
                        >
                          {headers.map((h) => (
                            <TableCell
                              sx={{
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              key={h}
                            >
                              {r[h]}
                            </TableCell>
                          ))}
                          <TableCell align="right" sx={{ minWidth: "152px" }}>
                            {!!viewButton && (
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="view"
                                  color="default"
                                  component={Link}
                                  state={{ prevRoute }}
                                  to={
                                    routePrefix
                                      ? `${routePrefix}/${r.key}`
                                      : r.key
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            {!!editItem && (
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  color="default"
                                  onClick={() => editItem(r.key)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            {!!deleteButton && !r.hideDelete && (
                              <Tooltip title={"Delete " + name}>
                                {/* <IconButton aria-label="delete" color='default' onClick={e => this.deleteItem(data.key)}> */}
                                <IconButton
                                  aria-label="delete"
                                  color="default"
                                  onClick={() => deleteItem(r.key)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            {showUserAdminOptionsButton && (
                              <UserAdminOptions
                                userId={r.key}
                                key={r.key}
                                updateTable={updateTable}
                              />
                            )}
                          </TableCell>
                        </DndTableRow>
                      ))}
                    </SortableContext>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length} align="center">
                      No {name} Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <DragOverlay
            // modifiers={[restrictToFirstScrollableAncestor]}
            >
              {activeId && activeItem && (
                <Table
                  sx={{ backgroundColor: "#fff" }}
                  aria-label="customized table"
                >
                  <TableBody>
                    <RawTableRow>
                      <TableCell
                        sx={{
                          opacity: "0.6",
                          width: "40px",
                          padding: "4px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        <IconButton color="default">
                          <DragIndicatorIcon sx={{ outline: "none" }} />
                        </IconButton>
                      </TableCell>

                      {headers.map((h) => (
                        <TableCell
                          sx={{
                            opacity: "0.6",
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          key={h}
                        >
                          {activeItem[h]}
                        </TableCell>
                      ))}
                      <TableCell
                        align="right"
                        sx={{ minWidth: "152px", opacity: "0.6" }}
                      >
                        {!!viewButton && (
                          <Tooltip title="View">
                            <IconButton aria-label="view" color="default">
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!!editItem && (
                          <Tooltip title="Edit">
                            <IconButton aria-label="edit" color="default">
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {!!deleteButton && (
                          <Tooltip title={"Delete " + name}>
                            <IconButton aria-label="delete" color="default">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </RawTableRow>
                  </TableBody>
                </Table>
              )}
            </DragOverlay>
          </DndContext>
        </TableContainer>
        {/* <TableFooter sx={{ display: 'flex', justifyContent: 'right' }} >
          <TableRow>
            <TablePagination
              align="right"
              rowsPerPageOptions={[]}
              count={10}
              rowsPerPage={10}
              page={0}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={() => { }}
            // onRowsPerPageChange={() => { }}
            />
          </TableRow>
        </TableFooter> */}
      </Container>
    </>
  );

  function handleDragStart(event: any) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;
    // console.log(active, over);

    if (over && active.id !== over?.id) {
      setRowItems((items) => {
        // const oldIndex = items.indexOf(active.id);
        const activeIndex = items.findIndex((i) => i.id === active.id);
        // const newIndex = items.indexOf(over.id);
        const overIndex = items.findIndex((i) => i.id === over.id);
        // console.log(activeIndex, overIndex)
        activeIndex || overIndex
          ? setRowOrderChanged(true)
          : setRowOrderChanged(false);

        return arrayMove(items, activeIndex, overIndex);
      });
    }
    setActiveId(null);
  }
}
