import { Box, Button, Checkbox, FormControlLabel, Grid, Switch } from '@mui/material';
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { Permissions, Users as UsersApi } from '../../api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUsers } from './usersSlice';

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));

export default function UserPermissions({ user, permissions, save }: any) {

  const locations = useAppSelector(s => s.locations)
  const dispatch = useAppDispatch();

  const [userPermissions, setUserPermissions] = useState<any>([]);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setUserPermissions(permissions.locations || [])

    return () => {
      setUserPermissions([])
    }
  }, [permissions])

  useEffect(() => {
    if (!settingsChanged) {
      setUserPermissions(permissions.locations || [])
    }
  }, [permissions, settingsChanged])


  const handleParentChange = (id: string, checked: boolean) => {
    setSettingsChanged(true)
    if (!checked) {
      return setUserPermissions((p: any) => {
        return p.filter((l: any) => l.locationId !== id);
      })
    }

    return setUserPermissions((p: any) => {
      return [...p, { locationId: id, areas: [] }];
    })
  }

  const handleChildChange = (id: string, location: string, checked: boolean) => {
    setSettingsChanged(true)
    if (!checked) {
      return setUserPermissions((p: any) => {
        return p.map((l: any) => {
          if (l.locationId !== location) return l;
          const areas = l.areas.filter((a: any) => a.areaId !== id);
          return { ...l, areas };
        })
      })
    }

    return setUserPermissions((p: any) => {
      const locationIndex = p.findIndex((p: any) => p.locationId === location);
      if (locationIndex === -1) {
        return [...p, { locationId: location, areas: [{ areaId: id }] }]
      }

      return p.map((l: any) => {
        if (l.locationId !== location) return l;
        return { ...l, areas: [...l.areas, { areaId: id }] };
      })
    })

  }

  const saveEdit = async (e: any) => {
    e.preventDefault()

    await save(userPermissions);
    setSettingsChanged(false)
  }


  return (
    <Box component="form" onSubmit={saveEdit}>
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={12}>
          {locations.data.map(location => {
            const isChecked = userPermissions.findIndex((l: any) => l.locationId === location.id);
            const areaIDs = isChecked === -1 || !userPermissions[isChecked].areas ? [] : userPermissions[isChecked].areas.map((a: any) => a.areaId);
            const allChildrenChecked = isChecked !== -1 && location.areas.every(a => {
              return areaIDs.includes(a.id);
            })
            return (
              <React.Fragment key={location.id}>
                <FormControlLabel
                  label={location.name}
                  control={
                    <Checkbox
                      checked={allChildrenChecked}
                      indeterminate={isChecked !== -1 && !allChildrenChecked}
                      onChange={(e) => handleParentChange(location.id, isChecked === -1)}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  {location.areas.map(area => (
                    <FormControlLabel
                      key={area.id}
                      label={area.name}
                      control={
                        <Checkbox
                          checked={areaIDs.includes(area.id)}
                          onChange={(e) => handleChildChange(area.id, location.id, e.target.checked)}
                        />
                      }
                    />
                  ))}
                </Box>
              </React.Fragment>
            )
          })}
        </Grid>
        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={!settingsChanged}
              onClick={(e) => setSettingsChanged(false)}
            >
              Discard changes
            </Button>

            <Button
              variant="contained"
              disabled={!settingsChanged}
              type="submit"
            >
              Save settings
            </Button>
          </ButtonContainer>

        </Grid>
      </Grid >
    </Box >
  )
}

