import { Box, Button, Chip, FormControl, Grid, Menu, MenuItem, Select, Switch, TextField } from '@mui/material';
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { Datagroups as DatagroupsApi } from '../../api';
import { deviceMetrics, formulaOptions, formulaOptionsPerMetric, tagOptions, thresholdOrderOptions } from '../../app/config/config'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppSelector } from '../../app/hooks';

interface IEditProps {
  datagroup: any;
  refreshForm(): any;
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #c3c3c3'
}));

const ParentItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '1px solid #c3c3c3'
}));

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '3px',
  border: '1px solid #ddd'
}));

export default function DatagroupSettings({ datagroup, refreshForm }: IEditProps) {

  const [name, setName] = useState("");
  const [hidden, setHidden] = useState(false);
  const [metric, setMetric] = useState("");
  const [tags, setTags] = useState([]);
  const [formula, setFormula] = useState("");
  const [settingsChanged, setSettingsChanged] = useState(false);

  const [templatesMenuEl, setTemplatesMenuEl] = useState(null)
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("");
  const [thresholdTemplates, setThresholdTemplates] = useState<any[]>([]);

  const [error, setError] = useState('');

  const settings = useAppSelector((s) => s.settings);

  useEffect(() => {
    const thresholds = settings.data.filter((s) => s.type === "thresholdtemplates").map((t: any) => ({
      name: t.key,
      ...t.value
    }))

    setThresholdTemplates(thresholds);
  
    return () => {
      setThresholdTemplates([]);
    }
  }, [settings.data])

  const handleTagsChange = (event: any) => {
    const { target: { value } } = event;

    setTags(typeof value === 'string' ? value.split(',') : value)
  }

  const reverseColorOrder = () => {
    const col1 = limit1Color;
    const col3 = limit3Color;

    setLimit1Color(col3);
    setLimit3Color(col1);
    setSettingsChanged(true)
  }

  const fillFromTemplate = (template: any) => {
    setLimit1Color(template.limit1Color);
    setLimit1(template.limit1);
    setLimit2Color(template.limit2Color);
    setLimit2(template.limit2);
    setLimit3Color(template.limit3Color);
    setLimit3(template.limit3);
    setTemplatesMenuEl(null)
    setSettingsChanged(true)
  }

  const saveEdit = async (e: any) => {
    e.preventDefault()
    try {
      await DatagroupsApi.patch(
        datagroup.tenant_id,
        datagroup.id,
        name,
        metric,
        tags,
        formula,
        limit1,
        limit1Color,
        limit2,
        limit2Color,
        limit3,
        limit3Color,
        hidden,
      );
      setSettingsChanged(false);
      refreshForm();
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  useEffect(() => {
    setName(datagroup.name);
    setHidden(!!datagroup.settings.hidden);
    setTags(datagroup.tags);
    setMetric(datagroup.metric);
    setFormula(datagroup.settings.formula || "");
    setLimit1(datagroup.settings.limit_1 || "");
    setLimit2(datagroup.settings.limit_2 || "");
    setLimit3(datagroup.settings.limit_3 || "");
    setLimit1Color(datagroup.settings.limit_1_color || "");
    setLimit2Color(datagroup.settings.limit_2_color || "");
    setLimit3Color(datagroup.settings.limit_3_color || "");

  }, [datagroup])

  return (
    <Box component="form" onSubmit={saveEdit}>
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={12}>
          <Item>
            <p>Name*</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField required fullWidth placeholder="Datagroup Name" value={name} onChange={(e) => { setName(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Metric*</p>

            <FormControl sx={{ m: 1, minWidth: 250 }}>
              {/* <InputLabel id="metricLabel">Metric</InputLabel> */}
              <Select disabled value={metric} labelId="metricLabel" fullWidth onChange={(e) => { setMetric(e.target.value); setSettingsChanged(true) }} >
                {deviceMetrics.map(metric => (
                  <MenuItem value={metric.type} key={metric.type}>{metric.type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        <Grid item xs={12}>
          <Item>
            <p>Formula</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={formula} fullWidth onChange={(e) => { setFormula(e.target.value); setSettingsChanged(true) }} >
                {metric && formulaOptionsPerMetric[metric].map((formula: any) => (
                  <MenuItem value={formula} key={formula}>{formula}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        <Grid item xs={12}>
          <ParentItem>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button sx={{ mt: 2, mb: 2 }} size="small" onClick={(e: any) => setTemplatesMenuEl(e.currentTarget)} variant="contained" endIcon={<KeyboardArrowDownIcon />}>Templates</Button>
              <Menu
                anchorEl={templatesMenuEl}
                open={!!templatesMenuEl}
                onClose={() => setTemplatesMenuEl(null)}
              >
                {thresholdTemplates.map((t: any) => (
                  <MenuItem key={t.name} onClick={() => fillFromTemplate(t)}>{t.name}</MenuItem>
                ))}
              </Menu>
              <Button sx={{ mt: 2, mb: 2 }} size="small" variant="contained" onClick={reverseColorOrder}>Reverse Thresholds</Button>
            </div>
            <SubItem>
              <p>Threshold Limit 1</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: limit1Color }} />
              </div>
            </SubItem>
            <SubItem>
              <p>Threshold Limit 2</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: limit2Color }} />
              </div>
            </SubItem>
            <SubItem>
              <p>Threshold Limit 3</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <ColorPreview sx={{ backgroundColor: limit3Color }} />
              </div>
            </SubItem>
          </ParentItem>
        </Grid>
        {/* <Grid item xs={12}>
          <Item>
            <p>Tags</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={tags} labelId="tagsLabel" fullWidth multiple onChange={(e) => { handleTagsChange(e); setSettingsChanged(true) }}
                renderValue={(selected) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}>
                {tagOptions.map(tag => (
                  <MenuItem value={tag} key={tag}>{tag}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid > */}
        {/* <Grid item xs={12}>
          <ParentItem>
            <SubItem>
              <p>Threshold Limit 1</p>
              <div>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
              </div>
            </SubItem>
            <SubItem>
              <p>Threshold Limit 2</p>
              <div>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
              </div>
            </SubItem>
            <SubItem>
              <p>Threshold Limit 3</p>
              <div>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Value" label="Value" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
                <FormControl sx={{ m: 1, width: 125 }}>
                  <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value); setSettingsChanged(true) }} />
                </FormControl>
              </div>
            </SubItem>
          </ParentItem>
        </Grid > */}
        <Grid item xs={12}>
          <Item>
            <p>Hide Data Group</p>
            <Switch checked={hidden} onChange={(e) => { setHidden(e.target.checked); setSettingsChanged(true) }} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={!settingsChanged}
              onClick={(e) => { refreshForm(); setSettingsChanged(false) }}
            >
              Discard changes
            </Button>

            <Button
              variant="contained"
              disabled={!settingsChanged}
              type="submit"
            >
              Save settings
            </Button>
          </ButtonContainer>

        </Grid>
      </Grid >
    </Box >
  )
}
