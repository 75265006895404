import { Button, Toolbar, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { Users as UsersApi } from "../../api";
import CustomTable from "../../components/table/Table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUsers } from "./usersSlice";
import CreateUserDialog from "./CreateUserDialog";
import DeleteItemDialog from "../../components/delete-item-dialog/DeleteItemDialog";
import ShowPasswordDialog from "../../pages/layout/ShowPasswordDialog";

export default function Users() {
  const dispatch = useAppDispatch();
  const tenants = useAppSelector((s) => s.tenants);
  const users = useAppSelector((s) => s.users);
  const auth = useAppSelector((s) => s.auth);

  const [addDialog, setAddDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selected, setSelected] = useState<any>({});

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState("");

  const saveCreate = async (data: any) => {
    if (!tenants.selected) return;

    try {
      const response = await UsersApi.create(
        data.email,
        data.TPA,
        tenants.selected,
        data.dontSendEmail,
        data.returnPassword,
        data.forceChangePassword,
        data.password
      );
      dispatch(getUsers(tenants.selected));
      setAddDialog(false);

      if (!!response?.data?.password) {
        setPassword(response.data.password);
        setShowPasswordDialog(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveDelete = async () => {
    if (!tenants.selected) return;
    try {
      await UsersApi.delete(tenants.selected, selected.userId);
      dispatch(getUsers(tenants.selected));
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  const openAndSelectDelete = (id: string) => {
    const user = users.data.find((u) => u.userId === id);

    if (!user) return;
    setSelected(user);
    setDeleteDialog(true);
  };

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog]);

  return (
    <div>
      {/* <p>Users</p> */}
      <Toolbar
        sx={{ alignItems: "baseline", justifyContent: "space-between" }}
        disableGutters
      >
        <Typography variant="h1">Users</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setAddDialog(true)}
        >
          {" "}
          New User
        </Button>
      </Toolbar>
      <CustomTable
        name="Users"
        data={users.data.map((d: any) => ({
          Email: `${d.email}`,
          TPA: `${d.isTPA}`,
          TCA: `${d.isTCA}`,
          "Force Change Password": `${d.forceChangePassword ?? false}`,
          key: d.userId,
        }))}
        headers={["Email", "TPA", "TCA", "Force Change Password"]}
        viewButton={true}
        editButton={false}
        deleteButton={true}
        deleteItem={(id: string) => openAndSelectDelete(id)}
        showUserAdminOptionsButton={auth.data.isGA ?? false}
        updateTable={() => dispatch(getUsers(tenants?.selected ?? ""))}
      />
      <CreateUserDialog
        open={addDialog}
        onClose={() => setAddDialog(false)}
        onSave={(data: any) => saveCreate(data)}
      />
      <DeleteItemDialog
        title={`Delete User`}
        text={`Are you sure you want to delete the user with the email address ${selected?.email}? This cannot be undone.`}
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        onConfirm={saveDelete}
      />
      <ShowPasswordDialog
        onCancel={() => setShowPasswordDialog(false)}
        open={showPasswordDialog}
        title="Password"
        text={password}
      />
    </div>
  );
}
