import { Box, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { overviewWidgetOptions, widgetTypes } from '../../app/config/usecases/config';
import EditItemDialog from '../../components/edit-item-dialog/EditItemDialog';

interface IEditProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  selected: any;
  datagroups: any;
  usecase: any;
}

export default function EditOverviewWidgetDialog({ open, onSave, onClose, selected, datagroups, usecase }: IEditProps) {

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    if (open) {
      setName(selected.Name);
      setType(selected.Type);
      setDataSource(usecase.data_source);
      setTiles(selected.tiles);
    }
    return () => {
      setName("");
      setType("");
      setDataSource("");
      setTiles([]);
    }
  }, [open, selected, usecase.data_source])

  const handleTilesChanged = (event: any) => {
    const { target: { value } } = event;
    const maxTiles = !!usecase.camera_feed ? 8 : 12;
    if (value.length > maxTiles) return;

    setTiles(typeof value === 'string' ? value.split(',') : value)
  }

  const formElements = <>
    <FormControl fullWidth>
      <TextField label="Name" required fullWidth value={name} disabled />
    </FormControl>
    {/* <FormControl fullWidth>
      <TextField label="Type" required fullWidth value={type} onChange={(e) => setType(e.target.value)} />
    </FormControl> */}
    <FormControl required fullWidth>
      <InputLabel id="type">Type</InputLabel>
      <Select value={type} fullWidth label="Type" disabled >
        <MenuItem value="default">Default</MenuItem>
      </Select>
    </FormControl>
    <FormControl required fullWidth>
      <InputLabel id="dataSourceLabel">Data Source</InputLabel>
      <Select value={dataSource} labelId="dataSourceLabel" fullWidth label="Data Source" disabled>
        {datagroups.map((d: any) => (
          <MenuItem value={d.id} key={d.name}>{d.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl required fullWidth>
      <InputLabel id="mainTileLabel">Tile data display options</InputLabel>
      <Select value={tiles} labelId="mainTileLabel" fullWidth multiple label="Tile data display options" onChange={(e) => handleTilesChanged(e)}
        renderValue={(selected) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}>
        {overviewWidgetOptions.map(option => (
          <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, type, dataSource, comparison: false, tiles })
  }

  return (
    <EditItemDialog
      title={`Edit Overview Widget`}
      text={`Edit the details of this Widget`}
      FormElements={formElements}
      open={open}
      onClose={onClose}
      onSave={(e: any) => save(e)}
    />
  )
}
