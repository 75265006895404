import React, { useEffect, useState } from "react";
import ComponentShowPasswordDialog from "../../components/show-password-dialog/ShowPasswordDialog";

interface IShowPasswordDialogProps {
  open: boolean;
  title: string;
  text: string;
  onCancel: () => void;
}

export default function ShowPasswordDialog({
  open,
  title,
  text,
  onCancel,
}: IShowPasswordDialogProps) {
  return (
    <ComponentShowPasswordDialog
      title={title}
      text={text}
      open={open}
      onClose={onCancel}
    />
  );
}
