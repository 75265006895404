import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, #root': {
          height: '100%',
          scrollBehavior: 'smooth',
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 400,
      fontSize: '3rem',
      color: '#000',
    },
  },
});

export default theme;
