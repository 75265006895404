import React, { useState } from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SendIcon from "@mui/icons-material/Send";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ConfirmationDialog from "../../pages/layout/ConfirmationDialog";
import ChangePasswordDialog from "../../pages/layout/ChangePasswordDialog";
import { Users as UsersApi } from "../../api";

interface Props {
  userId: string;
  updateTable?: () => void;
}

export default function UserAdminOptions({
  userId,
  updateTable = () => {},
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedUserId, setSelectedUserId] = useState("");

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    elementId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(elementId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [newPasswordConfirmationDialog, setNewPasswordConfirmationDialog] =
    useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [deleteUserConfirmationDialog, setDeleteUserConfirmationDialog] =
    useState(false);

  return (
    <>
      <Tooltip title={"Show GA options "}>
        <IconButton
          aria-label="more-ga-options"
          color="default"
          onClick={(event) => handleClick(event, userId)}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 1px 1px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          onClick={() => {
            setNewPasswordConfirmationDialog(true);
          }}
        >
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          Send new password
        </MenuItem>
        <MenuItem
          onClick={() => {
            setChangePasswordDialog(true);
          }}
        >
          <ListItemIcon>
            <AutorenewIcon fontSize="small" />
          </ListItemIcon>
          Change password
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteUserConfirmationDialog(true);
          }}
        >
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          Delete user
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={newPasswordConfirmationDialog}
        title="Confirmation"
        text="Are you sure you want to continue? Submitting a new password cannot be reversed."
        onConfirm={async () => {
          await UsersApi.resetPassword(selectedUserId);
          setSelectedUserId("");
          setNewPasswordConfirmationDialog(false);
        }}
        onCancel={() => {
          setSelectedUserId("");
          setNewPasswordConfirmationDialog(false);
        }}
      />
      <ChangePasswordDialog
        open={changePasswordDialog}
        onConfirm={async (password: string) => {
          await UsersApi.changePassword(selectedUserId, password);
          setSelectedUserId("");
          setChangePasswordDialog(false);
        }}
        onCancel={() => {
          setSelectedUserId("");
          setChangePasswordDialog(false);
        }}
      />
      <ConfirmationDialog
        open={deleteUserConfirmationDialog}
        title="Confirmation"
        text="Are you sure you want to continue? Deleting a user cannot be reversed and the user will be removed from all tenants."
        onConfirm={async () => {
          await UsersApi.deleteGA(selectedUserId);
          updateTable();
          setSelectedUserId("");
          setDeleteUserConfirmationDialog(false);
        }}
        onCancel={() => {
          setSelectedUserId("");
          setDeleteUserConfirmationDialog(false);
        }}
      />
    </>
  );
}
