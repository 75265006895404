import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'

const Content = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  paddingTop: '0px'
}));

export default function FilterDevicesDialog(props: any) {
  const { locations, locationFilter, open, onClose, onSave } = props;

  useEffect(() => {
    setSelectedLocation(locationFilter)
  }, [locationFilter])


  const [selectedLocation, setSelectedLocation] = useState("all")

  return (
    <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle>Devices Filter</DialogTitle>
      <form onSubmit={(e) => onSave({ event: e, location: selectedLocation })}>
        <Content>
          <FormControl>
            <FormLabel id="locations-radio-buttons-group-label">Locations</FormLabel>
            <RadioGroup
              aria-labelledby="locations-radio-buttons-group-label"
              name="location"
              value={selectedLocation}
              onChange={(e: any) => setSelectedLocation(e.target.value)}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              {locations.map((location: any) => (
                <FormControlLabel key={location.id} value={location.id} control={<Radio />} label={location.name} />
              ))}
            </RadioGroup>
          </FormControl>

        </Content>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Filter</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
