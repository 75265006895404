import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Button, Switch, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import CustomTable from '../../components/table/Table';
import Tabs from '../../components/tabs/Tabs';
import { Datagroups as DatagroupsApi, Devices as DevicesApi, DatagroupDeviceRefs as DatagroupDeviceRefsApi } from '../../api';

import AddSourceDialog from './AddSourceDialog';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import DatagroupSettings from './DatagroupSettings';

export default function Datagroup() {

  const { datagroup: datagroupSlug } = useParams();
  const { tenant: tenantSlug } = useParams();
  const { location: locationSlug } = useParams();
  const { area: areaSlug } = useParams();

  const [datagroup, setDatagroup] = useState({
    id: "",
    name: "",
    metric: "",
    datasources: [],
    tags: [],
    settings: {},
    linked_id: "",
    createdAt: 0,
    updatedAt: 0
  });
  const [devices, setDevices] = useState<any[]>([]);
  const [datagroups, setDatagroups] = useState<any[]>([]);
  const [attatchedSources, setAttatchedSources] = useState<any[]>([]);
  const [addDialog, setAddDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});

  const [notFound, setNotFound] = useState(false);

  const getDatagroup = async (tenantId: any, datagroupId: any) => {

    try {
      const datagroupResponse = await DatagroupsApi.get(tenantId, datagroupId);
      if (!datagroupResponse.data) {
        setNotFound(true);
        return;
      }

      if (!datagroupResponse.data.datasources.length) {
        setAddDialog(true);
      }

      setDatagroup({ settings: {}, ...datagroupResponse.data });

      setAttatchedSources(
        datagroupResponse.data.datasources.map((d: any) => ({
          Name: `${d.name}`,
          Active: (d.ref_type === "device" ? <Switch disabled checked={!d.disabled} /> : "n/a"),
          'Sub Area': (d.ref_type === "device" ? d.sub_area : "n/a"),
          Type: d.ref_type,
          key: d.ref,
        }))
      );
    } catch (error) {
      console.log(error);
      setNotFound(true);
    }
  };

  const saveCreate = async (data: any) => {
    const { device, subArea, datagroup } = data;

    const type = device ? 'device' : 'datagroup';

    const sourceId = device || datagroup;
    try {
      await DatagroupDeviceRefsApi.create(tenantSlug, datagroupSlug, sourceId, type, subArea)
      await getDatagroup(tenantSlug, datagroupSlug);
      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveDelete = async () => {
    try {
      await DatagroupDeviceRefsApi.delete(
        tenantSlug,
        selected.ref,
      );
      await getDatagroup(tenantSlug, datagroupSlug);
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const device = datagroup.datasources.find((d: any) => d.ref === id);
    if (!device) return;
    setSelected(device);
    setDeleteDialog(true);
  }

  useEffect(() => {
    DevicesApi.getAll(tenantSlug).then(response => {
      const devicesResults = response.data.map((d: any) => ({
        Name: `${d.name}`,
        "Serial Number": `${d.serial_number}`,
        Heatmap: d.heatmap,
        Disabled: d.disabled,
        Active: <Switch disabled checked={!d.disabled} />,
        Type: d.type,
        sub_areas: d.sub_areas,
        key: d.id,
      })).sort((a: any, b: any) => a.Name.localeCompare(b.Name))
      setDevices(devicesResults);
      getDatagroup(tenantSlug, datagroupSlug);
    }).catch(error => {
      console.log(error);
    })

    if (!areaSlug && !locationSlug) return;

    DatagroupsApi.getAll(tenantSlug).then(res => {
      const datagroupResults = res.data.filter((d: any) => {
        if (areaSlug) return d.linked_id === locationSlug || d.linked_id === tenantSlug;
        return d.linked_id === tenantSlug;
      }).map((d: any) => {
        return {
          key: d.id,
          Name: d.name
        }
      }).sort((a: any, b: any) => a.Name.localeCompare(b.Name))
      setDatagroups(datagroupResults);
    })
  }, [datagroupSlug, tenantSlug, areaSlug, locationSlug])

  const refreshForm = () => {
    getDatagroup(tenantSlug, datagroupSlug);
  }

  const headers = ['Name', 'Type', 'Sub Area', 'Active'];

  const tabs = [
    {
      label: `Sources (${attatchedSources.length})`,
      Component: (
        <div>
          <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}>Add Data Source</Button>
          </Toolbar >
          <CustomTable
            name="Devices"
            headers={headers}
            data={attatchedSources}
            viewButton={false}
            editButton={false}
            // editItem={(id: string) => openAndSelectEditDevideRef(id)}
            deleteButton={true}
            deleteItem={(id: string) => openAndSelectDelete(id)}
          />
        </div>
      )
    },
    {
      label: "Settings",
      Component: (
        <DatagroupSettings datagroup={datagroup} refreshForm={() => refreshForm()} />
      )
    }
  ];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>{datagroup.name}</Typography>
      </Toolbar>
      {/* <Chip label="Data Group" sx={{ backgroundColor: 'error.main', color: '#ffffff' }} /> */}
      <Tabs tabs={tabs} />
      <DeleteItemDialog title={`Remove source from ${datagroup.name || ''}`} text={`Are you sure you want to delete this source from this datagroup? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
      <AddSourceDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={saveCreate} devices={devices} datagroups={datagroups} attatchedSources={attatchedSources} />
    </div>
  )
}
