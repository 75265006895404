import axios from "axios";
import { store } from "./app/store";
import { setTokens } from "./pages/auth/authSlice";

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const PERMISSIONS_URL = process.env.REACT_APP_PERMISSIONS_URL;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const client_id = process.env.REACT_APP_CLIENT_ID;
    const client_url = process.env.REACT_APP_CLIENT_URL;
    const API_URL = process.env.REACT_APP_API_ROOT;

    if (error.config.url === `${API_URL}/auth/refresh`) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      window.location.href = `${AUTH_URL}/oauth/authorize?grant_type=authorization_code&response_type=code&client_id=${client_id}&redirect_uri=${client_url}/oauth/callback&state=myState&scope=auth tenantsinfo userinfo`;
      return;
    }

    if (status === 401) {
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        window.location.href = `${AUTH_URL}/oauth/authorize?grant_type=authorization_code&response_type=code&client_id=${client_id}&redirect_uri=${client_url}/oauth/callback&state=myState&scope=auth tenantsinfo userinfo`;
        return;
      }

      try {
        const token = await axios.post(`${API_ROOT}/auth/refresh`, {
          code: refresh_token,
        });
        console.log("Token Refreshed");
        console.log(token);
        localStorage.setItem("refresh_token", token.data.refresh_token);
        store.dispatch(
          setTokens({
            access_token: token.data.access_token,
            refresh_token: token.data.refresh_token,
            email: token.data.email,
            isGA: token.data.isGA,
          })
        );
        localStorage.setItem("access_token", token.data.access_token);

        error.config.headers[
          "Authorization"
        ] = `Bearer ${token.data.access_token}`;
        const request = await axios.request(error.config);
        return Promise.resolve(request);
      } catch (error) {
        console.log(error);
      }
    }

    if (status === 403) {
      window.location.href = `${client_url}`;
      return;
    }

    return Promise.reject(error);
  }
);

const request = () => {
  const token = localStorage.getItem("access_token");
  return {
    get: {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {},
    },
    post: {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    patch: {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    put: {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    delete: {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  };
};

export const Auth = {
  post: (authorizationCode: any) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/auth`,
      data: {
        code: authorizationCode,
      },
    }),
  get: (Authorization: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/auth/validate`,
      headers: { Authorization: `Bearer ${Authorization}` },
    }),
};

export const Tenants = {
  create: (name: any, description: any, image: any) =>
    axios({
      ...request().post,
      url: `${AUTH_URL}/tenants`,
      data: {
        name: name,
        // description: description,
        photoUrl: image,
      },
    }),
  getAll: () =>
    axios({
      ...request().get,
      url: `${API_ROOT}/tenants`,
    }),
  get: (tenant: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/tenants/${tenant}`,
    }),
  update: (tenant: any, name: any, description: any) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/tenants/${tenant}`,
      data: {
        name: name,
        description: description,
      },
    }),
  delete: (tenant: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/tenants/${tenant}`,
    }),
};

export const BucketUrl = {
  get: (ext?: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/bucketurl`,
      params: {
        ext,
      },
    }),
  upload: (url: any, image: any) =>
    axios({
      ...request().put,
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: { image },
    }),
};

export const Locations = {
  create: (
    name: any,
    kafka_alert_alias: any,
    latitude: any,
    longitude: any,
    tenant_id: any
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/locations`,
      data: {
        name,
        kafka_alert_alias,
        latitude,
        longitude,
        tenant_id,
      },
    }),
  getAll: (tenant_id: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/locations`,
    }),
  get: (tenant_id: any, location: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/locations/${location}`,
    }),
  update: (
    tenant_id: string,
    location: any,
    name: any,
    latitude: any,
    longitude: any,
    kafka_alert_alias: any
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/locations/${location}`,
      data: {
        name: name,
        latitude: latitude,
        longitude: longitude,
        kafka_alert_alias: kafka_alert_alias,
      },
    }),
  delete: (tenant_id: string, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/locations/${id}`,
    }),
};

export const Areas = {
  create: (name: any, description: any, tenant_id: string, location: any) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/locations/${location}/areas`,
      data: {
        name,
        description,
        heatmaps: [],
      },
    }),
  getAll: (tenant_id: string, location_id: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/locations/${location_id}/areas`,
    }),
  get: (tenant_id: any, location: any, area: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/locations/${location}/areas/${area}`,
    }),
  update: (
    tenant_id: string,
    location: any,
    area: any,
    name: any,
    description: any,
    device: any[]
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/locations/${location}/areas/${area}`,
      data: {
        name: name,
        description: description,
        heatmaps: [...device],
      },
    }),
  delete: (id: string, tenant_id: string, location: any) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/locations/${location}/areas/${id}`,
    }),
};

export const Devices = {
  create: (
    tenant_id: any,
    tenant_name: string,
    name: string,
    serial_number: string,
    type: string,
    heatmap: boolean,
    sub_areas: string[],
    metrics: string[],
    model: string,
    location: string,
    area: string,
    associatedCamera: string,
    latitude: string,
    longitude: string,
    isV2: boolean,
    apikey: string,
    disabled: boolean
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/devices`,
      data: {
        tenant_name,
        name,
        serial_number,
        type,
        heatmap,
        sub_areas,
        metrics,
        model,
        location,
        area,
        associated_camera: associatedCamera || undefined,
        latitude,
        longitude,
        isV2: isV2 || undefined,
        apikey,
        disabled,
      },
    }),
  getAll: (tenant_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/devices`,
    }),
  get: (tenant_id: any, device: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/devices/${device}`,
    }),
  patch: (
    tenant_id: any,
    id: string,
    tenant_name: string,
    name: string,
    serial_number: string,
    type: string,
    heatmap: boolean,
    metrics: string[],
    model: string,
    location: string,
    area: string,
    associatedCamera: string,
    latitude: string,
    longitude: string,
    isV2: boolean,
    apikey: string,
    disabled: boolean,
    sub_areas: String[]
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/devices/${id}`,
      data: {
        tenant_name,
        name,
        serial_number,
        type,
        heatmap,
        metrics,
        model,
        location,
        area,
        associated_camera: associatedCamera || undefined,
        latitude,
        longitude,
        isV2: isV2,
        apikey,
        disabled,
        sub_areas,
      },
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/devices/${id}`,
    }),
};

export const Datagroups = {
  create: (
    tenant_id: any,
    linked_id: string,
    name: string,
    metric: string,
    tags: string[],
    formula: string,
    limit1: string,
    limit1Color: string,
    limit2: string,
    limit2Color: string,
    limit3: string,
    limit3Color: string
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/datagroups`,
      data: {
        name,
        metric,
        tags,
        linked_id,
        settings: {
          formula,
          limit_1: limit1,
          limit_1_color: limit1Color,
          limit_2: limit2,
          limit_2_color: limit2Color,
          limit_3: limit3,
          limit_3_color: limit3Color,
        },
      },
    }),
  getAll: (tenant_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/datagroups`,
    }),
  getAllByLinkedId: (tenant_id: any, id: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/datagroups`,
      params: {
        linked_id: id,
      },
    }),
  get: (tenant_id: any, datagroup: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/datagroups/${datagroup}`,
    }),
  patch: (
    tenant_id: any,
    id: any,
    name: string,
    metric: string,
    tags: string[],
    formula: string,
    limit1: string,
    limit1Color: string,
    limit2: string,
    limit2Color: string,
    limit3: string,
    limit3Color: string,
    hidden: boolean
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/datagroups/${id}`,
      data: {
        id,
        name,
        metric,
        tags,
        settings: {
          formula,
          limit_1: limit1,
          limit_1_color: limit1Color,
          limit_2: limit2,
          limit_2_color: limit2Color,
          limit_3: limit3,
          limit_3_color: limit3Color,
          hidden,
        },
      },
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/datagroups/${id}`,
    }),
};

export const Usecases = {
  create: (
    tenant_id: any,
    display_type: string,
    name: string,
    sub_title: string,
    camera_feed: string,
    camera_feeds: string[],
    camera_feed_sub_area: string,
    camera_stream_url: string,
    data_source: string,
    data_sources: string[],
    data_source_metric: string,
    unit_of_measurement: string,
    time_range: number[],
    main_tile_options: string[],
    sub_tile_options: string[],
    graph_interval: String,
    compare_to: string,
    display_last_updated: boolean,
    display_notifications: boolean,
    time_intervals: string[],
    tags: string[],
    disabled: boolean,
    display_position: string,
    limit1: string,
    limit1Color: string,
    limit2: string,
    limit2Color: string,
    limit3: string,
    limit3Color: string,
    fallbackColor: string,
    secondaryLimit1: string,
    secondaryLimit2: string,
    secondaryLimit3: string,
    linked_id: string
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/usecases`,
      data: {
        display_type,
        name,
        sub_title,
        camera_feed,
        camera_feeds,
        camera_feed_sub_area,
        camera_stream_url,
        data_source,
        data_sources,
        data_source_metric,
        unit_of_measurement,
        time_range,
        main_tile_options,
        sub_tile_options,
        graph_interval,
        compare_to,
        display_last_updated,
        display_notifications,
        time_intervals,
        tags,
        disabled,
        linked_id,
        limit_1: limit1,
        limit_1_color: limit1Color,
        limit_2: limit2,
        limit_2_color: limit2Color,
        limit_3: limit3,
        limit_3_color: limit3Color,
        limit_fallback_color: fallbackColor,
        secondary_limit_1: secondaryLimit1,
        secondary_limit_2: secondaryLimit2,
        secondary_limit_3: secondaryLimit3,
        display_position,
      },
    }),
  getAll: (tenant_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/usecases`,
    }),
  getAllByLinkedId: (tenant_id: any, id: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/usecases`,
      params: {
        linked_id: id,
      },
    }),
  get: (tenant_id: any, usecase: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/usecases/${usecase}`,
    }),
  patch: (
    tenant_id: any,
    id: any,
    display_type?: string,
    name?: string,
    sub_title?: string,
    camera_feed?: string,
    camera_feeds?: string[],
    camera_feed_sub_area?: string,
    camera_stream_url?: string,
    data_source?: string,
    data_sources?: string[],
    data_source_metric?: string,
    unit_of_measurement?: string,
    time_range?: number[],
    main_tile_options?: string[],
    sub_tile_options?: string[],
    graph_interval?: string,
    compare_to?: string,
    display_last_updated?: boolean,
    display_notifications?: boolean,
    time_intervals?: string[],
    tags?: string[],
    disabled?: boolean,
    limit1?: string,
    limit1Color?: string,
    limit2?: string,
    limit2Color?: string,
    limit3?: string,
    limit3Color?: string,
    fallbackColor?: string,
    secondaryLimit1?: string,
    secondaryLimit2?: string,
    secondaryLimit3?: string,
    display_position?: string
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/usecases/${id}`,
      data: {
        id,
        display_type,
        name,
        sub_title,
        camera_feed,
        camera_feeds,
        camera_feed_sub_area,
        camera_stream_url,
        data_source,
        data_sources,
        data_source_metric,
        unit_of_measurement,
        time_range,
        main_tile_options,
        sub_tile_options,
        graph_interval,
        compare_to,
        display_last_updated,
        display_notifications,
        time_intervals,
        tags,
        disabled,
        limit_1: limit1,
        limit_1_color: limit1Color,
        limit_2: limit2,
        limit_2_color: limit2Color,
        limit_3: limit3,
        limit_3_color: limit3Color,
        limit_fallback_color: fallbackColor,
        secondary_limit_1: secondaryLimit1,
        secondary_limit_2: secondaryLimit2,
        secondary_limit_3: secondaryLimit3,
        display_position,
      },
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/usecases/${id}`,
    }),
};

export const Widgets = {
  create: (
    tenant_id: any,
    display_position: string,
    name: string,
    type: string,
    data_source: string,
    data_sources: string[],
    comparison: boolean,
    featuredCard: string,
    cameraFeed: string,
    cameraFeedSubArea: string,
    cameraFeedMetric: string,
    tiles: string[],
    layout: string,
    graphOption: string,
    textBox: object | null,
    limit1: string,
    limit1Color: string,
    limit2: string,
    limit2Color: string,
    limit3: string,
    limit3Color: string,
    fallbackColor: string,
    usecase_id: any
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/widgets`,
      data: {
        display_position,
        name,
        type,
        usecase_id,
        data_source,
        data_sources,
        comparison,
        featured_card: featuredCard,
        camera_feed: cameraFeed,
        camera_feed_sub_area: cameraFeedSubArea,
        cameraFeedMetric,
        tiles,
        layout,
        graph_option: graphOption,
        text_box: textBox,
        limit_1: limit1,
        limit_1_color: limit1Color,
        limit_2: limit2,
        limit_2_color: limit2Color,
        limit_3: limit3,
        limit_3_color: limit3Color,
        limit_fallback_color: fallbackColor,
      },
    }),
  getAll: (tenant_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/widgets`,
    }),
  getByUsecaseId: (tenant_id: any, usecase_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/widgets`,
      params: {
        usecase_id: usecase_id,
      },
    }),
  get: (tenant_id: any, id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/widgets/${id}`,
    }),
  patch: (
    tenant_id: any,
    id: any,
    display_position?: string,
    name?: string,
    type?: string,
    data_source?: string,
    data_sources?: string[],
    comparison?: boolean,
    featuredCard?: string,
    cameraFeed?: string,
    cameraFeedSubArea?: string,
    cameraFeedMetric?: string,
    tiles?: string[],
    layout?: string,
    graphOption?: string,
    textBox?: object | null,
    limit1?: string,
    limit1Color?: string,
    limit2?: string,
    limit2Color?: string,
    limit3?: string,
    limit3Color?: string,
    fallbackColor?: string
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/widgets/${id}`,
      data: {
        id,
        display_position,
        name,
        type,
        data_source,
        data_sources,
        comparison,
        featured_card: featuredCard,
        camera_feed: cameraFeed,
        camera_feed_sub_area: cameraFeedSubArea,
        camera_feed_metric: cameraFeedMetric,
        tiles,
        layout,
        graph_option: graphOption,
        text_box: textBox,
        limit_1: limit1,
        limit_1_color: limit1Color,
        limit_2: limit2,
        limit_2_color: limit2Color,
        limit_3: limit3,
        limit_3_color: limit3Color,
        limit_fallback_color: fallbackColor,
      },
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/widgets/${id}`,
    }),
};

export const ApiKeys = {
  create: (tenant_id: any, name: any, service: any, endpoint: any, key: any) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/apikeys`,
      data: {
        name,
        service,
        endpoint,
        key,
      },
    }),
  getAll: (tenant_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/apikeys`,
    }),
  patch: (
    tenant_id: any,
    id: string,
    name: any,
    service: any,
    endpoint: any,
    key: any
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/apikeys/${id}`,
      data: {
        name,
        service,
        endpoint,
        key,
      },
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/apikeys/${id}`,
    }),
};

export const DatagroupDeviceRefs = {
  create: (
    tenant_id: any,
    datagroup_id: any,
    device_id: any,
    type: any,
    sub_area: any
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/datagroupdeviceref`,
      data: {
        datagroup_id,
        device_id,
        type,
        sub_area,
      },
    }),
  patch: (tenant_id: any, id: string, sub_area: any) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/datagroupdeviceref/${id}`,
      data: {
        sub_area,
      },
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/datagroupdeviceref/${id}`,
    }),
};

export const Users = {
  create: (
    email: string,
    tpa: boolean,
    tenant_id: string,
    dontSendEmail: boolean,
    returnPassword: boolean,
    forceChangePassword: boolean,
    password: string
  ) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/users`,
      data: {
        email,
        tpa,
        dontSendEmail,
        returnPassword,
        forceChangePassword,
        password,
      },
    }),
  getAll: (tenant_id: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/users`,
    }),
  updateTenantUser: (
    tenant_id: string,
    user_id: string,
    tpa: boolean,
    tca: boolean,
    forceChangePassword: boolean
  ) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/${tenant_id}/users/${user_id}`,
      data: {
        tpa,
        tca,
        forceChangePassword,
      },
    }),
  resetPassword: (user_id: string) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/users/${user_id}/reset-password`,
      data: {},
    }),
  changePassword: (user_id: string, password: string) =>
    axios({
      ...request().patch,
      url: `${API_ROOT}/users/${user_id}/change-password`,
      data: { password },
    }),
  delete: (tenant_id: any, user_id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/users/${user_id}`,
    }),
  deleteGA: (user_id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/users/${user_id}`,
    }),
};

export const Permissions = {
  getUser: (tenant_id: string, user_id: string) =>
    axios({
      ...request().get,
      url: `${PERMISSIONS_URL}/tenants/${tenant_id}/user/${user_id}/permission`,
    }),
  create: (tenant_id: string, user_id: string, locations: any) =>
    axios({
      ...request().post,
      url: `${PERMISSIONS_URL}/tenants/${tenant_id}/user/${user_id}/permission`,
      data: {
        locations,
      },
    }),
  update: (tenant_id: string, user_id: string, id: string, locations: any) =>
    axios({
      ...request().put,
      url: `${PERMISSIONS_URL}/tenants/${tenant_id}/user/${user_id}/permission`,
      data: {
        id,
        user: user_id,
        locations,
      },
    }),
};

export const UserLogs = {
  get: (tenant_id: string, user_id: string) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/logs/${user_id}`,
    }),
};

export const Settings = {
  create: (tenant_id: any, type: any, key: any, value: any) =>
    axios({
      ...request().post,
      url: `${API_ROOT}/${tenant_id}/settings`,
      data: {
        type,
        key,
        value,
      },
    }),
  getAll: (tenant_id: any) =>
    axios({
      ...request().get,
      url: `${API_ROOT}/${tenant_id}/settings`,
    }),
  delete: (tenant_id: any, id: string) =>
    axios({
      ...request().delete,
      url: `${API_ROOT}/${tenant_id}/settings/${id}`,
    }),
};
