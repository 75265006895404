import React, { useEffect, useState } from 'react'
import { Button, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/table/Table';
import Tabs from '../../components/tabs/Tabs';
import { Tenants as TenantsApi, Locations as LocationsApi } from '../../api';
import EditTenantDialog from './EditTenantDialog';
import CreateLocationDialog from './CreateLocationDialog';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import Datagroups from '../../components/datagroups/Datagroups';
import Usecases from '../../components/usecases/Usecases';
import { updateTenant } from '../../tenantsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getLocations } from './locationsSlice';

export default function Tenant() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);
  const [tenantIndex, setTenantIndex] = useState<number | null>(null);

  useEffect(() => {
    if (tenants.status !== 'idle') return;
    const index = tenants.data.findIndex((t) => t.id === tenants.selected)
    if (index === -1 && tenants.selected) {
      return navigate('/');
    }
    setTenantIndex(index);
  }, [tenants.data, tenants.selected, tenants.status, navigate]);

  const [addDialog, setAddDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});
  const [datagroupCount, setDatagroupCount] = useState(0);
  const [usecaseCount, setUsecaseCount] = useState(0);
  const [datagroupsData, setDatagroupsData] = useState([]);

  const [error, setError] = useState('');

  const receiveDataGroups = (data: any) => {
    setDatagroupsData(data)
  }

  const saveCreate = async (data: any) => {
    if (!tenants.selected) return;

    try {
      await LocationsApi.create(data.name, data.kafka_alert_alias, data.latitude, data.longitude, tenants.selected)
      dispatch(getLocations(tenants.selected))
      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveEdit = async (data: any) => {
    try {
      const updates = await TenantsApi.update(
        tenants.selected,
        data.name,
        data.description,
      );
      dispatch(updateTenant({ index: tenantIndex, tenant: updates.data }));
      setEditDialog(false);
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  const saveDelete = async () => {
    if (!tenants.selected) return;

    try {
      await LocationsApi.delete(tenants.selected, selected.id)
      dispatch(getLocations(tenants.selected))
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const location = locations.data.find((l) => l.id === id);
    if (!location) return;
    setSelected(location);
    setDeleteDialog(true);
  }

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])

  const locationHeaders = ['Name'];

  const tabs = [
    {
      label: `Locations (${locations.data.length})`,
      Component: (
        <div>
          <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}> New Location</Button>
          </Toolbar >
          <CustomTable
            name="Locations"
            data={locations.data.map((d: any) => ({
              Name: `${d.name}`,
              key: d.id,
            }))}
            headers={locationHeaders}
            viewButton={true}
            editButton={false}
            deleteButton={true}
            deleteItem={(id: string) => openAndSelectDelete(id)}
          />
        </div>
      )
    },
    {
      label: `Use Cases (${usecaseCount})`,
      Component: (
        // <Typography>Use cases</Typography>
        <Usecases id={tenants.selected || ''} datagroups={datagroupsData} count={(c: number) => setUsecaseCount(c)} />
      )
    },
    {
      label: `Data Groups (${datagroupCount})`,
      Component: (
        <Datagroups id={tenants.selected || ''} datagroupsData={(data: any) => receiveDataGroups(data)} count={(c: number) => setDatagroupCount(c)} />
      )
    }
  ];
  if (tenantIndex === null) {
    return <div>Loading...</div>
  }
  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>{tenants.data[tenantIndex].name}</Typography>
        {/* <Button variant='contained' color='primary' startIcon={<EditIcon />} onClick={() => setEditDialog(true)}> Edit</Button> */}
      </Toolbar>
      {/* <Chip label="Tenant" sx={{ backgroundColor: 'primary.dark', color: '#ffffff' }} /> */}
      <p>{tenants.data[tenantIndex].description}</p>
      <Tabs tabs={tabs} />
      <EditTenantDialog open={editDialog} tenant={tenants.data[tenantIndex]} onSave={(data: any) => saveEdit(data)} onClose={() => setEditDialog(false)} />
      <CreateLocationDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} />
      <DeleteItemDialog title={`Delete ${selected?.name || ''} Location`} text={`Are you sure you want to delete this Location? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
    </div >
  )
}


