import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setSelected } from '../../tenantsSlice';
import { getDevices, resetDevices } from '../devices/devicesSlice';
import { getLocations, resetLocations } from './locationsSlice';
import { getUsers, resetUsers } from '../users/usersSlice';
import { getSettings, resetSettings } from '../settings/settingsSlice';

export default function Wrapper() {
  const dispatch = useAppDispatch();
  const { tenant } = useParams();
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);

  useEffect(() => {
    if (tenants.status !== 'idle') return;
    const index = tenants.data.findIndex((t) => t.id === tenants.selected)
    if (index === -1 && tenants.selected) {
      return navigate('/');
    }
  }, [tenants.data, tenants.selected, tenants.status, navigate]);

  useEffect(() => {
    if (!tenants.selected && tenants.data.length) {
      dispatch(setSelected(tenant));
    }
  }, [tenant, dispatch, tenants.selected, tenants.data.length]);

  useEffect(() => {
    if (!tenants.selected) return;

    dispatch(getLocations(tenants.selected));
    dispatch(getDevices(tenants.selected));
    dispatch(getUsers(tenants.selected));
    dispatch(getSettings(tenants.selected));

    return () => {
      dispatch(resetLocations());
      dispatch(resetDevices());
      dispatch(resetUsers());
      dispatch(resetSettings());
    }
  }, [tenants.selected, dispatch])

  return (
    <Outlet />
  )
}
