import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EditItemDialog from '../../components/edit-item-dialog/EditItemDialog';

interface IEditProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  tenant: any;
}

export default function EditTenantDialog({ open, onSave, onClose, tenant }: IEditProps) {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (open) {
      setName(tenant.name);
      setDescription(tenant.description);
    }
    return () => {
      setName("");
      setDescription("");
    }
  }, [open, tenant])

  const formElements = <>
    <TextField label="Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <TextField label="Description" multiline fullWidth rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, description })
  }

  return (
    <EditItemDialog
      title={`Edit ${tenant?.name || ''} Tenant`}
      text={`Edit the details of this Tenant`}
      FormElements={formElements}
      open={open}
      onClose={onClose}
      onSave={(e: any) => save(e)}
    />
  )
}
