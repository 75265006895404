import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import ApiKeys from './pages/api-keys/ApiKeys';
import Devices from './pages/devices/Devices';
import Device from './pages/devices/Device';
import Layout from './pages/layout/Layout';
import Tenant from './pages/tenant/Tenant';
import Location from './pages/location/Location';
import Area from './pages/area/Area';
import Datagroup from './pages/datagroup/Datagroup';
import Usecase from './pages/usecase/Usecase';
import Users from './pages/users/Users';
import User from './pages/users/User';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { getTenants } from './tenantsSlice';
import Home from './pages/home/Home';
import Login from './pages/auth/Login';
import Callback from './pages/auth/Callback';
import Error from './pages/auth/Error';
import Wrapper from './pages/tenant/Wrapper';
import LocationWrapper from './pages/location/Wrapper';
import AreaWrapper from './pages/area/Wrapper';
import { setTokens } from './pages/auth/authSlice';
import { Auth, AUTH_URL } from './api';
import Settings from './pages/settings/Settings';

function App() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get("code")
  const auth = useAppSelector((s) => s.auth);

  useEffect(() => {
    if (!auth.data.access_token || !auth.data.refresh_token) return;
    dispatch(getTenants());
  }, [dispatch, auth.data.access_token, auth.data.refresh_token])

  useEffect(() => {
    if (authorizationCode) return;
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");

    if (access_token && refresh_token) {
      Auth.get(access_token).then(res => {
        dispatch(setTokens({ access_token, refresh_token, email: res.data.email, isGA: res.data.isGA }));
      })
      return;
    }

    const client_id = process.env.REACT_APP_CLIENT_ID;
    const client_url = process.env.REACT_APP_CLIENT_URL;

    window.location.href = `${AUTH_URL}/oauth/authorize?grant_type=authorization_code&response_type=code&client_id=${client_id}&redirect_uri=${client_url}/oauth/callback&state=myState&scope=auth tenantsinfo userinfo`;
  }, [dispatch, authorizationCode])

  return (
    <Layout>
      <Routes>
        <Route path="*" element={<h1>Page not found</h1>} />
        {/* <Route
          path="/"
          element={<Navigate to="/tenants" replace />}
        /> */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/oauth/callback" element={<Callback />} />
        <Route path="/oauth/error" element={<Error />} />

        <Route path=":tenant" element={<Wrapper />}>
          <Route index element={<Tenant />} />
          <Route path="api-keys" element={<ApiKeys />} />
          <Route path="settings" element={<Settings />} />
          <Route path="devices" element={<Devices />} />
          <Route path="devices/:device" element={<Device />} />
          <Route path="users" >
            <Route index element={<Users />} />
            <Route path=":user" element={<User />} />
          </Route>
          <Route path="datagroups" element={<Navigate to="../" replace />} />
          <Route path="datagroups/:datagroup" element={<Datagroup />} />
          <Route path="usecases" element={<Navigate to="../" replace />} />
          <Route path="usecases/:usecase" element={<Usecase />} />
          <Route path=":location" element={<LocationWrapper />}>
            <Route index element={<Location />} />
            <Route path="datagroups" element={<Navigate to="../" replace />} />
            <Route path="datagroups/:datagroup" element={<Datagroup />} />
            <Route path="usecases" element={<Navigate to="../" replace />} />
            <Route path="usecases/:usecase" element={<Usecase />} />
            <Route path=":area" element={<AreaWrapper />}>
              <Route index element={<Area />} />
              <Route path="datagroups" element={<Navigate to="../" replace />} />
              <Route path="datagroups/:datagroup" element={<Datagroup />} />
              <Route path="usecases" element={<Navigate to="../" replace />} />
              <Route path="usecases/:usecase" element={<Usecase />} />
            </Route>
          </Route>
        </Route>
        {/* <Route path="tenants/:tenant" element={<Tenant />} />
        <Route path="tenants/:tenant/datagroups/:datagroup" element={<Datagroup />} />
        <Route path="tenants/:tenant/:location" element={<Location />} />
        <Route path="tenants/:tenant/:location/datagroups/:datagroup" element={<Datagroup />} />
        <Route path="tenants/:tenant/:location/:area" element={<Area />} />
        <Route path="tenants/:tenant/:location/:area/datagroups/:datagroup" element={<Datagroup />} />
        <Route path="api-keys" element={<ApiKeys />} />
        <Route path="tenants/:tenant/devices" element={<Devices />} />
        <Route path="tenants/:tenant/devices/:device" element={<Device />} />
        <Route path="users" element={<Users />} /> */}
      </Routes>
    </Layout>
  );
}

export default App;
