import { TextField, Button } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import React, { useEffect, useState } from "react";
import NewItemDialog from "../../components/new-item-dialog/NewItemDialog";

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

export default function CreateTenantDialog({
  open,
  onSave,
  onClose,
}: ICreateProps) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<any>("");
  const [filePreview, setPreviewFile] = useState<any>("");

  const handleFileChange = async ({ target }: any) => {
    const file = target.files![0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      //setImage(reader.result);
      setImage(file);
    };
    setPreviewFile(target.files![0].name);
  };

  useEffect(() => {
    if (!open) {
      setName("");
      setDescription("");
      setImage("");
      setPreviewFile("");
    }
  }, [open]);

  const formElements = (
    <>
      <TextField
        label="Name"
        required
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Description"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Button
        component="label"
        startIcon={<InsertPhotoIcon />}
        variant="outlined"
      >
        Upload logo
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      {filePreview}
    </>
  );

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, description, image });
  };

  return (
    <NewItemDialog
      title="New Tenant"
      text="Create a new Tenant"
      FormElements={formElements}
      open={open}
      onSave={(e: any) => save(e)}
      onClose={onClose}
    />
  );
}
