import { Box, FormControl, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewItemDialog from "../../components/new-item-dialog/NewItemDialog";

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "3px",
  border: "1px solid #ddd",
}));

export default function CreateThresholdDialog({
  open,
  onSave,
  onClose,
}: ICreateProps) {
  const [name, setName] = useState("");
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("#28a745");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("#ffc107");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("#dc3545");
  const [fallbackColor, setFallbackColor] = useState("#dc3595");

  useEffect(() => {
    if (!open) {
      setName("");
      setLimit1("");
      setLimit1Color("#28a745");
      setLimit2("");
      setLimit2Color("#ffc107");
      setLimit3("");
      setLimit3Color("#dc3545");
      setFallbackColor("#dc3595");
    }
  }, [open]);

  const formElements = (
    <>
      <TextField
        label="Name"
        required
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <SubItem>
        <p>Threshold Limit 1</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Value"
              label="Value"
              value={limit1}
              type="number"
              onChange={(e) => {
                setLimit1(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={limit1Color}
              onChange={(e) => {
                setLimit1Color(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: limit1Color }} />
        </div>
      </SubItem>
      <SubItem>
        <p>Threshold Limit 2</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Value"
              label="Value"
              value={limit2}
              type="number"
              onChange={(e) => {
                setLimit2(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={limit2Color}
              onChange={(e) => {
                setLimit2Color(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: limit2Color }} />
        </div>
      </SubItem>
      <SubItem>
        <p>Threshold Limit 3</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Value"
              label="Value"
              value={limit3}
              type="number"
              onChange={(e) => {
                setLimit3(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={limit3Color}
              onChange={(e) => {
                setLimit3Color(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: limit3Color }} />
        </div>
      </SubItem>
      <SubItem>
        <p>Fallback</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField
              fullWidth
              placeholder="Color"
              label="Color"
              value={fallbackColor}
              onChange={(e) => {
                setFallbackColor(e.target.value);
              }}
            />
          </FormControl>
          <ColorPreview sx={{ backgroundColor: fallbackColor }} />
        </div>
      </SubItem>
    </>
  );

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({
      name,
      limit1,
      limit1Color,
      limit2,
      limit2Color,
      limit3,
      limit3Color,
      fallbackColor,
    });
  };

  return (
    <NewItemDialog
      title="New Threshold Template"
      text="Create a new Threshold Template"
      FormElements={formElements}
      open={open}
      onSave={(e: any) => save(e)}
      onClose={onClose}
    />
  );
}
