import React, { useEffect, useState } from 'react'

import { Button, styled, Switch, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomTable from '../../components/table/Table';
import { Devices as DevicesApi, ApiKeys as ApiKeysApi } from '../../api';
import CreateDeviceDialog from './CreateDeviceDialog';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import { deviceMetrics } from '../../app/config/config';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDevices } from './devicesSlice';
import FilterDevicesDialog from '../../components/filter-devices-dialog/FilterDevicesDialog';
import { useSearchParams } from 'react-router-dom';

const ButtonContainer = styled('div')(({ theme }: { theme: any }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));


export default function Devices() {
  const dispatch = useAppDispatch();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);
  const devices = useAppSelector((s) => s.devices);
  const [searchParams, setSearchParams] = useSearchParams()
  const [apiKeys, setApiKeys] = useState<any[]>([]);
  const [formattedDevices, setFormattedDevices] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>({});
  const [addDialog, setAddDialog] = useState(false)
  const [filterDialog, setFilterDialog] = useState(false);
  const [locationFilter, setLocationFilter] = useState("all");
  const [deleteDialog, setDeleteDialog] = useState(false)

  const saveCreate = async (data: any) => {
    if (!tenants.selected) return;

    const tenantName = tenants.data.find((t) => t.id === tenants.selected)?.name;

    if (!tenantName) return;

    const subAreas = data.subAreas.map((s: any) => {
      return { name: s, formula: "average" }
    });

    const metrics = data.metrics.map((m: string) => {
      const defaultMetric = deviceMetrics.find(({ type }) => type === m)
      if (!defaultMetric) return { type: m, formula: "total" }
      return { type: m, formula: defaultMetric.formula }
    });

    try {
      await DevicesApi.create(tenants.selected, tenantName, data.name, data.serial, data.type, data.heatmap, subAreas, metrics, data.model, data.location, data.area, data.associatedCamera, data.latitude, data.longitude, data.isV2, data.apiKey, data.disabled)
      dispatch(getDevices(tenants.selected))

      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveDelete = async () => {
    if (!tenants.selected) return;
    // do delete API
    try {
      await DevicesApi.delete(tenants.selected, selected.id)
      dispatch(getDevices(tenants.selected))
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const device = devices.data.find(d => d.id === id);
    if (!device) return;

    setSelected(device);
    setDeleteDialog(true);
  }

  const setFilters = ({ event, location }: any) => {
    event.preventDefault();
    setLocationFilter(location);
    setFilterDialog(false);

    const sParams = new URLSearchParams(searchParams)
    if (sParams.get('location') === location) return;

    if (location === "all") {
      setSearchParams({});
      return;
    }

    setSearchParams({ location: location })
  }

  useEffect(() => {
    if (!tenants.selected) return;
    ApiKeysApi.getAll(tenants.selected).then(response => {
      const apiKeysResults = response.data.map((d: any) => ({
        Name: `${d.name}`,
        Service: `${d.service}`,
        Key: `${d.key}`,
        Endpoint: `${d.endpoint}`,
        key: d.id,
      }))
      setApiKeys(apiKeysResults);
    }).catch(error => {
      console.log(error);
    })
  }, [dispatch, tenants.selected])

  useEffect(() => {
    setFormattedDevices(devices.data.filter((d: any) => {
      if (locationFilter === "all") return true;
      return d.location === locationFilter;
    }).map((d: any) => ({
      Name: `${d.name}`,
      "Serial Number": `${d.serial_number}`,
      Type: `${d.type}`,
      Metrics: d.metrics.map((m: any) => m.type).join(' - '),
      Heatmap: <Switch disabled checked={!!d.heatmap} />,
      Disabled: <Switch color='error' disabled checked={!!d.disabled} />,
      "Api Key": apiKeys.find(k => k.key === d.apikey)?.Name || 'None',
      "Location": locations.data.find(l => l.id === d.location)?.name || 'N/A',
      key: d.id,
    })).sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    }).sort((a, b) => {
      if (a.Location < b.Location) {
        return -1;
      }
      if (a.Location > b.Location) {
        return 1;
      }
      return 0;
    }))
  }, [apiKeys, devices.data, locationFilter, locations.data])

  useEffect(() => {
    const sParams = new URLSearchParams(searchParams)
    const setLocation = sParams.get('location');
    if (!setLocation) return;

    setLocationFilter(setLocation)

  }, [searchParams])


  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])

  const headers = ['Name', 'Location', 'Serial Number', 'Type', 'Metrics', 'Api Key', 'Heatmap', 'Disabled'];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>Devices</Typography>
        <ButtonContainer>
          <Button color='primary' startIcon={<FilterListIcon />} onClick={() => setFilterDialog(true)}>Filter</Button>
          <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}> New Device</Button>
        </ButtonContainer>
      </Toolbar>
      <CustomTable
        name="Devices"
        data={formattedDevices}
        headers={headers}
        viewButton={true}
        editButton={false}
        deleteButton={true}
        deleteItem={(id: string) => openAndSelectDelete(id)}
      />
      <CreateDeviceDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} apiKeys={apiKeys} locations={locations.data} />
      <DeleteItemDialog title={`Delete ${selected?.name || ''} Device`} text={`Are you sure you want to delete this Device? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
      <FilterDevicesDialog open={filterDialog} onClose={() => setFilterDialog(false)} onSave={setFilters} locationFilter={locationFilter} locations={locations.data} />
    </div>
  )
}
