import { Autocomplete, Box, Button, Chip, FormControl, Grid, MenuItem, Select, Switch, TextField } from '@mui/material';
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Devices as DevicesApi } from '../../api';
import { deviceMetrics, deviceTypes, iotModels } from '../../app/config/config'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDevices } from './devicesSlice';

interface IEditProps {
  device: any;
  apiKeys: any;
  locations: any;
  locationState: any;
}
const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #c3c3c3'
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));

export default function DeviceSettings({ device, apiKeys, locations, locationState }: IEditProps) {

  const tenants = useAppSelector(s => s.tenants)
  const devices = useAppSelector((s) => s.devices);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [serial_number, setSerialNumber] = useState("");
  const [type, setType] = useState("");
  const [heatmap, setHeatmap] = useState(false);
  const [metrics, setMetrics] = useState<string[]>([]);
  const [model, setModel] = useState("");
  const [isAreaMetric, setIsAreaMetric] = useState(false)
  const [location, setLocation] = useState("");
  const [area, setArea] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isV2, setIsV2] = useState(false);
  const [apikey, setApiKey] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sub_areas, setSubAreas] = useState<string[]>([]);
  const [associatedCamera, setAssociatedCamera] = useState("")

  const [settingsChanged, setSettingsChanged] = useState(false);
  const [error, setError] = useState('');

  const handleMetricsChange = (event: any) => {
    const { target: { value } } = event;

    setMetrics(typeof value === 'string' ? value.split(',') : value)

    const metricContainsArea = value.find((metric: any) => {
      if (metric.includes("area")) {
        return true;
      }
      return false
    });

    metricContainsArea ? setIsAreaMetric(true) : setIsAreaMetric(false);
  }

  const handleSubAreasChange = (e: any, value: any) => {
    setSubAreas(value)
  }

  const saveEdit = async (e: any) => {
    e.preventDefault()
    if (!tenants.selected) return
    const existingMetrics = device.metrics.filter((m: { type: string; }) => metrics.includes(m.type));
    const existingMetricsStrings = device.metrics.map((m: { type: any; }) => m.type);
    const newMetricStrings = metrics.filter(m => !existingMetricsStrings.includes(m))
    const newMetrics = newMetricStrings.map(m => {
      const defaultMetric = deviceMetrics.find(({ type }) => type === m)
      if (!defaultMetric) return { type: m, formula: "total" }
      return { type: m, formula: defaultMetric.formula }
    });
    const savedMetrics = [...existingMetrics, ...newMetrics];


    const existingSubAreas = device.sub_areas.filter((s: { name: string; }) => sub_areas.includes(s.name));
    const existingSubAreasStrings = device.sub_areas.map((s: { name: any; }) => s.name);
    const newSubAreaStrings = sub_areas.filter(s => !existingSubAreasStrings.includes(s))
    const newSubAreas = newSubAreaStrings.map(s => {
      return { name: s, formula: "average" }
    });
    const savedSubAreas = [...existingSubAreas, ...newSubAreas];

    try {
      await DevicesApi.patch(
        tenants.selected,
        device.id,
        tenantName,
        name,
        serial_number,
        type,
        heatmap,
        savedMetrics,
        model,
        location,
        area,
        associatedCamera,
        latitude,
        longitude,
        isV2,
        apikey,
        disabled,
        savedSubAreas,
      );
      setSettingsChanged(false);
      dispatch(getDevices(tenants.selected))
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
    navigate(`/${tenants.selected}/devices${locationState?.prevRoute?.search || ''}`);
  }

  useEffect(() => {
    setTenantName(device.tenant_name || "")
    setName(device.name);
    setSerialNumber(device.serial_number || "");
    setType(device.type);
    setHeatmap(!!device.heatmap);
    setMetrics(device.metrics.map((metric: { type: any; }) => metric.type));
    setModel(device.model || "");
    setIsAreaMetric(device.metrics.some((metric: any) => {
      if (metric.type.includes('area')) {
        return true
      }
      return false
    }));
    setLocation(device.location || "");
    setArea(device.area || "");
    setLatitude(device.latitude || "");
    setLongitude(device.longitude || "");
    setIsV2(device.isV2 || false)
    setAssociatedCamera(device.associated_camera || "");
    setApiKey(device.apikey || "");
    setDisabled(!!device.disabled);
    setSubAreas(device.sub_areas.map((sub_area: { name: any; }) => sub_area.name));
  }, [device])

  return (
    <Box component="form" onSubmit={saveEdit}>
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={12}>
          <Item>
            <p>Tenant Name (TEMP)</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField fullWidth placeholder="Tenant Name" value={tenantName} onChange={(e) => { setTenantName(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Name*</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField required fullWidth placeholder="Device Name" value={name} onChange={(e) => { setName(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Serial number</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField fullWidth placeholder="Device serial number" value={serial_number} onChange={(e) => { setSerialNumber(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Type*</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={type} labelId="typeLabel" fullWidth onChange={(e) => setType(e.target.value)}>
                {deviceTypes.map(type => (
                  <MenuItem value={type.value} key={type.value}>{type.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Heatmap</p>
            <Switch checked={heatmap} onChange={(e) => { setHeatmap(e.target.checked); setSettingsChanged(true) }} />
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item>
            <p>Metrics</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Select value={metrics} labelId="metricsLabel" fullWidth multiple onChange={(e) => { handleMetricsChange(e); setSettingsChanged(true) }}
                renderValue={(selected) => (
                  // eslint-disable-next-line react/jsx-no-undef
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}>
                {deviceMetrics.map(tag => (
                  <MenuItem value={tag.type} key={tag.type}>{tag.type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid >
        {type === "iot" && (
          <Grid item xs={12}>
            <Item>
              <p>Model</p>
              <FormControl sx={{ m: 1, minWidth: 250 }} required={type === "iot"}>
                <Select value={model} labelId="modelLabel" fullWidth onChange={(e) => { setModel(e.target.value); setSettingsChanged(true) }}>
                  {iotModels.map(m => (
                    <MenuItem value={m} key={m}>{m}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        <Grid item xs={12}>
          <Item>
            <p>Sub areas{(isAreaMetric) && ("*")}</p>

            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                freeSolo
                onChange={(e, value) => { handleSubAreasChange(e, value); setSettingsChanged(true) }}
                value={sub_areas || []}

                renderTags={(
                  value: any[],
                  getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
                ) =>
                  value.map((option: any, index: any) => {
                    return (
                      <Chip
                        key={index}
                        // variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    required={isAreaMetric}
                    helperText="When adding a Sub Area - Press return after each entry"
                    inputProps={{
                      ...params.inputProps,
                      required: isAreaMetric ? sub_areas.length === 0 : false
                    }}

                  // placeholder="Sub areas"
                  />
                )}
              />
            </FormControl>
          </Item>
        </Grid >
        {(apiKeys.length > 0) && (
          <Grid item xs={12}>
            <Item>
              <p>API Key*</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>

                <Select value={apikey} labelId="apiKeyLabel" fullWidth onChange={(e) => { setApiKey(e.target.value); setSettingsChanged(true) }}>
                  {apiKeys.map((key: any) => (
                    <MenuItem value={key.key} key={key.key}>{key.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Item>
          </Grid>
        )}

        {(locations.length > 0) && (
          <Grid item xs={12}>
            <Item>
              <p>Location</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>

                <Select value={location} labelId="locationLabel" fullWidth onChange={(e) => { setLocation(e.target.value); setSettingsChanged(true); setArea("") }}>
                  {locations.map((key: any) => (
                    <MenuItem value={key.id} key={key.id}>{key.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Item>
          </Grid>
        )}
        {location && (
          <Grid item xs={12}>
            <Item>
              <p>Area</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>

                <Select value={area} labelId="areaLabel" fullWidth onChange={(e) => { setArea(e.target.value); setSettingsChanged(true) }}>
                  {locations.find((l: any) => l.id === location)?.areas.map((key: any) => (
                    <MenuItem value={key.id} key={key.id}>{key.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Item>
          </Grid>
        )}
        <Grid item xs={12}>
          <Item>
            <p>Latitude</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField fullWidth placeholder="Latitude" value={latitude} onChange={(e) => { setLatitude(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Longitude</p>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <TextField fullWidth placeholder="Longitude" value={longitude} onChange={(e) => { setLongitude(e.target.value); setSettingsChanged(true) }} />
            </FormControl>
          </Item>
        </Grid>
        {(type === "iot" || type === "mica") && (
          <Grid item xs={12}>
            <Item>
              <p>Associated Camera</p>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select value={associatedCamera} labelId="associatedCameraLabel" fullWidth onChange={(e) => { setAssociatedCamera(e.target.value); setSettingsChanged(true) }}>
                  {devices.data.filter(d => d.type === "camera").map(t => (
                    <MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid >
        )}
        <Grid item xs={12}>
          <Item>
            <p>Is V2</p>
            <Switch checked={isV2} onChange={(e) => { setIsV2(e.target.checked); setSettingsChanged(true) }} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Disabled</p>
            <Switch checked={disabled} onChange={(e) => { setDisabled(e.target.checked); setSettingsChanged(true) }} />
          </Item>
        </Grid>



        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={!settingsChanged}
              onClick={(e) => setSettingsChanged(false)}
            >
              Discard changes
            </Button>

            <Button
              variant="contained"
              disabled={!settingsChanged}
              type="submit"
            >
              Save settings
            </Button>
          </ButtonContainer>

        </Grid>
      </Grid >
    </Box >
  )
}
