import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Areas } from "../../api";

export interface AreaState {
  id: string;
  name: string;
  description: string;
  heatmaps: string[];
  location_id: string;
  createdAt: number;
  updatedAt: number;
}

export interface AreasState {
  data: AreaState[];
  status: 'idle' | 'loading' | 'failed';
  selected: string | undefined;
}

export interface GetAreas {
  tenantId: string;
  locationId: string;
}

const initialState: AreasState = {
  data: [],
  status: 'loading',
  selected: ''
}

export const getAreas = createAsyncThunk(
  'areas/getAreas',
  async ({tenantId, locationId}: GetAreas) => {
    const response = await Areas.getAll(tenantId, locationId);
    return response.data;
  }
);

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    resetAreas: (state) => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.selected = initialState.selected;
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    updateArea: (state, action) => {
      state.data[action.payload.index] = {
        ...state.data[action.payload.index],
        ...action.payload.area
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(getAreas.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { setSelected, resetAreas, updateArea } = areasSlice.actions;


export default areasSlice.reducer;