import React, { useEffect, useState } from 'react'

import { Toolbar, Typography } from '@mui/material'
import Tabs from '../../components/tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import UserDetails from './UserDetails';
import UserPermissions from './UserPermissions';
import { Permissions, UserLogs } from '../../api';
import UserAudit from './UserAudit';

export default function User() {
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const users = useAppSelector((s) => s.users);
  const [userIndex, setUserIndex] = useState<number | null>(null);
  const [permissions, setPermissions] = useState<any>({});
  const [userLogs, setUserLogs] = useState<any>([]);

  const { user: userSlug } = useParams();

  const getUserPermissions = async (tenantId: any, userId: string) => {
    try {
      const PermissionsResponse = await Permissions.getUser(tenantId, userId);
      setPermissions(
        PermissionsResponse.data || {}
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUserLogs = async (tenantId: any, userId: string) => {
    try {
      const LogsResponse = await UserLogs.get(tenantId, userId);
      setUserLogs(
        LogsResponse.data || []
      );
    } catch (error) {
      console.log(error);
    }
  };

  const saveUserPermissions = async (locations: any) => {
    if (userIndex === null || !tenants.selected) return;

    try {

      const response = await Permissions.create(tenants.selected, users.data[userIndex].userId, locations)
      setPermissions(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (users.status !== 'idle') return;
    const index = users.data.findIndex((u) => u.userId === userSlug)
    if (index === -1 && userSlug) {
      return navigate(`/${tenants.selected}/users`);
    }
    setUserIndex(index);
    getUserPermissions(tenants.selected, users.data[index].userId)
    getUserLogs(tenants.selected, users.data[index].userId)
  }, [users.data, tenants.selected, userSlug, users.status, navigate]);

  const [notFound, setNotFound] = useState(false);

  if (userIndex === null) {
    return (
      <p>Loading...</p>
    )
  }

  const tabs = [
    {
      label: `User Details`,
      Component: (
        <UserDetails user={users.data[userIndex]} />
      )
    },
    {
      label: "Permissions",
      Component: (
        <UserPermissions user={users.data[userIndex]} permissions={permissions} save={saveUserPermissions} />
      )
    },
    {
      label: "Logs",
      Component: (
        <UserAudit logs={userLogs} />
      )
    }
  ];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>User details</Typography>
        {/* <Typography variant='h1'>{users.data[userIndex].email}</Typography> */}
      </Toolbar>
      {/* <Chip label="User" sx={{ backgroundColor: 'black', color: '#ffffff' }} /> */}
      <Tabs tabs={tabs} />
    </div>
  )
}
