import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from '@mui/material';
import React from 'react'

const Content = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  paddingTop: '0px'
}));

export default function DeleteItemDialog(props: any) {
  const { title, text, open, onClose, onConfirm, id } = props;

  return (
    <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Content>
        <DialogContentText>
          {text}
        </DialogContentText>
      </Content>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="error" onClick={() => onConfirm(id)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}
