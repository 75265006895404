import { useCallback, useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useNav() {
  const navigate = useNavigate()
  const navigateRef = useRef({ navigate })
  useEffect(() => {
      navigateRef.current.navigate = navigate
  }, [navigate])
  return useCallback((location: string) => {
      navigateRef.current.navigate(location)
  }, [])
}