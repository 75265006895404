import { Toolbar, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Datagroups as DatagroupsApi } from '../../api';
import CustomTable from '../table/Table';
import CreateDatagroupsDialog from './CreateDatagroupsDialog';
import DeleteItemDialog from '../delete-item-dialog/DeleteItemDialog';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

export default function Datagroups(props: any) {
  const { id, datagroupsData, count } = props;

  const { tenant: tenantSlug } = useParams();
  const areas = useAppSelector((s) => s.areas);
  const locations = useAppSelector((s) => s.locations);

  const [addDialog, setAddDialog] = useState(false)
  const [datagroups, setDatagroups] = useState<any[]>([])
  const [allDatagroups, setAllDatagroups] = useState<any[]>([])
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});

  const getDatagroups = async (id: string, tenant_id: string, allAreas: any, allLocations: any) => {
    try {
      const currentArea = allAreas.data.find((a: any) => a.id === id);
      const areaIds = allAreas.data.map((a: any) => a.id);
      const currentLocation = allLocations.data.find((a: any) => a.id === id);

      const AllDatagroupsResponse = await DatagroupsApi.getAll(tenant_id);
      const currentDatagroups = AllDatagroupsResponse.data.filter((dg: any) => dg.linked_id === id).sort((a: any, b: any) => a.name.localeCompare(b.name));

      setDatagroups(currentDatagroups);

      if (currentArea) {
        const locationDatagroups = AllDatagroupsResponse.data.filter((dg: any) => dg.linked_id === currentArea.location_id ).map((dg: any) => {
          const location = allLocations.data.find((a: any) => a.id === dg.linked_id);
          return {...dg, name: `[${location?.name}]: ${dg.name}`}
        }).sort((a: any, b: any) => a.name.localeCompare(b.name));
  
        return setAllDatagroups(
          [...currentDatagroups, ...locationDatagroups]
        );
      }

      if (currentLocation) {
        const areaDatagroups = AllDatagroupsResponse.data.filter((dg: any) => areaIds.includes(dg.linked_id)).map((dg: any) => {
          const area = allAreas.data.find((a: any) => a.id === dg.linked_id);
          return {...dg, name: `[${area?.name}]: ${dg.name}`}
        }).sort((a: any, b: any) => a.name.localeCompare(b.name));
  
        return setAllDatagroups(
          [...currentDatagroups, ...areaDatagroups]
        );
      }

      setAllDatagroups(currentDatagroups);

      // const DatagroupsResponse = await DatagroupsApi.getAllByLinkedId(tenant_id, id);
      // setDatagroups(
      //   DatagroupsResponse.data.sort((a: any, b: any) => a.name.localeCompare(b.name))
      // );
    } catch (error) {
      console.log(error);
    }
  };

  const saveCreate = async (data: any) => {
    if (!tenantSlug) return;

    try {
      const dg = await DatagroupsApi.create(tenantSlug, id, data.name, data.metric, data.tags, data.formula, data.limit1, data.limit1Color, data.limit2, data.limit2Color, data.limit3, data.limit3Color)
      await getDatagroups(id, tenantSlug, areas, locations)
      setAddDialog(false);
      return dg;
    } catch (error) {
      console.log(error);
    }
  }

  const saveDelete = async () => {
    if (!tenantSlug) return;
    // do delete API
    try {
      await DatagroupsApi.delete(tenantSlug, selected.id)
      await getDatagroups(id, tenantSlug, areas, locations)
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const datagroup = datagroups.find(d => d.id === id);
    if (!datagroup) return;
    setSelected(datagroup);
    setDeleteDialog(true);
  }

  useEffect(() => {
    count(datagroups.length);
  }, [datagroups, count])

  useEffect(() => {
    datagroupsData(allDatagroups);
  }, [allDatagroups, datagroupsData])


  useEffect(() => {
    if (!id || !tenantSlug) return;
    getDatagroups(id, tenantSlug, areas, locations);
    return () => {
      setDatagroups([]);
    }
  }, [id, tenantSlug, areas, locations]);

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])

  const headers = ['Name', 'Metric', 'Data Sources'];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
        <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}> New Data Group</Button>
      </Toolbar >
      <CustomTable
        name="Data Groups"
        data={datagroups.map(dg => ({
          Name: dg.name,
          Metric: dg.metric,
          Tags: dg.tags.join(', '),
          "Data Sources": `${dg.datasources?.length || 0}`,
          key: dg.id
        }))}
        headers={headers}
        viewButton={true}
        editButton={false}
        deleteButton={true}
        routePrefix="datagroups"
        deleteItem={(id: string) => openAndSelectDelete(id)}
      />
      <CreateDatagroupsDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} />
      <DeleteItemDialog title={`Delete ${selected?.name || ''} Datagroup`} text={`Are you sure you want to delete this Datagroup? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
    </div>
  )
}
