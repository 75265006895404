import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector, useNav } from '../../app/hooks'
import { setSelected } from '../../tenantsSlice';

export default function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNav();
  const tenants = useAppSelector((s) => s.tenants);
  useEffect(() => {
    if (tenants.data.length) {
      dispatch(setSelected(tenants.data[0].id))
      navigate(`/${tenants.data[0].id}`)
    }

  }, [tenants, dispatch, navigate])

  return (
    <div>{tenants.status === 'idle' ? (<p>You are not currently assigned any tenants, please contact your support manager.</p>) : (<p>Loading...</p>)}</div>
  )
}
