import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setSelected } from '../tenant/locationsSlice';
import { getAreas, resetAreas } from './areasSlice';

export default function LocationWrapper() {
  const dispatch = useAppDispatch();
  const { location } = useParams();
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);

  useEffect(() => {
    if (locations.status !== 'idle') return;
    const index = locations.data.findIndex((t) => t.id === locations.selected)
    if (index === -1 && locations.selected) {
      return navigate(`/${tenants.selected}`);
    }
  }, [locations.data, tenants.selected, locations.selected, locations.status, navigate]);

  useEffect(() => {
    if (!locations.selected) {
      dispatch(setSelected(location));
    }
  }, [location, dispatch, locations.selected]);

  useEffect(() => {
    if (!tenants.selected || !locations.selected) return;

    dispatch(getAreas({ tenantId: tenants.selected, locationId: locations.selected }));

    return () => {
      dispatch(resetAreas());
      dispatch(setSelected(''));
    }
  }, [tenants.selected, locations.selected, dispatch])

  return (
    <Outlet />
  )
}
