import { FormControlLabel, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewItemDialog from "../../components/new-item-dialog/NewItemDialog";

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

export default function CreateUserDialog({
  open,
  onSave,
  onClose,
}: ICreateProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [TPA, setTPA] = useState(false);
  const [forceChangePassword, setForceChangePassword] = useState(true);
  const [returnPassword, setReturnPassword] = useState(false);
  const [dontSendEmail, setDontSendEmail] = useState(false);

  //TODO add modal to return password
  useEffect(() => {
    if (!open) {
      setEmail("");
      setPassword("");
      setTPA(false);
      setForceChangePassword(true);
      setReturnPassword(false);
      setDontSendEmail(false);
    }
  }, [open]);

  const formElements = (
    <>
      <TextField
        label="Email"
        required
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FormControlLabel
          label="Is TPA?"
          control={
            <Switch checked={TPA} onChange={(e) => setTPA(e.target.checked)} />
          }
          sx={{ width: "50%" }}
        />
        <FormControlLabel
          label="Force Change Password?"
          control={
            <Switch
              checked={!!password || returnPassword || forceChangePassword}
              onChange={(e) => setForceChangePassword(e.target.checked)}
            />
          }
          sx={{ width: "50%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FormControlLabel
          label="Return Password?"
          control={
            <Switch
              checked={returnPassword}
              onChange={(e) => setReturnPassword(e.target.checked)}
            />
          }
          sx={{ width: "50%" }}
        />
        <FormControlLabel
          label="Don't send email?"
          control={
            <Switch
              checked={dontSendEmail}
              onChange={(e) => setDontSendEmail(e.target.checked)}
            />
          }
          sx={{ width: "50%" }}
        />
      </div>
    </>
  );

  const save = async (e: any) => {
    e.preventDefault(e);
    await onSave({
      email,
      password,
      TPA,
      forceChangePassword,
      returnPassword,
      dontSendEmail,
    });
  };

  return (
    <NewItemDialog
      title="Invite user"
      text="Invite a new user to this Tenant"
      FormElements={formElements}
      open={open}
      onSave={(e: any) => save(e)}
      onClose={onClose}
    />
  );
}
