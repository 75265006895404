import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Content = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  paddingTop: "0px",
}));

interface IShowPasswordDialog {
  open: boolean;
  title: string;
  text: string;
  onClose: () => void;
}

export default function ShowPasswordDialog(props: IShowPasswordDialog) {
  const { title, text, open, onClose } = props;

  const [copyToClipboard, setCopyToClipboard] = useState({
    value: text,
    copied: false,
  });

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Content>
        <DialogContentText>
          <>
            {text}
            {"   "}
            <CopyToClipboard
              text={text}
              onCopy={() =>
                setCopyToClipboard({ ...copyToClipboard, copied: true })
              }
            >
              <IconButton size="small">
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>
          </>
        </DialogContentText>
      </Content>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
