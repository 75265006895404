import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  devices: any;
  datagroups: any[];
  attatchedSources: any;
}
export default function AddSourceDialog({ open, onSave, onClose, devices, datagroups, attatchedSources }: ICreateProps) {

  const { datagroup: datagroupSlug } = useParams();

  const [device, setDevice] = useState("");
  const [datagroup, setDatagroup] = useState("");
  const [deviceSubAreas, setDeviceSubAreas] = useState([]);
  const [subArea, setSubArea] = useState("");
  const [sourceType, setSourceType] = useState("");

  // const datagroups: any[] = [];

  // const existingDevices = attatchedDevices.map((e: { key: any; }) => e.key);
  const filteredDevices = devices.filter(function (device: { Disabled: boolean; key: string; }) {
    // const checkExistingDevices = existingDevices.some((devices: string) => device.key.includes(devices));
    // return !device.Disabled && !checkExistingDevices;
    return !device.Disabled;
  });

  const filteredDatagroups = datagroups.filter((datagroup => datagroup.key !== datagroupSlug));

  useEffect(() => {
    if (!device) return

    const selectedDevice = filteredDevices.find((d: any) => d.key === device);

    setDeviceSubAreas(selectedDevice.sub_areas)

  }, [device, filteredDevices])

  useEffect(() => {
    if (!open) {
      setDevice("");
      setSubArea("");
      setSourceType("");
    }
  }, [open])

  const changeDataSource = (e: any) => {
    setSourceType(e.target.value)
    setDevice("");
    setDatagroup("");
  }

  const formElements = <>
    <FormControl required fullWidth>
      <InputLabel id="sourceTypeLabel">Source Type</InputLabel>
      <Select value={sourceType} labelId="sourceTypeLabel" fullWidth label="Source Type" onChange={changeDataSource}>
        <MenuItem value="device" key="device">Device</MenuItem>
        <MenuItem value="datagroup" key="datagroup">Datagroup</MenuItem>
      </Select>
    </FormControl>
    {sourceType === 'device' && (
      <>
        <FormControl required fullWidth>
          <InputLabel id="deviceLabel">Device</InputLabel>
          <Select value={device} labelId="devicesLabel" fullWidth label="Device" onChange={(e) => { setDevice(e.target.value); setSubArea("") }}>
            {filteredDevices.map((key: any) => (
              <MenuItem value={key.key} key={key.key}>{key.Name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="subAreaLabel">Sub area</InputLabel>
          <Select value={subArea} labelId="subAreasLabel" fullWidth label="Sub area" onChange={(e) => setSubArea(e.target.value)}>
            {deviceSubAreas.map((d: any) => (
              <MenuItem value={d.name} key={d.name}>{d.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    )}
    {sourceType === 'datagroup' && (
      <FormControl fullWidth>
        <InputLabel id="datagroupsLabel">Datagroups</InputLabel>
        <Select value={filteredDatagroups.length ? datagroup : "none"} labelId="datagroupsLabel" disabled={!filteredDatagroups.length} fullWidth label="Datagroups" onChange={(e) => { setDatagroup(e.target.value) }}>
          {filteredDatagroups.length ?
            filteredDatagroups.map((key: any) => (
              <MenuItem value={key.key} key={key.key}>{key.Name}</MenuItem>
            ))
            : (
              <MenuItem value="none">No parent Datagroups found</MenuItem>

            )}
        </Select>
      </FormControl>
    )}
  </>

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ device, subArea, datagroup })
    setDevice("");
    setSubArea("");
  }

  return (
    <NewItemDialog title="Add Data Source" text="Add a device or datagroup to include it's data into this datagroup. If the device contains a specific sub area that you wish to pull data from, please enter it's name" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
