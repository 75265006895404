import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Settings } from "../../api";

export interface SettingState {
  id: string;
  tenant_id: string;
  type: string;
  key: string;
  value: any;
  createdAt: number;
  updatedAt: number;
}

export interface SettingsState {
  data: SettingState[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SettingsState = {
  data: [],
  status: 'loading',
}

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  async (tenantId: string) => {
    const response = await Settings.getAll(tenantId);
    return response.data.sort((a: any, b: any) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettings: (state) => {
      state.data = initialState.data;
      state.status = initialState.status;
    },
    updateSettings: (state, action) => {
      state.data[action.payload.index] = {
        ...state.data[action.payload.index],
        ...action.payload.device
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(getSettings.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { resetSettings, updateSettings } = settingsSlice.actions;


export default settingsSlice.reducer;