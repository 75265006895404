import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'
import { apiServices } from '../../app/config/config'

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

export default function CreateApiKeyDialog({ open, onSave, onClose }: ICreateProps) {

  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [key, setKey] = useState("");
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    if (!open) {
      setName("");
      setKey("");
      setService("");
      setEndpoint("");
    }
  }, [open])

  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <FormControl required>
      <InputLabel id="serviceLabel">Service</InputLabel>
      <Select value={service} labelId="serviceLabel" fullWidth label="Service" onChange={(e) => setService(e.target.value)}>
        {apiServices.map(service => (
          <MenuItem value={service.value} key={service.value}>{service.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {/* <TextField label="Service" fullWidth value={service} onChange={(e) => setService(e.target.value)} /> */}
    <TextField label="Key" required fullWidth value={key} onChange={(e) => setKey(e.target.value)} />
    <TextField label="Endpoint" fullWidth value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, service, key, endpoint })
  }

  return (
    <NewItemDialog title="New API Key" text="Create a new API Key that you can then attach to a device" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
