import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "../../api";

export interface AuthData {
  access_token: string | null;
  refresh_token: string | null;
  email: string | null;
  isGA: boolean;
}

export interface AuthState {
  data: AuthData;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AuthState = {
  data: {
    access_token: null,
    refresh_token: null,
    email: null,
    isGA: false
  },
  status: 'loading',
}

export interface RequestTokenParams {
  code: string;
}

export const requestAccessToken = createAsyncThunk(
  'auth/requestAccessToken',
  async ({ code }: RequestTokenParams) => {
    const response = await Auth.post(code);
    return response.data;
  }
);

export const authSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setTokens: (state, action) => {
      // console.log(action.payload);
      state.data.access_token = action.payload.access_token;
      state.data.refresh_token = action.payload.refresh_token;
      state.data.email = action.payload.email;
      state.data.isGA = action.payload.isGA;
    },
    logout: (state) => {
      state.data.access_token = null;
      state.data.refresh_token = null;
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestAccessToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(requestAccessToken.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data.access_token = action.payload.access_token;
        state.data.refresh_token = action.payload.refresh_token;
        state.data.email = action.payload.email;
        state.data.isGA = action.payload.isGA;
        localStorage.setItem("access_token", action.payload.access_token);
        localStorage.setItem("refresh_token", action.payload.refresh_token);
      })
      .addCase(requestAccessToken.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { setTokens, logout } = authSlice.actions;


export default authSlice.reducer;