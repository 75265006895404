import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
  devices: any;
  heatmaps: any;
}

export default function CreateHeatmapDialog({ open, onSave, onClose, devices, heatmaps }: ICreateProps) {
  const [device, setDevice] = useState("");
  const existingDevices = heatmaps.map((e: { key: any; }) => e.key);
  const filteredDevices = devices.filter((device: { Disabled: boolean; Heatmap: boolean; key: string; }) => {
    // const checkExistingDevices = existingDevices.some((devices: string) => device.key.includes(devices));
    return device.Heatmap && !device.Disabled && !existingDevices.includes(device.key);
  }).sort((a: any, b: any) => a.Name.localeCompare(b.Name));


  useEffect(() => {
    if (!open) {
      setDevice("");
    }
  }, [open])

  const formElements = <>
    <FormControl fullWidth>
      <InputLabel id="apiKeyLabel">Device</InputLabel>
      <Select value={device} labelId="devicesLabel" fullWidth label="Device" onChange={(e) => setDevice(e.target.value)} MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}>
        {filteredDevices.map((key: any) => (
          <MenuItem value={key.key} key={key.key}>{key.Name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </>

  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ device })
  }

  return (
    <NewItemDialog title="Add device" text="Add a device to show it's heatmap in this area" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
