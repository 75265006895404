import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Devices } from "../../api";

export interface SubArea {
  name: string;
  formula: string;
}

export interface Metric {
  type: string;
  formula: string;
}

export interface DeviceState {
  id: string;
  name: string;
  type: string;
  serial_number: string;
  heatmap: boolean;
  apikey: string;
  sub_areas: SubArea[];
  metrics: Metric[];
  createdAt: number;
  updatedAt: number;
}

export interface DevicesState {
  data: DeviceState[];
  status: 'idle' | 'loading' | 'failed';
  selected: string | undefined;
}

const initialState: DevicesState = {
  data: [],
  status: 'loading',
  selected: ''
}

export const getDevices = createAsyncThunk(
  'devices/getDevices',
  async (tenantId: string) => {
    const response = await Devices.getAll(tenantId);
    return response.data;
  }
);

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    resetDevices: (state) => {
      state.data = initialState.data;
      state.status = initialState.status;
      state.selected = initialState.selected;
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    updateDevice: (state, action) => {
      state.data[action.payload.index] = {
        ...state.data[action.payload.index],
        ...action.payload.device
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDevices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.sort((a: any, b: any) => a.name.localeCompare(b.name));
      })
      .addCase(getDevices.rejected, (state) => {
        state.status = 'failed';
      });
  },
})

export const { setSelected, resetDevices, updateDevice } = devicesSlice.actions;


export default devicesSlice.reducer;