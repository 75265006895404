import React, { useEffect, useState } from 'react'

import { Toolbar, Typography } from '@mui/material'
import Tabs from '../../components/tabs/Tabs';
import { Devices as DevicesApi, ApiKeys as ApiKeysApi } from '../../api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeviceSettings from './DeviceSettings';
import DeviceProcessing from './DeviceProcessing';
import { useAppSelector } from '../../app/hooks';

export default function Device() {
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);
  const devices = useAppSelector((s) => s.devices);
  const [deviceIndex, setDeviceIndex] = useState<number | null>(null);

  const { device: deviceSlug } = useParams();
  const location = useLocation();

  const [apiKeys, setApiKeys] = useState<any[]>([]);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!tenants.selected) return
    ApiKeysApi.getAll(tenants.selected).then(response => {
      const apiKeysResults = response.data.map((d: any) => ({
        Name: `${d.name}`,
        Service: `${d.service}`,
        Key: `${d.key}`,
        Endpoint: `${d.endpoint}`,
        key: d.id,
      }))
      setApiKeys(apiKeysResults);
    }).catch(error => {
      console.log(error);
    })
  }, [tenants.selected])

  useEffect(() => {
    if (devices.status !== 'idle') return;
    const index = devices.data.findIndex((d) => d.id === deviceSlug)
    if (index === -1 && deviceSlug) {
      return navigate(`/${tenants.selected}/devices`);
    }
    setDeviceIndex(index);
  }, [devices.data, tenants.selected, deviceSlug, devices.status, navigate]);


  if (deviceIndex === null) {
    return (
      <p>Loading...</p>
    )
  }

  const tabs = [
    {
      label: `Settings`,
      Component: (
        <DeviceSettings device={devices.data[deviceIndex]} apiKeys={apiKeys} locationState={location.state} locations={locations.data} />
      )
    },
    {
      label: "Processing",
      Component: (
        <DeviceProcessing device={devices.data[deviceIndex]} locationState={location.state} />
      )
    }
  ];

  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>{devices.data[deviceIndex].name}</Typography>
      </Toolbar>
      {/* <Chip label="Device" sx={{ backgroundColor: 'black', color: '#ffffff' }} /> */}
      <Tabs tabs={tabs} />
    </div>
  )
}
