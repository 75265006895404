import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CustomTable from '../../components/table/Table';
import Tabs from '../../components/tabs/Tabs';
import { Locations as LocationsApi, Areas as AreasApi } from '../../api';
import EditLocationDialog from './EditLocationDialog';
import CreateAreaDialog from './CreateAreaDialog';
import DeleteItemDialog from '../../components/delete-item-dialog/DeleteItemDialog';
import Datagroups from '../../components/datagroups/Datagroups';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getAreas } from './areasSlice';
import { getLocations, updateLocation } from '../tenant/locationsSlice';
import Usecases from '../../components/usecases/Usecases';

export default function Locations() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tenants = useAppSelector((s) => s.tenants);
  const locations = useAppSelector((s) => s.locations);
  const areas = useAppSelector((s) => s.areas);
  const [locationIndex, setLocationIndex] = useState<number | null>(null);

  useEffect(() => {
    if (locations.status !== 'idle') return;
    const index = locations.data.findIndex((l) => l.id === locations.selected)
    if (index === -1 && locations.selected) {
      return navigate(`/${tenants.selected}`);
    }
    setLocationIndex(index);
  }, [locations.data, tenants.selected, locations.selected, locations.status, navigate]);

  const [addDialog, setAddDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selected, setSelected] = useState<any>({});
  const [datagroupCount, setDatagroupCount] = useState(0);
  const [usecaseCount, setUsecaseCount] = useState(0);
  const [datagroupsData, setDatagroupsData] = useState([]);

  // const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState('');

  const receiveDataGroups = (data: any) => {
    setDatagroupsData(data)
  }

  const saveCreate = async (data: any) => {
    if (!tenants.selected || !locations.selected) return;

    try {
      await AreasApi.create(data.name, data.description, tenants.selected, locations.selected)
      dispatch(getAreas({ tenantId: tenants.selected, locationId: locations.selected }))
      dispatch(getLocations(tenants.selected))
      setAddDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const saveEdit = async (data: any) => {
    if (!tenants.selected || !locations.selected) return;

    try {
      const updates = await LocationsApi.update(
        tenants.selected,
        locations.selected,
        data.name,
        data.latitude,
        data.longitude,
        data.kafka,
      );
      dispatch(updateLocation({ index: locationIndex, location: updates.data }));
      setEditDialog(false);
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  }

  const saveDelete = async () => {
    if (!tenants.selected || !locations.selected) return;

    try {
      await AreasApi.delete(selected.id, tenants.selected, locations.selected)
      dispatch(getAreas({ tenantId: tenants.selected, locationId: locations.selected }))
      setDeleteDialog(false);
    } catch (error) {
      console.log(error);
    }
  }

  const openAndSelectDelete = (id: string) => {
    const area = areas.data.find((a) => a.id === id);
    if (!area) return;
    setSelected(area);
    setDeleteDialog(true);
  }

  useEffect(() => {
    if (!deleteDialog) {
      setSelected({});
    }
  }, [deleteDialog])

  const areaHeaders = ['Name'];

  const tabs = [
    {
      label: `Areas (${areas.data.length})`,
      Component: (
        <div>
          <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'right', gap: '24px' }} disableGutters >
            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => setAddDialog(true)}> New Area</Button>
          </Toolbar>
          <CustomTable
            name="Areas"
            data={areas.data.map((d: any) => ({
              Name: `${d.name}`,
              key: d.id,
            }))}
            headers={areaHeaders}
            viewButton={true}
            editButton={false}
            deleteButton={true}
            deleteItem={(id: string) => openAndSelectDelete(id)}
          />
        </div>
      )
    },
    {
      label: `Use Cases (${usecaseCount})`,
      Component: (
        // <Typography>Use cases</Typography>
        <Usecases id={locations.selected || ''} datagroups={datagroupsData} count={(c: number) => setUsecaseCount(c)} />
      )
    },
    {
      label: `Data Groups (${datagroupCount})`,
      Component: (
        <Datagroups id={locations.selected || ''} datagroupsData={(data: any) => receiveDataGroups(data)} count={(c: number) => setDatagroupCount(c)} />
      )
    }
  ];
  if (locationIndex === null) {
    return <div>Loading...</div>
  }
  return (
    <div>
      <Toolbar sx={{ alignItems: 'baseline', justifyContent: 'space-between' }} disableGutters>
        <Typography variant='h1'>{locations.data[locationIndex].name}</Typography>
        <Button variant='contained' color='primary' startIcon={<EditIcon />} onClick={() => setEditDialog(true)}>Edit</Button>
      </Toolbar>
      {/* <Chip label="Location" sx={{ backgroundColor: 'success.main', color: '#ffffff' }} /> */}
      <p><strong>Kafka alert alias: </strong>{locations.data[locationIndex].kafka_alert_alias}</p>
      <p><strong>Latitude: </strong>{locations.data[locationIndex].latitude}</p>
      <p><strong>Longitude: </strong>{locations.data[locationIndex].longitude}</p>
      <Tabs tabs={tabs} />
      <EditLocationDialog open={editDialog} location={locations.data[locationIndex]} onSave={(data: any) => saveEdit(data)} onClose={() => setEditDialog(false)} />
      <CreateAreaDialog open={addDialog} onClose={() => setAddDialog(false)} onSave={(data: any) => saveCreate(data)} />
      <DeleteItemDialog title={`Delete ${selected?.name || ''} Area`} text={`Are you sure you want to delete this Area? It cannot be undone.`} open={deleteDialog} onClose={() => setDeleteDialog(false)} onConfirm={saveDelete} />
    </div>
  )
}
