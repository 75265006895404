import React, { useEffect } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useNav } from '../../app/hooks'
import { setSelected } from '../../tenantsSlice';
import { Auth as AuthApi } from '../../api';
import spinner from '../../assets/spinner.svg'
import { Box, styled } from '@mui/material';
import { requestAccessToken } from './authSlice';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '66vh',
  gap: '20px'
});

export default function Callback() {
  const navigate = useNav();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get("code")

  // const sendRequestAccessToken = async () => {
  //   try {
  //     await AuthApi.post(authorizationCode, token)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    if (!authorizationCode) return;
    // const requestAccessToken = async () => {
    //   const accessToken = await AuthApi.post(authorizationCode, token)
    //   const accessTokenResponse = accessToken.data
    //   console.log(accessTokenResponse)
    //   if (!accessToken) return;
    //   <Navigate replace to="/" />
    // };
    // requestAccessToken().catch()
    dispatch(requestAccessToken({ code: authorizationCode })).then(() => {
      navigate('/');
    })
  }, [authorizationCode, navigate, dispatch]);

  return (
    <Container>
      <p>Authenticating...</p>
      <img src={spinner} alt="Loading" style={{ width: '100px' }} />
    </Container>
  )
}
