import {
  Box,
  Container,
  Drawer,
  FormControl,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  styled,
} from "@mui/material";
import logo from "../../assets/siyte-logo.png";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CustomBreadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useAppDispatch, useAppSelector, useNav } from "../../app/hooks";
import { getTenants, setSelected } from "../../tenantsSlice";
import CreateTenantDialog from "./CreateTenantDialog";
import { BucketUrl, Tenants } from "../../api";
import { logout } from "../auth/authSlice";

const ButtonLink = styled(NavLink)(({ theme }) => ({
  "&.active": {
    borderRight: "2px solid #333",
  },
}));

export default function Layout(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNav();
  const tenants = useAppSelector((s) => s.tenants);
  const auth = useAppSelector((s) => s.auth);
  const drawerWidth = 360;
  const [createTenantDialog, setCreateTenantDialog] = useState(false);
  const [tenantSelect, setTenantSelect] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setSelected(event.target.value));
    navigate(`/${event.target.value}`);
  };
  const saveCreateTenant = async (data: any) => {
    let imageUrl: any;

    if (data.image) {
      // get secure url from our server
      const splitImageName = data.image.name.split(".");
      const photoUrl = await BucketUrl.get(
        splitImageName[splitImageName.length - 1]
      );
      const uploadUrl = photoUrl.data.uploadUrl;
      // post the image direclty to the s3 bucket
      await BucketUrl.upload(uploadUrl, data.image);
      imageUrl = uploadUrl?.split("?")[0];
    }
    const newTenant = await Tenants.create(
      data.name,
      data.description,
      imageUrl
    );
    await dispatch(getTenants());
    dispatch(setSelected(newTenant.data.id));
    navigate(`/${newTenant.data.id}`);
    setCreateTenantDialog(false);
  };

  const openCreateTenantDialog = () => {
    setCreateTenantDialog(true);
    setTenantSelect(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/oauth/logout?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  };

  return (
    <Container
      sx={{ display: "flex", height: "100%" }}
      maxWidth={false}
      disableGutters
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            justifyContent: "space-between",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box>
          <NavLink to={"/"}>
            <img
              src={logo}
              alt="Siyte Logo"
              style={{ maxWidth: "60%", padding: "32px 0px 0px 32px" }}
            />
          </NavLink>
          <List>
            <FormControl fullWidth sx={{ pl: 4, pr: 4 }}>
              {/* <InputLabel id="apiKeyLabel">Device</InputLabel> */}
              <Select
                value={tenants.status === "idle" ? tenants.selected : "loading"}
                fullWidth
                onChange={handleChange}
                open={tenantSelect}
                onOpen={() => setTenantSelect(true)}
                onClose={() => setTenantSelect(false)}
              >
                {/* {auth.data.isGA && (
                  <MenuItem
                    value="global-admin-panel"
                    key="global-admin-panel-key"
                  >
                    Access Global Admin Panel
                  </MenuItem>
                )} */}
                {tenants.status === "idle" ? (
                  tenants.data.map((tenant) => (
                    <MenuItem value={tenant.id} key={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="loading">Loading...</MenuItem>
                )}
                {auth.data.isGA && (
                  <MenuItem
                    sx={{
                      border: 1,
                      borderColor: "gray",
                      borderRadius: 1,
                      margin: 2,
                    }}
                    onClick={openCreateTenantDialog}
                  >
                    Create a new tenant
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            <ListItemButton
              sx={{ pl: 4 }}
              component={ButtonLink}
              to={`/${tenants.selected}`}
            >
              <ListItemText>Locations</ListItemText>
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4 }}
              component={ButtonLink}
              to={`/${tenants.selected}/api-keys`}
            >
              {" "}
              <ListItemText>API Keys</ListItemText>
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component={ButtonLink}
              to={`/${tenants.selected}/devices`}
            >
              {" "}
              <ListItemText>Devices</ListItemText>
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component={ButtonLink}
              to={`/${tenants.selected}/users`}
            >
              {" "}
              <ListItemText>Users</ListItemText>
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component={ButtonLink}
              to={`/${tenants.selected}/settings`}
            >
              {" "}
              <ListItemText>Settings</ListItemText>
            </ListItemButton>
          </List>
        </Box>
        <Box>
          <List>
            <ListItemButton sx={{ pl: 4 }} onClick={handleLogout}>
              {" "}
              <ListItemText>Logout - {auth.data.email}</ListItemText>
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          minWidth: 0,
          flexGrow: 1,
          bgcolor: "#F7F7F7",
          p: 3,
          overflow: "auto",
        }}
      >
        <CustomBreadcrumbs />
        {/* <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            MUI
          </Link>
          <Link
            // underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            Core
          </Link>
          <Typography color="text.primary">Breadcrumbs</Typography>
        </Breadcrumbs> */}
        <div>{props.children}</div>
      </Box>
      <CreateTenantDialog
        open={createTenantDialog}
        onClose={() => {
          setCreateTenantDialog(false);
        }}
        onSave={(data: any) => saveCreateTenant(data)}
      />
    </Container>
  );
}
