import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import styled from '@emotion/styled';

const BreadcrumbLinks = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'initial',
}));

export default function CustomSeparator() {
  const tenants = useAppSelector((s) => s.tenants);
  const [tenantIndex, setTenantIndex] = useState<number | null>(null);
  const locations = useAppSelector((s) => s.locations);
  const [locationIndex, setLocationIndex] = useState<number | null>(null);
  const areas = useAppSelector((s) => s.areas);
  const [areaIndex, setAreaIndex] = useState<number | null>(null);

  const apiKeysMatch = useMatch(':tenant/api-keys');
  const devicesMatch = useMatch(':tenant/devices');
  const usersMatch = useMatch(':tenant/users');
  const settingsMatch = useMatch(':tenant/settings');

  useEffect(() => {
    const index = tenants.data.findIndex((t) => t.id === tenants.selected)
    if (index === -1) {
      setTenantIndex(null);
    } else {
      setTenantIndex(index);
    }

    return () => {
      setTenantIndex(null);
    }

  }, [tenants.selected, tenants.data])

  useEffect(() => {
    const index = locations.data.findIndex((t) => t.id === locations.selected)
    if (index === -1) {
      setLocationIndex(null);
    } else {
      setLocationIndex(index);
    }

    return () => {
      setLocationIndex(null);
    }

  }, [locations.selected, locations.data])

  useEffect(() => {
    const index = areas.data.findIndex((t) => t.id === areas.selected)
    if (index === -1) {
      setAreaIndex(null);
    } else {
      setAreaIndex(index);
    }

    return () => {
      setAreaIndex(null);
    }

  }, [areas.selected, areas.data])


  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {tenants.data.length && tenantIndex !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}`}>
          {tenants.data[tenantIndex].name}
        </BreadcrumbLinks>
      )}
      {locations.data.length && tenantIndex !== null && locationIndex !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}/${locations.data[locationIndex].id}`}>
          {locations.data[locationIndex].name}
        </BreadcrumbLinks>
      )}
      {areas.data.length && tenantIndex !== null && locationIndex !== null && areaIndex !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}/${locations.data[locationIndex].id}/${areas.data[areaIndex].id}`}>
          {areas.data[areaIndex].name}
        </BreadcrumbLinks>
      )}
      {tenants.data.length && tenantIndex !== null && apiKeysMatch !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}/api-keys`}>
          API Keys
        </BreadcrumbLinks>
      )}
      {tenants.data.length && tenantIndex !== null && devicesMatch !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}/devices`}>
          Devices
        </BreadcrumbLinks>
      )}
      {tenants.data.length && tenantIndex !== null && usersMatch !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}/users`}>
          Users
        </BreadcrumbLinks>
      )}
      {tenants.data.length && tenantIndex !== null && settingsMatch !== null && (
        <BreadcrumbLinks to={`/${tenants.data[tenantIndex].id}/settings`}>
          Settings
        </BreadcrumbLinks>
      )}
    </Breadcrumbs>
  );
}
