import { Box, Button, Grid, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Users as UsersApi } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUsers } from "./usersSlice";
import LoadingButton from "@mui/lab/LoadingButton";

interface IEditProps {
  user: any;
}
const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #c3c3c3",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "right",
  gap: "16px",
}));

export default function UserDetails({ user }: IEditProps) {
  const tenants = useAppSelector((s) => s.tenants);
  const dispatch = useAppDispatch();
  const [isTPA, setIsTPA] = useState(false);
  const [isTCA, setIsTCA] = useState(false);
  const [isForceChangePassword, setIsForceChangePassword] = useState(false);

  const [settingsChanged, setSettingsChanged] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const saveEdit = async (e: any) => {
    e.preventDefault();
    if (!tenants.selected) return;
    try {
      setLoading(true);
      await UsersApi.updateTenantUser(
        tenants.selected,
        user.userId,
        isTPA,
        isTCA,
        isForceChangePassword
      );
      setLoading(false);
      setSettingsChanged(false);
      dispatch(getUsers(tenants.selected));
    } catch (error: any) {
      const { data } = error.response;
      setError(data.message || error.message);
    }
  };

  useEffect(() => {
    setIsTPA(user.isTPA);
    setIsTCA(user.isTCA);
    setIsForceChangePassword(user.forceChangePassword);
  }, [user]);

  return (
    // <Box component="form">
    <Box component="form" onSubmit={saveEdit}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Item>
            <p>User email</p>
            <p>{user.email}</p>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Is Tenant Permissions Admin (TPA)</p>
            <Switch
              checked={isTPA}
              onChange={(e) => {
                setIsTPA(e.target.checked);
                setSettingsChanged(true);
              }}
            />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <p>Force Change Password</p>
            <Switch
              checked={isForceChangePassword}
              onChange={(e) => {
                setIsForceChangePassword(e.target.checked);
                setSettingsChanged(true);
              }}
            />
          </Item>
        </Grid>
        {/* <Grid item xs={12}>
          <Item>
            <p>Is Tenant Configuration Admin (TCA)</p>
            <Switch checked={isTCA} onChange={(e) => { setIsTCA(e.target.checked); setSettingsChanged(true) }} />
          </Item>
        </Grid> */}
        <Grid item xs={12}>
          <ButtonContainer>
            <LoadingButton
              variant="outlined"
              disabled={!settingsChanged || loading}
              onClick={(e) => setSettingsChanged(false)}
              // loading={loading}
            >
              {/* There is a known issue with the login button you need to put the text inside an html element to avoid */}
              <span>Discard changes</span>
            </LoadingButton>

            <LoadingButton
              variant="contained"
              disabled={!settingsChanged}
              type="submit"
              loading={loading}
            >
              {/* There is a known issue with the login button you need to put the text inside an html element to avoid */}
              <span>Save settings</span>
            </LoadingButton>
          </ButtonContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
