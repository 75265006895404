import { Box, Chip, FormControl, InputLabel, MenuItem, Select, styled, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NewItemDialog from '../../components/new-item-dialog/NewItemDialog'
import { deviceMetrics, formulaOptions, formulaOptionsPerMetric, tagOptions, thresholdOrderOptions } from '../../app/config/config'
import { useNavigate } from 'react-router-dom';

interface ICreateProps {
  open: Boolean;
  onSave: Function;
  onClose: Function;
}

const ParentItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '1px solid #c3c3c3'
}));

const SubItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default function CreateDatagroupsDialog({ open, onSave, onClose }: ICreateProps) {

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [metric, setMetric] = useState("");
  const [tags, setTags] = useState([]);
  const [formula, setFormula] = useState("total");
  const [limit1, setLimit1] = useState("");
  const [limit1Color, setLimit1Color] = useState("");
  const [limit2, setLimit2] = useState("");
  const [limit2Color, setLimit2Color] = useState("");
  const [limit3, setLimit3] = useState("");
  const [limit3Color, setLimit3Color] = useState("");

  const handleTagsChange = (event: any) => {
    const { target: { value } } = event;

    setTags(typeof value === 'string' ? value.split(',') : value)
  }

  useEffect(() => {
    if (!open) {
      setName("");
      setTags([]);
      setMetric("");
      setFormula("total");
      setLimit1("");
      setLimit1Color("");
      setLimit2("");
      setLimit2Color("");
      setLimit3("");
      setLimit3Color("");
    }
  }, [open])

  useEffect(() => {
    if (metric && formulaOptionsPerMetric[metric].length === 1) {
      setFormula(formulaOptionsPerMetric[metric][0]);
    }

    return () => {
      setFormula("");
    }
  }, [metric])


  const formElements = <>
    <TextField label="Name" required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
    <FormControl required fullWidth>
      <InputLabel id="metricLabel">Metric</InputLabel>
      <Select value={metric} labelId="metricLabel" fullWidth label="Metric" onChange={(e) => setMetric(e.target.value)}>
        {deviceMetrics.map(metric => (
          <MenuItem value={metric.type} key={metric.type}>{metric.type}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {/* <FormControl fullWidth>
      <InputLabel id="tagsLabel">Tags</InputLabel>
      <Select value={tags} labelId="tagsLabel" fullWidth multiple label="Tags" onChange={handleTagsChange}
        renderValue={(selected) => (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}>
        {tagOptions.map(tag => (
          <MenuItem value={tag} key={tag}>{tag}</MenuItem>
        ))}
      </Select>
    </FormControl> */}
    {metric && formulaOptionsPerMetric[metric].length !== 1 && (
      <FormControl fullWidth>
        <InputLabel id="formulaLabel">Formula</InputLabel>
        <Select value={formula} labelId="formulaLabel" fullWidth label="Formula" onChange={(e) => setFormula(e.target.value)} >
          {formulaOptionsPerMetric[metric].map((formula: any) => (
            <MenuItem value={formula} key={formula}>{formula}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    {/* <ParentItem>
      <SubItem>
        <p>Threshold Limit 1</p>
        <div>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Value" label="Value" value={limit1} type="number" onChange={(e) => { setLimit1(e.target.value) }} />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Color" label="Color" value={limit1Color} onChange={(e) => { setLimit1Color(e.target.value) }} />
          </FormControl>
        </div>
      </SubItem>
      <SubItem>
        <p>Threshold Limit 2</p>
        <div>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Value" label="Value" value={limit2} type="number" onChange={(e) => { setLimit2(e.target.value) }} />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Color" label="Color" value={limit2Color} onChange={(e) => { setLimit2Color(e.target.value) }} />
          </FormControl>
        </div>
      </SubItem>
      <SubItem>
        <p>Threshold Limit 3</p>
        <div>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Value" label="Value" value={limit3} type="number" onChange={(e) => { setLimit3(e.target.value) }} />
          </FormControl>
          <FormControl sx={{ m: 1, width: 125 }}>
            <TextField fullWidth placeholder="Color" label="Color" value={limit3Color} onChange={(e) => { setLimit3Color(e.target.value) }} />
          </FormControl>
        </div>
      </SubItem>
    </ParentItem> */}
  </>


  const save = (e: any) => {
    e.preventDefault(e);
    onSave({ name, tags, metric, formula, limit1, limit1Color, limit2, limit2Color, limit3, limit3Color }).then((res: any) => {
      navigate(`datagroups/${res.data.id}`, { replace: true })
    })
  }

  return (
    <NewItemDialog title="New Datagroup" text="Create a new Datagroup to display data to a user" FormElements={formElements} open={open} onSave={(e: any) => save(e)} onClose={onClose} />
  )
}
