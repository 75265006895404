import { Box, Grid, Button, styled, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Settings } from '../../api';
import { deviceMetrics } from '../../app/config/config';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getSettings } from './settingsSlice';

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #c3c3c3'
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '16px',
}));

export default function ZeroingMetrics() {
  const dispatch = useAppDispatch();
  const tenants = useAppSelector(s => s.tenants)
  const settings = useAppSelector(s => s.settings)
  const [zeroMetricsSettings, setZeroMetricsSettings] = useState<any>({});
  const [changedZeroMetricsSettings, setChangedZeroMetricsSettings] = useState<any>({});

  const [settingsChanged, setSettingsChanged] = useState(false);

  useEffect(() => {
    setZeroMetricsSettings(settings.data.filter(s => s.type === "zerometrics").reduce((acc, cur) => {
      return {...acc, [cur.key]: cur.value};
    }, {}));
  }, [settings])

  const updateMetricSetting = (metric: any, value: any) => {
    setChangedZeroMetricsSettings((s: any) => {
      return {...s, [metric]: value}
    })
    setSettingsChanged(true)
  }

  const discardChanges = () => {
    setChangedZeroMetricsSettings(false)
    setSettingsChanged(false)
  }

  const save = async () => {
    if (!tenants.selected) return
    try {
      const promises = Object.keys(changedZeroMetricsSettings).map(async (metric) => {
        await Settings.create(tenants.selected, "zerometrics", metric, changedZeroMetricsSettings[metric]);
      })
  
      await Promise.all(promises);
      
      dispatch(getSettings(tenants.selected));
      discardChanges();
    } catch (error) {
      
    }
  }
  
  
  return (
    <Box component="form">
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">

        {[...deviceMetrics].sort((a, b) => a.type.localeCompare(b.type)).map(item => {
          const itemName = item.type;
          const checked = changedZeroMetricsSettings[item.type] !== undefined ? changedZeroMetricsSettings[item.type] : zeroMetricsSettings[item.type] !== undefined ? zeroMetricsSettings[item.type] : true;
          return (
            <Grid item key={itemName} xs={12}>
              <Item>
                <p>{itemName}</p>
                <Switch checked={checked} onChange={(e) => { updateMetricSetting(item.type, e.target.checked) }} />
              </Item>
            </Grid>
          )
        })}

        <Grid item xs={12}>
          <ButtonContainer>
            <Button
              variant="outlined"
              disabled={!settingsChanged}
              onClick={discardChanges}
            >
              Discard changes
            </Button>

            <Button
              variant="contained"
              disabled={!settingsChanged}
              onClick={save}
            >
              Save settings
            </Button>
          </ButtonContainer>

        </Grid>
      </Grid >
    </Box >
  )
}
